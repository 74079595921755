import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User, UserProfileDetails } from 'app/core/user/user.types';
import { catchError, map, Observable, ReplaySubject, tap,  of, switchMap, throwError } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'environment/environment.prod';

import { globals } from 'app/globals';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({providedIn: 'root'})
export class UserService
{
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    SERVER_URL      = globals.CORE_SERVER_URL
    SERVER_PORT     = globals.CORE_SERVER_PORT

    private _updateProfileDetailsURL    = environment.API_BASE_URL+'/accounts/update-profile-details'// this.SERVER_URL+':'+this.SERVER_PORT+'/accounts/update-profile-details'
    private _updateStatusURL            = environment.API_BASE_URL+'/accounts/update-status'// this.SERVER_URL+':'+this.SERVER_PORT+'/accounts/update-status'
    private _profileURL                 = environment.API_BASE_URL+'/accounts/me'// this.SERVER_URL+':'+this.SERVER_PORT+'/accounts/me'

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<User>
    {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>(this._updateStatusURL, {user}).pipe(
            map((response) =>
            {
                this._user.next(response);
            }),
        );
    }

    /**
     * Update About User
     *
     * @param about
     */
    updateProfileDetails(userProfileDetails:UserProfileDetails): Observable<any>
    {
        //console.log("The details to be sent to the API : "+JSON.stringify(userProfileDetails))

        return this._httpClient.put(this._updateProfileDetailsURL+'/'+userProfileDetails.id, userProfileDetails, httpOptions);

        // return this._httpClient.put<User>(this._updateProfileDetailsURL+'/'+userProfileDetails.id, {userProfileDetails}).pipe(
        //     map((response) =>
        //     {
        //         this._user.next(response);
        //     }),
        // );
    }

    /**
     * Get the logged in user
     *
     * @param email
     */
    getProfileDetails(email): Observable<any>
    {
        //return this._httpClient.post(this._meURL, email, httpOptions);
        return this._httpClient.post(this._profileURL, {
            "email":email
        }, httpOptions).pipe(

            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                //console.log("The user profile detail are : "+JSON.stringify(response))

                // Store the user on the user service
                //this._userService.user = response; //cloneDeep(this._user); //response.user;

                // Return a new observable with the response
                return of(response);

            })

        )
    }
}
