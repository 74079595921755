import { ActivatedRoute, Router, RouterLink, RouterStateSnapshot, Routes } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/core/auth/auth.service';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { NgIf, NgFor } from '@angular/common';
import { FormControl, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';





import { HelpCenterService } from 'app/modules/admin/apps/help-center/help-center.service';
import { MemberType } from 'app/modules/admin/apps/help-center/help-center.type';

@Component({
    selector     : 'auth-account-activation',
    templateUrl  : './account-activation.component.html',
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,
    standalone   : true,
    imports      : [RouterLink,NgIf,NgFor, FuseAlertComponent, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatButtonModule, MatIconModule, MatCheckboxModule, MatProgressSpinnerModule],
})
export class AuthAccountActivationComponent implements OnInit
{

    userCode!: string;

    userCodePayload = {
        "user_code" : ""
    }

    showAlert: boolean = false;
    isSuccessful = false;
    isSignUpFailed = false;
    errorMessage = '';

    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: '',
    };

    /**
     * Constructor
     */
    constructor(
        private _route: ActivatedRoute,
        private _authService: AuthService,
        private _router: Router,
    )
    {
    }

    ngOnInit() {

        this._route.paramMap.subscribe((params) => {
            this.userCode = params.get('user_id')!;

            // Send the instruction to acivate the user based on the code
            this.userCodePayload = {
                "user_code" : this.userCode
            }

            // Sign up
            this._authService.activateAccount(this.userCodePayload)
                .subscribe(
                    (response) =>
                    {
                        // Navigate to the account activation page

                        // Display the response from the server
                        setTimeout(() => {
                            console.log(response);
                            console.log('Completing the process......')

                            // Show the alert
                            this.showAlert = true;

                            // Set the alert
                            this.alert = {
                                type   : 'success',
                                message: "You account has been activated successfully and 100 worth of research credits added. Login to start collaborating with other members in providing justice to your survivors of injustices!",
                            };



                            // Navigate to the required page
                            this.isSuccessful = true;
                            this.isSignUpFailed = false;

                            // And any other code that should run only after 1s
                          }, 1000);

                    },
                    (response) =>
                    {

                        this.errorMessage = JSON.stringify(response.error)

                        // Set the alert
                        this.alert = {
                            type   : 'error',
                            message: this.errorMessage,
                        };

                        // Show the alert
                        this.showAlert = true;

                    },
                );
                // console.log("The user code is :: "+this.userCode)
            });
    }

}
