import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import {
    categories as categoriesData,
    courses as coursesData,
    demoCourseSteps as demoCourseStepsData,
    newCaseBlankTemplateSteps as newCaseBlankTemplateStepsData,
    mootCourtSteps as mootCourtStepsData,
    unlawfullAssemblyAndRiotsCaseLawSteps as unlawfullAssemblyAndRiotsCaseLawStepsData,
    perjuryCaseLawSteps as perjuryCaseLawStepsData,
    trespassCaseLawSteps as trespassCaseLawStepsData,
    trespassToLandCourtOfAppealAppealCaseLawSteps as trespassToLandCourtOfAppealAppealCaseLawStepsData,
    contractDuressHighCourtCaseLawSteps as contractDuressHighCourtCaseLawStepsData,
    contractEconomicDuressHighCourtCaseLawSteps as contractEconomicDuressHighCourtCaseLawStepsData,
    constitutionalLawHumanRightsSupremeCourtCaseLawSteps as constitutionalLawHumanRightsSupremeCourtCaseLawStepsData,
} from 'app/mock-api/apps/academy/data';

import {
    domains as domainsData,
    caseFiles as caseFilesData,
    demoCaseFileSteps as demoCaseFileStepsData,
    newCaseFileBlankTemplateSteps as newCaseFileBlankTemplateStepsData,
} from 'app/mock-api/apps/case-files/data';

import { cloneDeep, constant } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class AcademyMockApi
{
    private _categories: any[] = categoriesData;
    private _courses: any[] = coursesData;
    private _demoCourseSteps: any[] = demoCourseStepsData;
    private _newCaseBlankTemplateSteps: any[] = newCaseBlankTemplateStepsData;
    private _mootCourtSteps: any[] = mootCourtStepsData;
    private _unlawfullAssemblyAndRiotsCaseLawSteps: any[] = unlawfullAssemblyAndRiotsCaseLawStepsData;
    private _perjuryCaseLawSteps: any[] = perjuryCaseLawStepsData;
    private _trespassCaseLawSteps: any[] = trespassCaseLawStepsData;
    private _trespassToLandCourtOfAppealAppealCaseLawSteps: any[] = trespassToLandCourtOfAppealAppealCaseLawStepsData;
    private _contractDuressHighCourtCaseLawSteps: any[] = contractDuressHighCourtCaseLawStepsData;
    private _contractEconomicDuressHighCourtCaseLawSteps: any[] = contractEconomicDuressHighCourtCaseLawStepsData;
    private _constitutionalLawHumanRightsSupremeCourtCaseLawSteps: any[] = constitutionalLawHumanRightsSupremeCourtCaseLawStepsData;

    private _domains: any[] = domainsData;
    private _caseFiles: any[] = caseFilesData;
    private _demoCaseFileSteps: any[] = demoCaseFileStepsData;
    private _newCaseFileBlankTemplateSteps: any[] = newCaseFileBlankTemplateStepsData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Categories - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/academy/categories')
            .reply(() =>
            {
                // Clone the categories
                const categories = cloneDeep(this._categories);

                // Sort the categories alphabetically by title
                categories.sort((a, b) => a.title.localeCompare(b.title));

                return [200, categories];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Courses - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/academy/courses')
            .reply(() =>
            {
                // Clone the courses
                const courses = cloneDeep(this._courses);

                return [200, courses];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Course - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/academy/courses/course')
            .reply(({request}) =>
            {
                // Get the id from the params
                const id = request.params.get('id');

                // Clone the courses and steps
                const courses = cloneDeep(this._courses);
                const steps = cloneDeep(this._demoCourseSteps);
                const newCaseSteps = cloneDeep(this._newCaseBlankTemplateSteps);
                const mootCourtSteps = cloneDeep(this._mootCourtSteps);
                const unlawfullAssemblyAndRiotsCaseLawSteps = cloneDeep(this._unlawfullAssemblyAndRiotsCaseLawSteps);
                const perjuryCaseLawSteps = cloneDeep(this._perjuryCaseLawSteps);
                const trespassCaseLawSteps = cloneDeep(this._trespassCaseLawSteps);
                const trespassToLandCourtOfAppealAppealCaseLawSteps = cloneDeep(this._trespassToLandCourtOfAppealAppealCaseLawSteps);
                const contractDuressHighCourtCaseLawSteps = cloneDeep(this._contractDuressHighCourtCaseLawSteps);
                const contractEconomicDuressHighCourtCaseLawSteps = cloneDeep(this._contractEconomicDuressHighCourtCaseLawSteps);
                const constitutionalLawHumanRightsSupremeCourtCaseLawSteps = cloneDeep(this._constitutionalLawHumanRightsSupremeCourtCaseLawSteps)

                // Find the course and attach steps to it
                const course = courses.find(item => item.id === id);

                if ( course )
                {
                    if( course.template == "moot-court-template-human-rights"){
                        course.steps = mootCourtSteps;
                    } else if(course.template == "offenses-against-public-order-unlawful-assembly-and-riot-template"){
                        course.steps = unlawfullAssemblyAndRiotsCaseLawSteps;
                    } else if(course.template == "offenses-against-administration-of-lawful-authority-perjury-template"){
                        course.steps = perjuryCaseLawSteps;
                    } else if(course.template == "tort-hypothetical-case-template-civil-trespass"){
                        course.steps = trespassCaseLawSteps;
                    } else if(course.template == "tort-court-of-appeal-appeal-template-trespass-to-land"){
                        course.steps = trespassToLandCourtOfAppealAppealCaseLawSteps;
                    } else if(course.template == "contracts-high-court-case-template-duress"){
                        course.steps = contractDuressHighCourtCaseLawSteps;
                    } else if(course.template == "contracts-high-court-case-template-economic-duress"){
                        course.steps = contractEconomicDuressHighCourtCaseLawSteps;
                    } else if(course.template == "constitutional-law-supreme-court-case-template-human-rights"){
                        course.steps = constitutionalLawHumanRightsSupremeCourtCaseLawSteps;
                    } else   {
                        course.steps = newCaseSteps
                    }

                }

                return [
                    200,
                    course,
                ];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Domains - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/case-files/domains')
            .reply(() =>
            {
                // Clone the domains
                const domains = cloneDeep(this._domains);

                // Sort the domains alphabetically by title
                domains.sort((a, b) => a.title.localeCompare(b.title));

                return [200, domains];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ CaseFiles - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/case-files/casefiles')
            .reply(() =>
            {
                // Clone the courses
                const caseFiles = cloneDeep(this._caseFiles);

                return [200, caseFiles];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ CaseFile - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/case-files/casefiles/casefile')
            .reply(({request}) =>
            {
                // Get the id from the params
                const id = request.params.get('id');

                // Clone the caseFiles and steps
                const caseFiles = cloneDeep(this._caseFiles);
                const steps = cloneDeep(this._demoCaseFileSteps);
                const newCaseFileSteps = cloneDeep(this._newCaseFileBlankTemplateSteps);

                // Find the course and attach steps to it
                const caseFile = caseFiles.find(item => item.id === id);

                if ( caseFile )
                {
                    if( caseFile.template == "moot-court-template-human-rights"){
                        caseFile.steps = newCaseFileSteps;
                    } else {
                        caseFile.steps = newCaseFileSteps
                    }

                }

                return [
                    200,
                    caseFile,
                ];
            });
    }
}
