/* eslint-disable */
export const faqCategories = [
    {
        id   : '28924eab-97cc-465a-ba21-f232bb95843f',
        slug : 'most-asked',
        title: 'Most asked',
    },
    {
        id   : '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
        slug : 'law-student-subscription-inquiries',
        title: 'Law Student subscription inquiries',
    },
    {
        id   : 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
        slug : 'paralegal-subscription-inquiries',
        title: 'Paralegal subscription inquiries',
    },
    {
        id   : '71c34043-d89d-4aca-951d-8606c3943c43',
        slug : 'lawyer-subscription-inquiries',
        title: 'Lawyer subscription inquiries',
    },
    {
        id   : 'bea49ee0-26da-46ad-97be-116cd7ab416d',
        slug : 'law-firm-subscription-inquiries',
        title: 'Law Firm subscription inquiries',
    },
    {
        id   : 'dea29fe0-28da-43bd-97be-116sd4ab426d',
        slug : 'client-subscription-inquiries',
        title: 'Client subscription inquiries',
    },
];
export const faqs = [
    // Most asked
    {
        id        : 'f65d517a-6f69-4c88-81f5-416f47405ce1',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question  : 'What is Amicus?',
        answer    : 'Amicus is an online collaborative platform for legal practitioners. Amicus brings together, law students, lawyers, advocates and other users interested in law and legal services. Depending on their interest, each user gets personalized and reliable services.',
    },
    {
        id        : '0fcece82-1691-4b98-a9b9-b63218f9deef',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question  : 'How does one get to use Amicus?',
        answer    : 'To start using Amicus, one needs to do three things: (1) Read the Terms and Conditions (2) Read the Privacy Policy Statement (3) Create a personal account as a user. It is however possible that your account was created with publicly available information waiting for you to claim it or reject the profile. For you to claim the profile you MUST read and understand the Terms & Privacy policy. If you wish to reject the profile, please reply to the email informing you of the created profile. ',
    },
    {
        id        : '2e6971cd-49d5-49f1-8cbd-fba5c71e6062',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question  : 'What is the benefit of using Amicus as a Law Student?',
        answer    : 'There are several benefits for law students using Amicus. (1) FREE & PAID access to categorized UNLIMITED analyzed case laws using FIRAC. (2) GATEKEEPER Job Opportunity - As a gate keeper, you help lawyers and law firms open digital files. Therefore you earn as a law student. (3) Mentorship & Guidance - You get continous feedback from practicing lawyers and advocates on the case briefs and digital files that you open and submit to them. (4) Practical Experience - You develop practical experience as you continue with your studies.',
    },
    {
        id        : '974f93b8-336f-4eec-b011-9ddb412ee828',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question  : 'What is the benefit of using Amicus as a Lawyer or Advocate of the High Court?',
        answer    : 'Several benefits to lawyers, advocates and law firms using Amicus. (1) Save Time - From the onset, the manual labor required in opening a digital file that involves researching relevant cases and building a case brief is done by the student leaving you with only the job of reviewing and giving guidance. (2) Access to unlimited analyzed and validated CASE laws using FIRAC cutting across several practicing areas. (3) Constant inflow of clients depending on your subscription service. (4) No need to invest in efficient and reliable technology as a lawyer or advocates. Please check on Subscription options for lawyers, advocates and law firms below.',
    },
    {
        id        : '5d877fc7-b881-4527-a6aa-d39d642feb23',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question  : 'What is the benefit of using Amicus as a law firm?',
        answer    : 'Several benefits to law firms. (1) All the three main benefits for lawyers and advocates as highlighted above. (2) Direct engagement with your clients to build your brand through the exclusive FAQ for Law Firms Section (3) Custom integration with your internal systems, website e.t.c. Please check on Subscription options for law firms below.',
    },
    {
        id        : '6d876fc7-b881-4527-a6aa-d39d646feb24',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question  : 'What is the benefit of using Amicus as a client?',
        answer    : 'The biggest advantage of Amicus is the collaboration it brings between students, lawyers, advocates and lawfirms having different years of experience in the various practicing fields of law. This collaboration brings about transparency, efficiency and accountability. All these three combined builds a personalized and reliable experience to you as a client. And remember, as a student you are a potential client, as a lawyer you are a potential client and as an advocate you are a potential client. This is a tightly coupled web where you experience the service you give. Everybody gets happy and everybody is everybody\'s BIG BROTHER. Welcome to the family',
    },
    // Law student subscription inquiries
    {
        id        : '3d1c26c5-1e5e-4eb6-8006-ed6037ed9aca',
        categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
        question  : 'What is the FREE Subscripiton package for students?',
        answer    : 'For you to access one case law for FREE, you need to have successfully analyzed and deposited one CASE law in a field of your interest. The FREE subscription package is a one to one mapping where you analyze one case law and you get one case law in the same practice area for FREE. If you analyze two case laws, you get two case laws for FREE. The more you analyze cases, the more case laws you access. The CASE laws you submit undergo a validation and quality audit process by either a paralegal, junior associate, senior associate or managing partner in an existing law firm.',
    },
    {
        id        : '11bd2b9a-85b4-41c9-832c-bd600dfa3a52',
        categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
        question  : 'What is the PAID Subscription package for students?',
        answer    : 'The default student prepay subscription is monthly. For you to access cases and case laws as a student, you must subscribe to the law student subscription plan. With this plan, you will not only access the plaform as a law student, but you will also be able to earn money by analyzing cases and generating case briefs that are to be used by lawyers and advocates of the high court.',
    },
    {
        id        : 'f55c023a-785e-4f0f-b5b7-47da75224deb',
        categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
        question  : 'How do i earn money from the platform?',
        answer    : 'As a student, once your case law has PASSED the validation and quality assurance by a senior user (either a paralegal, junior associate, senior associate or managing partner), it is made available to other users, and for a maximum of six months from the date of approval, you will get GUARANTEED commission out of the revenue generated by virtue of your CASE law. You can earn a comission from a CASE law for a maximum of six months. Meaning any commission earned from a CASE law older than six months may be stopped or reviewed at our own discretion.',
    },
    {
        id        : 'c577a67d-357a-4b88-96e8-a0ee1fe9162e',
        categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
        question  : 'What kind of CASE laws can i analyze?',
        answer    : 'As a student, you can analyze CASE laws according to your line of interests. There exists over 150 practice categories from which you can analyze a case law. The practice categories spanning over 15 practice domains which are (1) Accidents & Injuries (2) Bankruptcy & Debts (3) Business (4) Civil & Human Rights (5) Consumer Rights (6) Criminal Defense (7) Elder law (8) Employment & Labor (9) Family (10) Immigration (11) Insurance (12) Intellectual Property (13) Lawsuits and Disputes (14) Media Technology & Telecoms (15) Real Estates and so much more. The choise is yours.',
    },
    {
        id        : '1a680c29-7ece-4a80-9709-277ad4da8b4b',
        categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
        question  : 'What are some of my tasks as a GATEKEEPER',
        answer    : 'As a student, you are at the start (foundation) of a legal process and therefore your work goes a long way in making the subsequent stages easier, seamless, efficient and of high quality depending on how good you do the tasks. Some of the tasks up and above creating CASE laws of your choice include but not limited to (1) recieving legal briefs and questions from the public (2) answering questions of fact - restricting your answers to legal facts and not legal intepretation (3) preparing legal briefs (FIRAC) that will be used to score the case based on several other factors by either the paralegal, junior associate, senior associate or the managing partners of existing law firms. (4) track progress of all cases where you appear as the GATEKEEPER. This is to determine the bonus you will get at the end (closure) of the case ',
    },
    {
        id        : 'c49c2216-8bdb-4df0-be25-d5ea1dbb5688',
        categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
        question  : 'Besides the payment, what other benefits do i get as a student?',
        answer    : 'Apart from the monetary value that Amicus gives you as a student, the platform exposes you to a great deal of professional network where you can improve and grow in your communication and networking skills, get to appreciate the processes followed in real time while still a student, get to be mentored by a professional on the practicing category and so many other indirect benefits.',
    },
    // Paralegal subscription inquiries
    {
        id        : '3ef176fa-6cba-4536-9f43-540c686a4faa',
        categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
        question  : 'How do licenses work for items I bought?',
        answer    : 'Culpa duis nostrud qui velit sint magna officia fugiat ipsum eiusmod enim laborum pariatur anim culpa elit ipsum lorem pariatur exercitation laborum do labore cillum exercitation nisi reprehenderit exercitation quis.\n\nMollit aute dolor non elit et incididunt eiusmod non in commodo occaecat id in excepteur aliqua ea anim pariatur sint elit voluptate dolor eu non laborum laboris voluptate qui duis.',
    },
    {
        id        : '7bc6b7b4-7ad8-4cbe-af36-7301642d35fb',
        categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
        question  : 'Do licenses have an expiry date?',
        answer    : 'Ea proident dolor tempor dolore incididunt velit incididunt ullamco quis proident consectetur magna excepteur cillum officia ex do aliqua reprehenderit est esse officia labore dolore aute laboris eu commodo aute.\n\nOfficia quis id ipsum adipisicing ipsum eu exercitation cillum ex elit pariatur adipisicing ullamco ullamco nulla dolore magna aliqua reprehenderit eu laborum voluptate reprehenderit non eiusmod deserunt velit magna do.',
    },
    {
        id        : '56c9ed66-a1d2-4803-a160-fba29b826cb4',
        categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
        question  : 'I want to make multiple end products with the same item',
        answer    : 'Elit cillum incididunt enim cupidatat ex elit cillum aute dolor consectetur proident non minim eu est deserunt proident mollit ullamco laborum anim ea labore anim ex enim ullamco consectetur enim.\n\nEx magna consectetur esse enim consequat non aliqua nulla labore mollit sit quis ex fugiat commodo eu cupidatat irure incididunt consequat enim ut deserunt consequat elit consequat sint adipisicing sunt.',
    },
    {
        id        : '21c1b662-33c8-44d7-9530-91896afeeac7',
        categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
        question  : 'How easy is it to change the license type?',
        answer    : 'Duis culpa ut veniam voluptate consequat proident magna eiusmod id est magna culpa nulla enim culpa mollit velit lorem mollit ut minim dolore in tempor reprehenderit cillum occaecat proident ea.\n\nVeniam fugiat ea duis qui et eu eiusmod voluptate id cillum eiusmod eu reprehenderit minim reprehenderit nisi cillum nostrud duis eu magna minim sunt voluptate eu pariatur nulla ullamco elit.',
    },
    {
        id        : '5fa52c90-82be-41ae-96ec-5fc67cf054a4',
        categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
        question  : 'Do I need a Regular License or an Extended License?',
        answer    : 'Mollit nostrud ea irure ex ipsum in cupidatat irure sit officia reprehenderit adipisicing et occaecat cupidatat exercitation mollit esse in excepteur qui elit exercitation velit fugiat exercitation est officia excepteur.\n\nQuis esse voluptate laborum non veniam duis est fugiat tempor culpa minim velit minim ut duis qui officia consectetur ex nostrud ut elit elit nulla in consectetur voluptate aliqua aliqua.',
    },
    // Lawyers subscription inquiries
    {
        id        : '81ac908c-35a2-4705-8d75-539863c35c09',
        categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
        question  : 'Common PayPal, Skrill, and credit card issues',
        answer    : 'Sit occaecat sint nulla in esse dolor occaecat in ea sit irure magna magna veniam fugiat consequat exercitation ipsum ex officia velit consectetur consequat voluptate lorem eu proident lorem incididunt.\n\nExcepteur exercitation et qui labore nisi eu voluptate ipsum deserunt deserunt eu est minim dolor ad proident nulla reprehenderit culpa minim voluptate dolor nostrud dolor anim labore aliqua officia nostrud.',
    },
    {
        id        : 'b6d8909f-f36d-4885-8848-46b8230d4476',
        categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
        question  : 'How do I find my transaction ID?',
        answer    : 'Laboris ea nisi commodo nulla cillum consequat consectetur nisi velit adipisicing minim nulla culpa amet quis sit duis id id aliqua aute exercitation non reprehenderit aliquip enim eiusmod eu irure.\n\nNon irure consectetur sunt cillum do adipisicing excepteur labore proident ut officia dolor fugiat velit sint consectetur cillum qui amet enim anim mollit laboris consectetur non do laboris lorem aliqua.',
    },
    {
        id        : '9496235d-4d0c-430b-817e-1cba96404f95',
        categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
        question  : 'PayPal disputes And chargebacks',
        answer    : 'Ullamco eiusmod do pariatur pariatur consectetur commodo proident ex voluptate ullamco culpa commodo deserunt pariatur incididunt nisi magna dolor est minim eu ex voluptate deserunt labore id magna excepteur et.\n\nReprehenderit dolore pariatur exercitation ad non fugiat quis proident fugiat incididunt ea magna pariatur et exercitation tempor cillum eu consequat adipisicing est laborum sit cillum ea fugiat mollit cupidatat est.',
    },
    {
        id        : '7fde17e6-4ac1-47dd-a363-2f4f14dcf76a',
        categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
        question  : 'Saving your credit card details',
        answer    : 'Qui quis nulla excepteur voluptate elit culpa occaecat id ex do adipisicing est mollit id anim nisi irure amet officia ut sint aliquip dolore labore cupidatat magna laborum esse ea.\n\nEnim magna duis sit incididunt amet anim et nostrud laborum eiusmod et ea fugiat aliquip velit sit fugiat consectetur ipsum anim do enim excepteur cupidatat consequat sunt irure tempor ut.',
    },
    {
        id        : '90a3ed58-e13b-40cf-9219-f933bf9c9b8f',
        categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
        question  : 'Why do prepaid credits expire?',
        answer    : 'Consequat consectetur commodo deserunt sunt aliquip deserunt ex tempor esse nostrud sit dolore anim nostrud nulla dolore veniam minim laboris non dolor veniam lorem veniam deserunt laborum aute amet irure.\n\nEiusmod officia veniam reprehenderit ea aliquip velit anim aute minim aute nisi tempor qui sunt deserunt voluptate velit elit ut adipisicing ipsum et excepteur ipsum eu ullamco nisi esse dolor.',
    },
    {
        id        : '153376ed-691f-4dfd-ae99-e204a49edc44',
        categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
        question  : 'Why is there a minimum $20 credit?',
        answer    : 'Duis sint velit incididunt exercitation eiusmod nisi sunt ex est fugiat ad cupidatat sunt nisi elit do duis amet voluptate ipsum aliquip lorem aliqua sint esse in magna irure officia.\n\nNon eu ex elit ut est voluptate tempor amet ut officia in duis deserunt cillum labore do culpa id dolore magna anim consectetur qui consectetur fugiat labore mollit magna irure.',
    },
    // Law firms subscription inquiries
    {
        id        : '4e7ce72f-863a-451f-9160-cbd4fbbc4c3d',
        categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
        question  : 'What is item support?',
        answer    : 'Exercitation sit eiusmod enim officia exercitation eiusmod sunt eiusmod excepteur ad commodo eiusmod qui proident quis aliquip excepteur sit cillum occaecat non dolore sit in labore ut duis esse duis.\n\nConsequat sunt voluptate consectetur dolor laborum enim nostrud deserunt incididunt sint veniam laboris sunt amet velit anim duis aliqua sunt aliqua aute qui nisi mollit qui irure ullamco aliquip laborum.',
    },
    {
        id        : '0795a74f-7a84-4edf-8d66-296cdef70003',
        categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
        question  : 'How to contact an author',
        answer    : 'Minim commodo cillum do id qui irure aliqua laboris excepteur laboris magna enim est lorem consectetur tempor laboris proident proident eu irure dolor eiusmod in officia lorem quis laborum ullamco.\n\nQui excepteur ex sit esse dolore deserunt ullamco occaecat laboris fugiat cupidatat excepteur laboris amet dolore enim velit ipsum velit sint cupidatat consectetur cupidatat deserunt sit eu do ullamco quis.',
    },
    {
        id        : '05532574-c102-4228-89a8-55fff32ec6fc',
        categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
        question  : 'Extending and renewing item support',
        answer    : 'Reprehenderit anim consectetur anim dolor magna consequat excepteur tempor enim duis magna proident ullamco aute voluptate elit laborum mollit labore id ex lorem est mollit do qui ex labore nulla.\n\nUt proident elit proident adipisicing elit fugiat ex ullamco dolore excepteur excepteur labore laborum sunt ipsum proident magna ex voluptate laborum voluptate sint proident eu reprehenderit non excepteur quis eiusmod.',
    },
    {
        id        : 'b3917466-aa51-4293-9d5b-120b0ce6635c',
        categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
        question  : 'Rating or review removal policy',
        answer    : 'Ipsum officia mollit qui laboris sunt amet aliquip cupidatat minim non elit commodo eiusmod labore mollit pariatur aute reprehenderit ullamco occaecat enim pariatur aute amet occaecat incididunt irure ad ut.\n\nIncididunt cupidatat pariatur magna sint sit culpa ad cupidatat cillum exercitation consequat minim pariatur consectetur aliqua non adipisicing magna ad nulla ea do est nostrud eu aute id occaecat ut.',
    },
    {
        id        : '2f2fb472-24d4-4a00-aa80-d513fa6c059c',
        categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
        question  : 'Purchasing supported and unsupported items',
        answer    : 'Dolor cupidatat do qui in tempor dolor magna magna ut dolor est aute veniam consectetur enim sunt sunt duis magna magna aliquip id reprehenderit dolor in veniam ullamco incididunt occaecat.\n\nId duis pariatur anim cillum est sint non veniam voluptate deserunt anim nostrud duis voluptate occaecat elit ut veniam voluptate do qui est ad velit irure sint lorem ullamco aliqua.',
    },
    {
        id        : '2fffd148-7644-466d-8737-7dde88c54154',
        categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
        question  : 'I haven\'t received a response from the author',
        answer    : 'Velit commodo pariatur ullamco elit sunt dolor quis irure amet tempor laboris labore tempor nisi consectetur ea proident dolore culpa nostrud esse amet commodo do esse laboris laboris in magna.\n\nAute officia labore minim laborum irure cupidatat occaecat laborum ex labore ipsum aliqua cillum do exercitation esse et veniam excepteur mollit incididunt ut qui irure culpa qui deserunt nostrud tempor.',
    },
    {
        id        : '24a1034e-b4d6-4a86-a1ea-90516e87e810',
        categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
        question  : 'Responding to requests outside of support',
        answer    : 'Exercitation eu in officia lorem commodo pariatur pariatur nisi consectetur qui elit in aliquip et ullamco duis nostrud aute laborum laborum est dolor non qui amet deserunt ex et aliquip.\n\nProident consectetur eu amet minim labore anim ad non aute duis eiusmod sit ad elit magna do aliquip aliqua laborum dolor laboris ea irure duis mollit fugiat tempor eu est.',
    },
];
export const guideCategories = [
    // {
    //     id   : '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
    //     slug : 'getting-started',
    //     title: 'Getting Started',
    // },
    // {
    //     id   : '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
    //     slug : 'projects',
    //     title: 'Projects',
    // },
    // {
    //     id   : 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
    //     slug : 'settings',
    //     title: 'Settings',
    // },
    {
        id   : '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug : 'payments',
        title: 'Payments',
    },
    {
        id   : '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug : 'your-account',
        title: 'Your Account',
    },
];
export const guides = [
    // Getting started
    {
        id        : 'a008ffa3-7b3f-43be-8a8f-dbf5272ed2dd',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'what-is-this-app',
        title     : 'What is this app?',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '7643d388-12ab-4025-a2f1-5045ac7b1c4c',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'start-using-the-app',
        title     : 'Start using the app',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '1fecee67-c4b4-413a-b0f2-949dcab73249',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'signing-in-to-the-dashboard',
        title     : 'Signing in to the dashboard',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : 'd2e2ea8f-5298-4ba2-898b-afc60c064bba',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'navigating-within-the-app',
        title     : 'Navigating within the app',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    // Projects
    {
        id        : 'f2592886-11b8-4b56-baab-96802c2ed93e',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'creating-a-project',
        title     : 'Creating a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '9ec3f4b9-a355-4f57-9e93-efa8611cc1c9',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'renaming-a-project',
        title     : 'Renaming a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '1bc6e7f9-b046-4f4f-9b18-741c9d5429f6',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'displaying-a-project',
        title     : 'Displaying a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : 'a005d5f1-938d-45c5-8ed4-d0cf8d02e533',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'deleting-a-project',
        title     : 'Deleting a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '43837279-dce2-4dc0-beac-30b5ba829f14',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'changing-the-visibility-of-a-project',
        title     : 'Changing the visibility of a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '4cf5a435-eaa0-463c-8d2b-efde193c7fb3',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'adding-media-to-a-project',
        title     : 'Adding media to a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : 'cd3fb87e-e138-4721-9e29-a5c751bfd949',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'removing-a-media-from-a-project',
        title     : 'Removing a media from a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : 'f26205c6-882e-4713-b067-c73758b45551',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'cropping-a-media',
        title     : 'Cropping a media',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    // Settings
    {
        id        : '1cbdeaeb-bbf1-4d04-b43d-f37b55e6a229',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'general-settings',
        title     : 'General settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '98de7d4a-2ca2-4d47-bbe6-083ed26467db',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'project-settings',
        title     : 'Project settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '145f497c-1fdb-47b5-a6c1-31f856403571',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'media-settings',
        title     : 'Media settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '0a007f59-a5ea-4875-991d-f22d6fd69898',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'domain-settings',
        title     : 'Domain settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '4707c8eb-31f9-415c-bd07-86f226c75feb',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'privacy-settings',
        title     : 'Privacy settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    // Payments
    {
        id        : 'c771bf0a-1e0c-4b6d-af7e-189e10cc6fb8',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug      : 'subscriptions',
        title     : 'Subscriptions',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '3d7150d2-feb3-4f20-bd3f-8e525cef77a4',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug      : 'discounts',
        title     : 'Discounts',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '79239bc4-4fb5-428b-b30d-62c5289b061d',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug      : 'payment-methods',
        title     : 'Payment methods',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '8d68c5e6-5404-450c-9d5f-d9800c164041',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug      : 'overdue-payments',
        title     : 'Overdue payments',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    // Your account
    {
        id        : '60df0d4c-dda1-439c-bd44-179c57a7597d',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'changing-your-username',
        title     : 'Changing your username',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '0a9c3321-1db3-42bc-92b6-7e257368123e',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'changing-your-email',
        title     : 'Changing your email',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '80ba5106-5f9c-4ed7-b8f3-8544035e3095',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'changing-your-password',
        title     : 'Changing your password',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : 'db2e97a6-d657-4e9d-9b6c-5f213ea3301c',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'closing-your-account',
        title     : 'Closing your account',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : '3374c887-2fb7-4223-9f40-7f2cbbf76795',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'account-limits',
        title     : 'Account limits',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        id        : 'cc65f92a-7d46-4557-b15b-6f8f59a60576',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'two-factor-authentication',
        title     : 'Two factor authentication',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
];

// Since we only have one content for the demo, we will
// use the following mock-api on every request for every guide.
export const guideContent = `
<h2>Header Level 2</h2>

<p>
    <strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit
    amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper
    pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci,
    sagittis tempus lacus enim ac dui. <a href="#"
                                          class="link">Donec non enim</a>
    in turpis pulvinar facilisis. Ut felis.
</p>

<p>
    Orci varius natoque penatibus et magnis dis <em>parturient montes</em>, nascetur ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
    himenaeos. Curabitur vitae sagittis odio. <mark>Suspendisse</mark> ullamcorper nunc non pellentesque laoreet. Curabitur eu tortor id quam pretium mattis. Proin ut quam velit.
</p>

<h3>Header Level 3</h3>

<img src="assets/images/pages/help-center/image-1.jpg">
<p class="text-secondary">
    <em>Nullam sagittis nulla in diam finibus, sed pharetra velit vestibulum. Suspendisse euismod in urna eu posuere.</em>
</p>

<h4>Header Level 4</h4>

<blockquote>
    <p>
        Blockquote. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur
        massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.
    </p>
    <footer>
        Ayub Robert
    </footer>
</blockquote>

<ol>
    <li>Ordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ol>

<h5>Header Level 5</h5>

<ul>
    <li>Unordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ul>

<pre><code>#header h1 a {
    display: block;
    width: 300px;
    height: 80px;
}</code></pre>

<h6>Header Level 6</h6>

<dl>
    <dt>Definition list</dt>
    <dd>
        Quisque sit amet risus enim. Aliquam sit amet interdum justo, at ultricies sapien. Suspendisse et semper urna, in gravida eros. Quisque id nibh iaculis, euismod urna sed,
        egestas nisi. Donec eros metus, congue a imperdiet feugiat, sagittis nec ipsum. Quisque dapibus mollis felis non tristique.
    </dd>

    <dt>Definition list</dt>
    <dd>
        Ut auctor, metus sed dapibus tempus, urna diam auctor odio, in malesuada odio risus vitae nisi. Etiam blandit ante urna, vitae placerat massa mollis in. Duis nec urna ac
        purus semper dictum ut eget justo. Aenean non sagittis augue. Sed venenatis rhoncus enim eget ornare. Donec viverra sed felis at venenatis. Mauris aliquam fringilla nulla,
        sit amet congue felis dignissim at.
    </dd>
</dl>`;
