/* eslint-disable */
export const domains = [
    {
        id   : '9a67dff7-3c38-4052-a335-0cef93438ff6',
        title: 'Accidents & Injuries',
        slug : 'accidents-and-injuries',
    },
]

export const caseFiles = [
    {
        id         : '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        title      : 'Trespass to Person - Assault',
        slug       : 'trespass-to-person-assault',
        description: 'Bundi Makube Vs Joseph Onkoba Nyamuro [1983] eKLR',
        domain     : 'accidents-and-injuries',
        category   : '',
        subcategory: '',
        template   : 'trespass-to-person-assault-template',
        duration   : 30,
        court      : 'Court of Appeal',
        totalSteps : 1,
        updatedAt  : 'Jun 28, 2021',
        featured   : true,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
]


/* eslint-disable */
export const categories = [
    {
        id   : '9a67dff7-3c38-4052-a335-0cef93438ff6',
        title: 'Accidents & Injuries',
        slug : 'accidents-and-injuries',
    },
    // {
    //     id   : 'a89672f5-e00d-4be4-9194-cb9d29f82165',
    //     title: 'Bankruptcy & Debts',
    //     slug : 'firebase',
    // },
    {
        id   : '02f42092-bb23-4552-9ddb-cfdcc235d48f',
        title: 'Contracts',
        slug : 'contracts',
    },
    {
        id   : '5648a630-979f-4403-8c41-fc9790dea8cd',
        title: 'Civil & Human Rights',
        slug : 'civil-and-human-rights',
    },
    // {
    //     id   : '1078a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Consumer Rights',
    //     slug : 'consumer-rights',
    // },
    {
        id   : '1078a630-979f-4403-8c41-fc9790dea8cd',
        title: 'Criminal Defense',
        slug : 'criminal-defense',
    },
    // {
    //     id   : '1078a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Elder Law',
    //     slug : 'elder-law',
    // },
    // {
    //     id   : '1088a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Employment & Labor',
    //     slug : 'employment-labor',
    // },
    // {
    //     id   : '1098a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Family',
    //     slug : 'family',
    // },
    // {
    //     id   : '1048a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Immigration',
    //     slug : 'immigration',
    // },
    // {
    //     id   : '1148a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Insurance',
    //     slug : 'insurance',
    // },
    // {
    //     id   : '1248a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Intellectual Property',
    //     slug : 'intellectual-property',
    // },
    // {
    //     id   : '1348a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Lawsuits and Disputes',
    //     slug : 'lawsuits-and-disputes',
    // },
    // {
    //     id   : '1448a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Media Technology and Telecoms',
    //     slug : 'media-technology-and-telecoms',
    // },
    // {
    //     id   : '1548a630-979f-4403-8c41-fc9790dea8cd',
    //     title: 'Real Estate',
    //     slug : 'real-estate',
    // },

];
export const courses = [
    {
        id         : '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        title      : 'Trespass to Person - Assault',
        slug       : 'trespass-to-person-assault',
        description: 'Bundi Makube Vs Joseph Onkoba Nyamuro [1983] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'trespass-to-person-assault-template',
        duration   : 30,
        court      : 'Court of Appeal',
        totalSteps : 1,
        updatedAt  : 'Jun 28, 2021',
        featured   : true,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'f924007a-2ee9-470b-a316-8d21ed78277f',
        title      : 'Defamation - Libel',
        slug       : 'defamation-libel',
        description: 'Ernest Omondi Owino & Another Vs Felix Olick and 2 Others',
        category   : 'accidents-and-injuries',
        template   : 'defamation-libel-template',
        duration   : 60,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Nov 01, 2021',
        featured   : true,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : '0c06e980-abb5-4ba7-ab65-99a228cab36b',
        title      : 'Mooting Compromis - Ujamaa Housing',
        slug       : 'mooting-compromis-ujammaa-housing',
        description: 'Green Pastures Community Action Group Vs Amani Housing Authority',
        category   : 'civil-and-human-rights',
        template   : 'moot-court-template-human-rights',
        duration   : 120,
        court      : 'Moot Court',
        totalSteps : 12,
        updatedAt  : 'May 08, 2021',
        featured   : false,
        progress   : {
            currentStep: 3,
            completed  : 2,
        },
    },
    {
        id         : '1b9a9acc-9a36-403e-a1e7-b11780179e38',
        title      : 'Offenses against a person - Murder',
        slug       : 'offenses-against-a-person-murder',
        description: 'Republic v Henry Kaithia [2022] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-a-person-murder-template',
        duration   : 30,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jan 09, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : '55eb415f-3f4e-4853-a22b-f0ae91331169',
        title      : 'Tort Hypotetical Case - Trespass',
        slug       : 'tort-hypothetical-case-trespass',
        description: 'Oduor & Another Vs Wambui',
        category   : 'civil-and-human-rights',
        template   : 'tort-hypothetical-case-template-civil-trespass',
        duration   : 45,
        court      : 'High Court',
        totalSteps : 8,
        updatedAt  : 'Jan 14, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'fad2ab23-1011-4028-9a54-e52179ac4a50',
        title      : 'Contracts - Misrepresentation',
        slug       : 'contracts-misrepresentation',
        description: 'Sachin Shaha v Jagat Mahendra Kumar Shah & another [2020] eKLR',
        category   : 'contracts',
        template   : 'contracts-misrepresentation-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'bcd2ab23-1011-4138-9a54-e52179ac4a50',
        title      : 'Contracts - Mistake',
        slug       : 'contracts-mistake',
        description: 'Sapra Studio v Kenya National Properties Ltd[1985] eKLR',
        category   : 'contracts',
        template   : 'contracts-mistake-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'fad2ca23-1011-4028-9v54-e52179xc4a50',
        title      : 'Contracts - Duress',
        slug       : 'contracts-duress',
        description: 'NABRO PROPERTIES LIMITED vs SKY STRUCTURES LIMITED ) Z.R. SHAH ) SOUTHFORK INVESTMENTS LIMITED )[1986] eKLR',
        category   : 'contracts',
        template   : 'contracts-high-court-case-template-duress',
        duration   : 90,
        court      : 'Court of Appeal',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'fbd4db33-1011-4028-9v54-e52279xd4b51',
        title      : 'Contracts - Economic Duress',
        slug       : 'contracts-economic-duress',
        description: 'Madhupaper International Ltd & another v Kenya Commercial Bank Ltd & 2 others [2003] eKLR',
        category   : 'contracts',
        template   : 'contracts-high-court-case-template-economic-duress',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'fad2ab23-1011-4028-9a54-e52179ac4a51',
        title      : 'Contracts - Undue Influence',
        slug       : 'contracts-undue-influence',
        description: 'Mombasa Bricks & Tiles Ltd & 5 others v Arvind Shah & 7 others [2019] eKLR',
        category   : 'contracts',
        template   : 'contracts-undue-influence-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'bcd2ab23-1011-4138-9a54-e52179ac4a52',
        title      : 'Contracts - Illegal Contracts',
        slug       : 'contracts-illegal-contracts',
        description: 'Grana Limited v National Social Security Fund (Civil Appeal E028 of 2020) [2022] KEHC 61 (KLR) (Commercial and Tax) (31 January 2022) (Judgment)',
        category   : 'contracts',
        template   : 'contracts-illegal-contracts-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'fad2ca23-1011-4028-9v54-e52179xc4a53',
        title      : 'Contracts - Performance',
        slug       : 'contracts-performance',
        description: 'Chirchir Arap Kuto v Nancy Cherotich Koech & another [2022] eKLR',
        category   : 'contracts',
        template   : 'contracts-performance-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'fad2ab23-1011-4028-9a54-e52179ac4a54',
        title      : 'Contracts - Termination',
        slug       : 'contracts-termination',
        description: 'Matsesho v Newton (Cause 9 of 2019) [2022] KEELRC 1554 (KLR) (29 July 2022) (Judgment)',
        category   : 'contracts',
        template   : 'contracts-termination-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'bcd2ab23-1011-4138-9a54-e52179ac4a55',
        title      : 'Contracts - Breach',
        slug       : 'contracts-breach',
        description: 'Dormakaba Limited v Arcitectural Supplies Kenya Limited (Civil Suit 136 of 2020) [2021] KEHC 210 (KLR) (Commercial and Tax) (10 November 2021) (Judgment)',
        category   : 'contracts',
        template   : 'contracts-breach-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'fad2ca23-1011-4028-9v54-e52179xc4a56',
        title      : 'Contracts - Frustration',
        slug       : 'contracts-frustration',
        description: 'Showcase Properties Limited v Kenya Commercial Bank Ltd [2015] eKLR',
        category   : 'contracts',
        template   : 'contracts-frustration-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'c4bc107b-edc4-47a7-a7a8-4fb09732e794',
        title      : 'Trespass to Person - Battery',
        slug       : 'trespass-to-person-battery',
        description: 'Eunice Auma Onyango v Salin Akinyi Oluoch [2015] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'trespass-to-person-battery-template',
        duration   : 120,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Nov 19, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : '1449f945-d032-460d-98e3-406565a22293',
        title      : 'Offenses against a person - Manslaughter',
        slug       : 'offenses-against-a-person-manslaughter',
        description: 'Republic v Isaac Wanjala Murumba [2021] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-a-person-manslaughter-template',
        duration   : 45,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jul 11, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'f05e08ab-f3e3-4597-a032-6a4b69816f24',
        title      : 'Contracts - Incomplete',
        slug       : 'contracts-incomplete',
        description: 'Hopetoun Epz (K) Ltd v Job Mwangi t/a Africost Kenya [2019] eKLR',
        category   : 'contracts',
        template   : 'contracts-incomplete-template',
        duration   : 30,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Mar 13, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : '181728f4-87c8-45c5-b9cc-92265bcd2f4d',
        title      : 'Contracts - Interpretation',
        slug       : 'contracts-interpretation',
        description: 'Euromec International Limited v Shandong Taikai Power Engineering Company Limited (Civil Case E527 of 2020) [2021] KEHC 93 (KLR) (Commercial and Tax) (21 September 2021) (Ruling)',
        category   : 'contracts',
        template   : 'contracts-interpretation-template',
        duration   : 60,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Nov 01, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'fcbfedbf-6187-4b3b-89d3-1a7cb4e11616',
        title      : 'Offenses against a person - Assault',
        slug       : 'offenses-against-a-person-assault',
        description: 'Vicky Chelangat v Republic [2022] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-a-person-assault-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Aug 08, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : '5213f6a1-1dd7-4b1d-b6e9-ffb7af534f28',
        title      : 'Trespass to Person - False Imprisonment',
        slug       : 'trespass-to-person-false-imprisonment',
        description: 'Kenya Fluorspar Company Limited v William Mutua Maseve & another [2014] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'trespass-to-person-false-imprisonment-template',
        duration   : 45,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'May 12, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : '02992ac9-d1a3-4167-b70e-8a1d5b5ba253',
        title      : 'Trespass to Land',
        slug       : 'trespass-to-land',
        description: 'Kenya Power & Lighting Company Ltd v Ringera & 2 others',
        category   : 'accidents-and-injuries',
        template   : 'tort-court-of-appeal-appeal-template-trespass-to-land',
        duration   : 90,
        court      : 'Court of Appeal',
        totalSteps : 8,
        updatedAt  : 'Sep 18, 2021',
        featured   : false,
        progress   : {
            currentStep: 6,
            completed  : 5,
        },
    },
    {
        id         : '2139512f-41fb-4a4a-841a-0b4ac034f9b4',
        title      : 'Constitutional Law - Human Rights',
        slug       : 'constitutional-law-human-rights',
        description: 'Mitu-Bell Welfare Society v Kenya Airports Authority & 2 others;',
        category   : 'civil-and-human-rights',
        template   : 'constitutional-law-supreme-court-case-template-human-rights',
        duration   : 45,
        court      : 'Supreme Court',
        totalSteps : 8,
        updatedAt  : 'Apr 24, 2021',
        featured   : false,
        progress   : {
            currentStep: 6,
            completed  : 5,
        },
    },
    {
        id         : '65e0a0e0-d8c0-4117-a3cb-eb74f8e28809',
        title      : 'Defamation - Slander',
        slug       : 'defamation-slander',
        description: 'Elisha Ochieng Odhiambo v Booker Ngesa Omole [2021] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'defamation-slander-template',
        duration   : 45,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 05, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'c202ebc9-9be3-433a-9d38-7003b3ed7b7a',
        title      : 'Public Nuisance',
        slug       : 'nuisance-public',
        description: 'Republic v Kisanga & 8 others[1989] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'nuisance-public-template',
        duration   : 30,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Oct 14, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'b202fbc9-9be3-444a-9d38-7103r3ed7b7a',
        title      : 'Private Nuisance',
        slug       : 'nuisance-private',
        description: 'Jaswinder Singh Jabbal v Mark John Tilbury & another [2019] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'nuisance-private-template',
        duration   : 30,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Oct 14, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'b201fbc9-9be3-444a-9d39-7103r3ed8b7a',
        title      : 'Strict Liability',
        slug       : 'strict-liability',
        description: 'David M. Ndetei v Orbit Chemical Industries Limited [2014] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'strict-liability-template',
        duration   : 30,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Oct 14, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : '75e0a0e0-d9c0-4227-a3cb-eb74f8e18809',
        title      : 'Vicarious (In-direct) Liability',
        slug       : 'vicarious-liability',
        description: 'Rentco East Africa Limited v Dominic Mutua Ngonzi [2021] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'vicarious-liability-template',
        duration   : 45,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Jun 05, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'c4bd107b-edd4-47a7-a7a8-4fb09732e794',
        title      : 'Trespass to Chattels',
        slug       : 'trespass-to-chattels',
        description: 'PME & MRS. KE vs PNE [2019] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'trespass-to-chattels-template',
        duration   : 120,
        court      : 'Court of Appeal',
        totalSteps : 11,
        updatedAt  : 'Nov 19, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'a203fbc9-9be4-444a-9d48-7103r3ed7b7a',
        title      : 'Intentional Interference with Person - Malicious Prosecution',
        slug       : 'intentional-interference-with-person-malicious-prosecution',
        description: 'Lawrence Onyango Oduori v Attonery General & another [2022] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'intentional-interference-with-person-malicious-prosecution-template',
        duration   : 30,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Oct 14, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'a304fbc5-9be6-474a-9d88-7103r3ed7b7a',
        title      : 'Intentional Interference with Person - Intentional Infliction of Nervous Shock',
        slug       : 'intentional-interference-with-person-intentional-infliction-of-nervous-shock',
        description: 'Peter Ndungu Ngae v Ann Waithera Ndungu & 2 others [2014] eKLR',
        category   : 'accidents-and-injuries',
        template   : 'intentional-interference-with-person-intentional-infliction-of-nervous-shock-template',
        duration   : 30,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Oct 14, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },

    {
        id         : 'f304fdc5-9bg6-474a-9d88-7123r3ed7b7a',
        title      : 'Nuisance to Servitude',
        slug       : 'nuisance-to-servitude',
        description: 'No Case Found',
        category   : 'accidents-and-injuries',
        template   : 'nuisance-to-servitude-template',
        duration   : 30,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Oct 14, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },

    {
        id         : '980ae7da-9f77-4e30-aa98-1b1ea594e775',
        title      : 'Contracts - Limits and Limitations',
        slug       : 'contracts-limits-and-limitations',
        description: 'Odero & another v Ndivo & another (Civil Case E105 of 2021) [2021] KEHC 289 (KLR) (Commercial and Tax) (25 November 2021) (Ruling)',
        category   : 'contracts',
        template   : 'contracts-limits-and-limitations-template',
        duration   : 60,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Dec 16, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'c9748ea9-4117-492c-bdb2-55085b515978',
        title      : 'Offenses against liberty',
        slug       : 'offenses-against-liberty',
        description: 'Dickson Macharia Ndemi v Republic [2016] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-liberty-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'd9748fa9-4117-492c-bdb2-55085b515978',
        title      : 'Offenses against property - Theft',
        slug       : 'offenses-against-property-theft',
        description: 'Sammy Kasaine Ole Kantai v Republic [2022] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-property-theft-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'd9849f10-4117-412c-bdb2-55085b515978',
        title      : 'Offenses against property - Robbery',
        slug       : 'offenses-against-property-robbery',
        description: 'Charles Mwai Kimani v Republic [2022] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-property-robbery-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'db748fa9-4217-492c-bde2-55085b415973',
        title      : 'Offenses against property - Robbery with Violence',
        slug       : 'offenses-against-property-robbery-with-violence',
        description: 'Maritim v Republic (Criminal Appeal E024 of 2021) [2022] KEHC 10256 (KLR) (6 July 2022) (Judgment)',
        category   : 'criminal-defense',
        template   : 'offenses-against-property-robbery-with-violence-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'db758fa9-4237-492c-bde2-55185b415973',
        title      : 'Offenses against property - Handling Stolen Property',
        slug       : 'offenses-against-property-handling-stolen-property',
        description: 'Kaspan Lokitowial v Republic [2018] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-property-handling-stolen-property-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'db759fa9-4237-493c-bde2-55285b415973',
        title      : 'Offenses against property - Burglary',
        slug       : 'offenses-against-property-burglary',
        description: 'LK v Republic [2021] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-property-burglary-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'db759fb9-4237-493d-cde2-55285c415973',
        title      : 'Offenses against property - House Breaking',
        slug       : 'offenses-against-property-house-breaking',
        description: 'Duba v Republic (Criminal Appeal E013 of 2023) [2023] KEHC 21349 (KLR) (27 July 2023) (Judgment)',
        category   : 'criminal-defense',
        template   : 'offenses-against-property-house-breaking-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'db579fa9-2437-439c-bde2-55285b415973',
        title      : 'Offenses against public order - Treason',
        slug       : 'offenses-against-public-order-treason',
        description: 'Obuon v Republic [1983] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-public-order-treason-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'db795fa9-4237-943c-bde2-55285b415973',
        title      : 'Offenses against public order - Sedition',
        slug       : 'offenses-against-public-order-sedition',
        description: 'Robert Alai v The Hon Attorney General & another [2017] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-public-order-sedition-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'ab759fa9-2437-439c-bde2-55285b415973',
        title      : 'Offenses against public order - Prohibited Publications',
        slug       : 'offenses-against-public-order-prohibited-publications',
        description: 'Bloggers Association of Kenya (BAKE) v Attorney General & 3 others; Article 19 East Africa & another (Interested Parties) [2020] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-public-order-prohibited-publications-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'cd795fa9-4237-493b-bde2-55285b415973',
        title      : 'Offenses against public order - Unlawful Oaths',
        slug       : 'offenses-against-public-order-unlawful-oaths',
        description: 'Republic v Thomas Kalume Kingi [2022] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-public-order-unlawful-oaths-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'bd759fc9-4237-493c-bde2-52585b451973',
        title      : 'Offenses against public order - Unlawful Assembly and Riot',
        slug       : 'offenses-against-public-order-unlawful-assembly-and-riot',
        description: 'Ibrahim Adan v Republic [1983] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-public-order-unlawful-assembly-and-riot-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 8,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'bd789fc9-4207-491c-bde2-52585b351974',
        title      : 'Offenses against administration of lawful authority - Perjury',
        slug       : 'offenses-against-administration-of-lawful-authority-perjury',
        description: 'David Omwenga Maobe v Republic [2015] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-administration-of-lawful-authority-perjury-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 8,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 7,
            completed  : 7,
        },
    },
    {
        id         : 'bd798fc9-4270-491c-bdf2-52585b359174',
        title      : 'Offenses against administration of lawful authority - Disobidience of lawful orders',
        slug       : 'offenses-against-administration-of-lawful-authority-disobidience-of-lawful-orders',
        description: 'Republic v County Government of Kitui Ex Parte Fairplan Systems Limited [2022] eKLR',
        category   : 'criminal-defense',
        template   : 'offenses-against-administration-of-lawful-authority-disobidience-of-lawful-orders-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
    {
        id         : 'zd798fc9-4270-491d-bdf2-25558b359174',
        title      : 'Offenses against administration of lawful authority - Contempt of Court',
        slug       : 'offenses-against-administration-of-lawful-authority-contempt-of-court',
        description: 'Githiga & 5 others v Kiru Tea Factory Company Ltd (Petition 13 of 2019) [2023] KESC 41 (KLR) (16 June 2023) (Judgment)',
        category   : 'criminal-defense',
        template   : 'offenses-against-administration-of-lawful-authority-contempt-of-court-template',
        duration   : 90,
        court      : 'High Court',
        totalSteps : 11,
        updatedAt  : 'Apr 04, 2021',
        featured   : false,
        progress   : {
            currentStep: 0,
            completed  : 0,
        },
    },
];
export const demoCourseContent = `
<p class="lead">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aperiam lab et fugiat id magnam minus nemo quam
    voluptatem. Culpa deleniti explica nisi quod soluta.
</p>
<p>
    Alias animi labque, deserunt distinctio eum excepturi fuga iure labore magni molestias mollitia natus, officia pofro
    quis sunt temporibus veritatis voluptatem, voluptatum. Aut blanditiis esse et illum maxim, obcaecati possimus
    voluptate! Accusamus <em>adipisci</em> amet aperiam, assumenda consequuntur fugiat inventore iusto magnam molestias
    natus necessitatibus, nulla pariatur.
</p>
<p>
    Amet distinctio enim itaque minima minus nesciunt recusandae soluta voluptatibus:
</p>
<blockquote>
    <p>
        Ad aliquid amet asperiores lab distinctio doloremque <code>eaque</code>, exercitationem explicabo, minus mollitia
        natus necessitatibus odio omnis pofro rem.
    </p>
</blockquote>
<p>
    Alias architecto asperiores, dignissimos illum ipsam ipsum itaque, natus necessitatibus officiis, perferendis quae
    sed ullam veniam vitae voluptas! Magni, nisi, quis! A <code>accusamus</code> animi commodi, consectetur distinctio
    eaque, eos excepturi illum laboriosam maiores nam natus nulla officiis perspiciatis rem <em>reprehenderit</em> sed
    tenetur veritatis.
</p>
<p>
    Consectetur <code>dicta enim</code> error eveniet expedita, facere in itaque labore <em>natus</em> quasi? Ad consectetur
    eligendi facilis magni quae quis, quo temporibus voluptas voluptate voluptatem!
</p>
<p>
    Adipisci alias animi <code>debitis</code> eos et impedit maiores, modi nam nobis officia optio perspiciatis, rerum.
    Accusantium esse nostrum odit quis quo:
</p>
<pre><code>h1 a {{'{'}}
    display: block;
    width: 300px;
    height: 80px;
{{'}'}}</code></pre>
<p>
    Accusantium aut autem, lab deleniti eaque fugiat fugit id ipsa iste molestiae,
    <a>necessitatibus nemo quasi</a>
    .
</p>
<hr>
<h2>
    Accusantium aspernatur autem enim
</h2>
<p>
    Blanditiis, fugit voluptate! Assumenda blanditiis consectetur, labque cupiditate ducimus eaque earum, fugiat illum
    ipsa, necessitatibus omnis quaerat reiciendis totam. Architecto, <strong>facere</strong> illum molestiae nihil nulla
    quibusdam quidem vel! Atque <em>blanditiis deserunt</em>.
</p>
<p>
    Debitis deserunt doloremque labore laboriosam magni minus odit:
</p>
<ol>
    <li>Asperiores dicta esse maiores nobis officiis.</li>
    <li>Accusamus aliquid debitis dolore illo ipsam molettiae possimus.</li>
    <li>Magnam mollitia pariatur perspiciatis quasi quidem tenetur voluptatem! Adipisci aspernatur assumenda dicta.</li>
</ol>
<p>
    Animi fugit incidunt iure magni maiores molestias.
</p>
<h3>
    Consequatur iusto soluta
</h3>
<p>
    Aliquid asperiores corporis — deserunt dolorum ducimus eius eligendi explicabo quaerat suscipit voluptas.
</p>
<p>
    Deserunt dolor eos et illum laborum magni molestiae mollitia:
</p>
<blockquote>
    <p>Autem beatae consectetur consequatur, facere, facilis fugiat id illo, impedit numquam optio quis sunt ducimus illo.</p>
</blockquote>
<p>
    Adipisci consequuntur doloribus facere in ipsam maxime molestias pofro quam:
</p>
<figure>
    <img
        src="assets/images/pages/help-center/image-1.jpg"
        alt="">
    <figcaption>
        Accusamus blanditiis labque delectus esse et eum excepturi, impedit ipsam iste maiores minima mollitia, nihil obcaecati
        placeat quaerat qui quidem sint unde!
    </figcaption>
</figure>
<p>
    A beatae lab deleniti explicabo id inventore magni nisi omnis placeat praesentium quibusdam:
</p>
<ul>
    <li>Dolorem eaque laboriosam omnis praesentium.</li>
    <li>Atque debitis delectus distinctio doloremque.</li>
    <li>Fuga illo impedit minima mollitia neque obcaecati.</li>
</ul>
<p>
    Consequ eius eum excepturi explicabo.
</p>
<h2>
    Adipisicing elit atque impedit?
</h2>
<h3>
    Atque distinctio doloremque ea qui quo, repellendus.
</h3>
<p>
    Delectus deserunt explicabo facilis numquam quasi! Laboriosam, magni, quisquam. Aut, blanditiis commodi distinctio, facere fuga
    hic itaque iure labore laborum maxime nemo neque provident quos recusandae sequi veritatis illum inventore iure qui rerum sapiente.
</p>
<h3>
    Accusamus iusto sint aperiam consectetur …
</h3>
<p>
    Aliquid assumenda ipsa nam odit pofro quaerat, quasi recusandae sint! Aut, esse explicabo facilis fugit illum iure magni
    necessitatibus odio quas.
</p>
<ul>
    <li>
        <p><strong>Dolore natus placeat rem atque dignissimos laboriosam.</strong></p>
        <p>
            Amet repudiandae voluptates architecto dignissimos repellendus voluptas dignissimos eveniet itaque maiores natus.
        </p>
        <p>
            Accusamus aliquam debitis delectus dolorem ducimus enim eos, exercitationem fugiat id iusto nostrum quae quos
            recusandae reiciendis rerum sequi temporibus veniam vero? Accusantium culpa, cupiditate ducimus eveniet id maiores modi
            mollitia nisi aliquid dolorum ducimus et illo in.
        </p>
    </li>
    <li>
        <p><strong>Ab amet deleniti dolor, et hic optio placeat.</strong></p>
        <p>
            Accusantium ad alias beatae, consequatur consequuntur eos error eveniet expedita fuga laborum libero maxime nulla pofro
            praesentium rem rerum saepe soluta ullam vero, voluptas? Architecto at debitis, doloribus harum iure libero natus odio
            optio soluta veritatis voluptate.
        </p>
    </li>
    <li>
        <p><strong>At aut consectetur nam necessitatibus neque nesciunt.</strong></p>
        <p>
            Aut dignissimos labore nobis nostrum optio! Dolor id minima velit voluptatibus. Aut consequuntur eum exercitationem
            fuga, harum id impedit molestiae natus neque numquam perspiciatis quam rem voluptatum.
        </p>
    </li>
</ul>
<p>
    Animi aperiam autem labque dolore enim ex expedita harum hic id impedit ipsa laborum modi mollitia non perspiciatis quae ratione.
</p>
<h2>
    Alias eos excepturi facilis fugit.
</h2>
<p>
    Alias asperiores, aspernatur corporis
    <a>delectus</a>
    est
    <a>facilis</a>
    inventore dolore
    ipsa nobis nostrum officia quia, veritatis vero! At dolore est nesciunt numquam quam. Ab animi <em>architecto</em> aut, dignissimos
    eos est eum explicabo.
</p>
<p>
    Adipisci autem consequuntur <code>labque cupiditate</code> dolor ducimus fuga neque nesciunt:
</p>
<pre><code>module.exports = {{'{'}}
    purge: [],
    theme: {{'{'}}
        extend: {{'{}'}},
    },
    variants: {{'{}'}},
    plugins: [],
{{'}'}}</code></pre>
<p>
    Aliquid aspernatur eius fugit hic iusto.
</p>
<h3>
    Dolorum ducimus expedita?
</h3>
<p>
    Culpa debitis explicabo maxime minus quaerat reprehenderit temporibus! Asperiores, cupiditate ducimus esse est expedita fuga hic
    ipsam necessitatibus placeat possimus? Amet animi aut consequuntur earum eveniet.
</p>
<ol>
    <li>
        <strong>Aspernatur at beatae corporis debitis.</strong>
        <ul>
            <li>
                Aperiam assumenda commodi lab dicta eius, “fugit ipsam“ itaque iure molestiae nihil numquam, officia omnis quia
                repellendus sapiente sed.
            </li>
            <li>
                Nulla odio quod saepe accusantium, adipisci autem blanditiis lab doloribus.
            </li>
            <li>
                Explicabo facilis iusto molestiae nisi nostrum obcaecati officia.
            </li>
        </ul>
    </li>
    <li>
        <strong>Nobis odio officiis optio quae quis quisquam quos rem.</strong>
        <ul>
            <li>Modi pariatur quod totam. Deserunt doloribus eveniet, expedita.</li>
            <li>Ad beatae dicta et fugit libero optio quaerat rem repellendus./</li>
            <li>Architecto atque consequuntur corporis id iste magni.</li>
        </ul>
    </li>
    <li>
        <strong>Deserunt non placeat unde veniam veritatis? Odio quod.</strong>
        <ul>
            <li>Inventore iure magni quod repellendus tempora. Magnam neque, quia. Adipisci amet.</li>
            <li>Consectetur adipisicing elit.</li>
            <li>labque eum expedita illo inventore iusto laboriosam nesciunt non, odio provident.</li>
        </ul>
    </li>
</ol>
<p>
    A aliquam architecto consequatur labque dicta doloremque <code>&lt;li&gt;</code> doloribus, ducimus earum, est <code>&lt;p&gt;</code>
    eveniet explicabo fuga fugit ipsum minima minus molestias nihil nisi non qui sunt vel voluptatibus? A dolorum illum nihil quidem.
</p>
<ul>
    <li>
        <p>
            <strong>Laboriosam nesciunt obcaecati optio qui.</strong>
        </p>
        <p>
            Doloremque magni molestias reprehenderit.
        </p>
        <ul>
            <li>Accusamus aperiam blanditiis <code>&lt;p&gt;</code> commodi</li>
            <li>Dolorum ea explicabo fugiat in ipsum</li>
        </ul>
    </li>
    <li>
        <p>
            <strong>Commodi dolor dolorem dolores eum expedita libero.</strong>
        </p>
        <p>
            Accusamus alias consectetur dolores et, excepturi fuga iusto possimus.
        </p>
        <ul>
            <li>
                <p>
                    Accusantium ad alias atque aut autem consequuntur deserunt dignissimos eaque iure <code>&lt;p&gt;</code> maxime.
                </p>
                <p>
                    Dolorum in nisi numquam omnis quam sapiente sit vero.
                </p>
            </li>
            <li>
                <p>
                    Adipisci lab in nisi odit soluta sunt vitae commodi excepturi.
                </p>
            </li>
        </ul>
    </li>
    <li>
        <p>
            Assumenda deserunt distinctio dolor iste mollitia nihil non?
        </p>
    </li>
</ul>
<p>
    Consectetur adipisicing elit dicta dolor iste.
</p>
<h2>
    Consectetur ea natus officia omnis reprehenderit.
</h2>
<p>
    Distinctio impedit quaerat sed! Accusamus
    <a>aliquam aspernatur enim expedita explicabo</a>
    . Libero molestiae
    odio quasi unde ut? Ab exercitationem id numquam odio quisquam!
</p>
<p>
    Explicabo facilis nemo quidem natus tempore:
</p>
<table class="table table-striped table-bordered">
    <thead>
        <tr>
            <th>Wrestler</th>
            <th>Origin</th>
            <th>Finisher</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Bret “The Hitman” Hart</td>
            <td>Calgary, AB</td>
            <td>Sharpshooter</td>
        </tr>
        <tr>
            <td>Stone Cold Steve Austin</td>
            <td>Austin, TX</td>
            <td>Stone Cold Stunner</td>
        </tr>
        <tr>
            <td>Randy Savage</td>
            <td>Sarasota, FL</td>
            <td>Elbow Drop</td>
        </tr>
        <tr>
            <td>Vader</td>
            <td>Boulder, CO</td>
            <td>Vader Bomb</td>
        </tr>
        <tr>
            <td>Razor Ramon</td>
            <td>Chuluota, FL</td>
            <td>Razor’s Edge</td>
        </tr>
    </tbody>
</table>
<p>
    A aliquid autem lab doloremque, ea earum eum fuga fugit illo ipsa minus natus nisi <code>&lt;span&gt;</code> obcaecati pariatur
    perferendis pofro <code>suscipit tempore</code>.
</p>
<h3>
    Ad alias atque culpa <code>illum</code> earum optio
</h3>
<p>
    Architecto consequatur eveniet illo in iure laborum minus omnis quibusdam sequi temporibus? Ab aliquid <em>“atque dolores molestiae
    nemo perferendis”</em> reprehenderit saepe.
</p>
<p>
    Accusantium aliquid eligendi est fuga natus, <code>quos</code> vel? Adipisci aperiam asperiores aspernatur consectetur cupiditate
    <a><code>@distinctio/doloribus</code></a>
    et exercitationem expedita, facere facilis illum, impedit inventore
    ipsa iure iusto magnam, magni minus nesciunt non officia possimus quod reiciendis.
</p>
<h4>
    Cupiditate explicabo <code>hic</code> maiores
</h4>
<p>
    Aliquam amet consequuntur distinctio <code>ea</code> est <code>excepturi</code> facere illum maiores nisi nobis non odit officiis
    quisquam, similique tempora temporibus, tenetur ullam <code>voluptates</code> adipisci aperiam deleniti <code>doloremque</code>
    ducimus <code>eos</code>.
</p>
<p>
    Ducimus qui quo tempora. lab enim explicabo <code>hic</code> inventore qui soluta voluptates voluptatum? Asperiores consectetur
    delectus dolorem fugiat ipsa pariatur, quas <code>quos</code> repellendus <em>repudiandae</em> sunt aut blanditiis.
</p>
<h3>
    Asperiores aspernatur autem error praesentium quidem.
</h3>
<h4>
    Ad blanditiis commodi, doloribus id iste <code>repudiandae</code> vero vitae.
</h4>
<p>
    Atque consectetur lab debitis enim est et, facere fugit impedit, possimus quaerat quibusdam.
</p>
<p>
    Dolorem nihil placeat quibusdam veniam? Amet architecto at consequatur eligendi eveniet excepturi hic illo impedit in iste magni maxime
    modi nisi nulla odio placeat quidem, quos rem repellat similique suscipit voluptate voluptates nobis omnis quo repellendus.
</p>
<p>
    Assumenda, eum, minima! Autem consectetur fugiat iste sit! Nobis omnis quo repellendus.
</p>
`;
export const mootCourtTemplateStep_0_Content = `
<p class="lead">
    Ujamaa Apartment Case.
</p>

<p>
Nestled in the heart of New Amani, a bustling metropolis in the Republic of Shakah,
stood the sprawling Green Pastures slum. For decades, it housed a vibrant community
of over 500 families, many of whom had built their lives and raised their children
within its confines. However, the Green Pastures' ramshackle dwellings lacked basic
amenities like sanitation and proper infrastructure. Sanitation woes were particularly
pressing, posing a significant health risk to residents.
</p>

<p>
To address this issue, the government of Shakah through Amani Housing Authority
(AHA), a government parastatal unveiled the ambitious Ujamaa Apartments project.
This multi-million-dollar initiative envisioned the construction of 400 high-quality,
affordable apartments on the very site currently occupied by Green Pastures. The
project promised not only improved living conditions but also a potential economic
boost to the area.
</p>

<p>
The announcement, however, was met with mixed reactions. Residents of Green
Pastures, while acknowledging the need for better housing, were apprehensive. The
AHA's resettlement plan offered meagre compensation and relocation to a distant,
underdeveloped area with limited access to schools and jobs. Many residents felt this
would disrupt their livelihoods and sever their social ties.
</p>

<p>
Adding fuel to the fire, rumors swirled that the new apartments wouldn't be readily
available to the displaced community. Whispers suggested priority would be given to
government officials and wealthy individuals, effectively pushing the legitimate
residents to the back of the queue.
</p>

<p>
Discontent soon morphed into action. Residents formed the Green Pastures
Community Action Group (GPCAG). Led by the charismatic Mama Amani, a respected
elder, they organized peaceful protests outside the AHA headquarters. Chanting
slogans and holding placards, the GPCAG demanded fair compensation, the right to
return to the new development after resettlement, and a transparent allocation
process.
</p>

<p>
The protests attracted media attention, placing pressure on the AHA. However, the
authority remained steadfast. They argued the project was crucial for public health and
the economic development of the area. While they assured fair compensation and a
relocation plan, details remained vague.
</p>

<p>
Unconvinced, the GPCAG decided to take legal action. Their lawyer, Ms. Ndlovu, a
human rights advocate, advised filing a petition in the High Court. She argued the
AHA's actions violated the residents' right to property, a fundamental right enshrined in
the Amani constitution. Furthermore, Ms. Ndlovu contended the relocation plan
infringed on their right to a decent standard of living. She also raised concerns about
the rumors regarding the allocation of apartments, suggesting potential discrimination
based on socio-economic status.
</p>

<p>
Meanwhile, the AHA countered by arguing for the court's deference to their expertise in
urban planning and development. They maintained the project served the greater
public good and the relocation plan, while imperfect, was the best solution under the
circumstances. They downplayed the rumors of discrimination, claiming a transparent
allocation process would be implemented.
</p>

<hr>
<h2>
    Issues for Consideration
</h2>
<p>
The Republic of Shakah is a country, whose legal system replicates that of Kenya.
Additionally, the Republic of Shakah has ratified the International Covenant on Civil
and Political Rights (ICCPR)</em>.
</p>
<p>
    Some of the issues for consideration are:
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>Can the AHA restrict these protests in any way?</li>
</ol>
`;

export const mootCourtTemplateStep_1_Content = `
<p class="lead">
    Retrieved facts.
</p>
<h2>
    Verifiable Satements from the case.
</h2>
<p>
    These are statements that were said, written, seen, done or promised by any party in the compromise. These also include mentioned dates, description of events
    that took place as well as description of the places.
</p>
<p>
    Facts to take note of:
</p>
<table class="table table-striped table-bordered">
    <thead>
        <tr>
            <th>Fact</th>
            <th>Description</th>
            <th>Abbreviation</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Republic of Shakah</td>
            <td>This is the name of the republic in the compromis.</td>
            <td>RoS</td>
        </tr>
        <tr>
            <td>New Amani</td>
            <td>This is the name of the metropolis found in the Republic of Shakah</td>
            <td>NA</td>
        </tr>
        <tr>
            <td>Green Pastures Slum</td>
            <td>This is the name of the slum found in New Amani</td>
            <td>GPS</td>
        </tr>
        <tr>
            <td>Amani Housing Authority</td>
            <td>A parastatal within the government of the Republic of Shakah</td>
            <td>AHA</td>
        </tr>
        <tr>
            <td>Ujamaa Apartments Project</td>
            <td>This is the name of the project that targets construction of 400 high quality affordable apartments in Green Pastures lum</td>
            <td>UAP</td>
        </tr>
    </tbody>
</table>
<hr>
<h2>
    Description of State of Affairs before Action
</h2>
<p>
    Green pastures slum is at the heart of new amani and has been home to 500 families for decades.
</p>
<p>
    Just like any other slum across the globe, it lacked basic amenities like:
</p>
<ol>
    <li>Sanitation - This posed health risk to residents</li>
    <li>Poor infrastructure</li>
</ol>
<hr>
<h2>
    Description of Action / Proposed Action
</h2>
<p>
    The government, through Amani Housing Authority has unveiled an Ujamaa Apartments project with a plan to construct 400 high-quality and affordable apartments as a
    replacement to the houses currently existing in Green Pastures Slum.
</p>
<p>
    This project promises:
</p>
<ol>
    <li>Improved living conditions</li>
    <li>Potential economic boosts to the area</li>
</ol>
<hr>
<h2>
    Outcome of the Action / Proposed Action
</h2>
<p>
    Learning that there houses were going to be demolished, the residents of Green Pastures Slum became apprehensive.
</p>
<p>
    They raised several concerns some of which were:
</p>
<ol>
    <li>Resettlement plan offered meagre compensation</li>
    <li>Relocation was a distant, underdeveloped area with limited access to schools and jobs</li>
    <li>Majority of the residents felt that this would disrupt their livelihoods and sever their social ties</li>
</ol>

<hr>
<h2>
    Chronology of events
</h2>
<p>
    The apprehension by the residents of Green Pastures slum led to a case at the high court in the republic of Shakah.
    The sequence of events that led to the case being taken to the high court are:
</p>
<ol>
    <li>
        <strong>News and Rumors</strong>
        <ul>
            <li>
                There were rumors that the new apartments would not be readily available to the displaced community.
            </li>
            <li>
                Priority would instead be given to government officials and wealthy individuals.
            </li>
        </ul>
    </li>
    <li>
        <strong>Community Action Group Formed</strong>
        <ul>
            <li>The residents mobilized themselves and formed Green Pastures Community Action Group led by Mama Amani, one of the elders.</li>
            <li>They organized peaceful demostrations</li>
            <li>Protest attracted media attention</li>
            <li>
                Demands made
                <ul>
                    <li>Fair Compensation</li>
                    <li>Right to return to the new development after completion</li>
                    <li>Transparent allocation process</li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Amani Housing Authority Response</strong>
        <ul>
            <li>Argued that the project was crucial for public health and economic development of the area</li>
            <li>Assured Fair Compensation</li>
            <li>Assured a relocation plan</li>
        </ul>
    </li>
</ol>
<p>
    Green Pastures Community Action Group (GPCAG), through their lawyer Mr. Ndlovu, a human rights advocate, took the action to the high court.
</p>
`;

export const mootCourtTemplateStep_2_Content = `
<p class="lead">
    What are the legal questions that need to be determined
</p>
<h2>
    Issues for determination
</h2>
<p>
    According to the compromis, the issues listed for determination are:
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>AHA restrict these protests in any way?</li>
</ol>

<h2>
    Applicant's submissions
</h2>
<ol>
    <li>
        <strong>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</strong>
        <ol>
            <li>
                <strong>Article 23(1)</strong>
                “The High Court has jurisdiction, in accordance with Article 165, to hear and determine applications for redress of a denial, violation or infringement
                of, or threat of, a right or fundamental freedom in the Bill of Rights.
            </li>
            <li>
                <strong>Article 165(7)</strong>
                “For the purposes of clause (6) the High Court may recall for the record of any proceedings before any subordinate court or person, body or authority
                referred to in clause 6, and may make or order or give any direction it considers appropriate to ensure the fair administration of justice.
            </li>
        </ol>
    </li>
    <li>
        <strong>Should this be addressed in a lower court first?</strong>
    </li>
    <li>
        <strong>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</strong>
    </li>
    <li>
        <strong>Does the relocation plan adequately address the right to a decent standard of living?</strong>
    </li>
    <li>
        <strong>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</strong>
    </li>
    <li>
        <strong>Do the GPCAG's protests fall under the right to assembly and demonstration?</strong>
    </li>
    <li>
        <strong>AHA restrict these protests in any way?</strong>
    </li>
</ol>

<h2>
    Respondent's submission
</h2>
<ol>
    <li>
        <strong>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</strong>
    </li>
    <li>
        <strong>Should this be addressed in a lower court first?</strong>
    </li>
    <li>
        <strong>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</strong>
    </li>
    <li>
        <strong>Does the relocation plan adequately address the right to a decent standard of living?</strong>
    </li>
    <li>
        <strong>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</strong>
    </li>
    <li>
        <strong>Do the GPCAG's protests fall under the right to assembly and demonstration?</strong>
    </li>
    <li>
        <strong>AHA restrict these protests in any way?</strong>
    </li>
</ol>

`;

export const mootCourtTemplateStep_3_Content = `
<p class="lead">
    The rules as per the issues raised
</p>
<h2>
    Rules as per Applicant's Submissions
</h2>
<ol>
    <li>
        <strong>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Should this be addressed in a lower court first?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Does the relocation plan adequately address the right to a decent standard of living?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Do the GPCAG's protests fall under the right to assembly and demonstration?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>AHA restrict these protests in any way?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
</ol>

<h2>
    Rules as per Respondent's Submissions
</h2>
<ol>
    <li>
        <strong>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Should this be addressed in a lower court first?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Does the relocation plan adequately address the right to a decent standard of living?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Do the GPCAG's protests fall under the right to assembly and demonstration?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>AHA restrict these protests in any way?</strong>
        <ul>
            <li>
                International References
                <ul>
                    <li>
                        Relevant Treaties
                        <ul>
                            <li>
                                Treaty #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Treaty #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Domestic References
                <ul>
                    <li>
                        Relevant Constitutional References
                        <ul>
                            <li>
                                Reference #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Reference #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Statutes
                        <ul>
                            <li>
                                Statute #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Statute #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Relevant Domestic Case Laws
                        <ul>
                            <li>
                                Case Law #1
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                            <li>
                                Case Law #2
                                <ul>
                                    <li>Item #1</li>
                                    <li>Item #2</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
</ol>
`;

export const mootCourtTemplateStep_4_Content = `
<p class="lead">
    Legal reasoning and findings.
</p>
<h2>
    Analysis as per Applicant's Submissions
</h2>
<ol>

    <li>
        <strong>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Applicant</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Should this be addressed in a lower court first?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Applicant</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Applicant</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Does the relocation plan adequately address the right to a decent standard of living?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Applicant</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Applicant</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Do the GPCAG's protests fall under the right to assembly and demonstration?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Applicant</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>AHA restrict these protests in any way?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Applicant</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>
</ol>

<h2>
    Analysis as per Respondent's Submissions
</h2>
<ol>

    <li>
        <strong>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Respondent</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Should this be addressed in a lower court first?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Respondent</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Respondent</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Does the relocation plan adequately address the right to a decent standard of living?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Respondent</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Respondent</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>Do the GPCAG's protests fall under the right to assembly and demonstration?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Respondent</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>AHA restrict these protests in any way?</strong>
        <ul>
            <li>
                <strong>Written literature and other references</strong>
                <ul>
                    <li>
                        <strong>Black Law Dictionary</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sessional Reports</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Judicial Reviews</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>General Comments</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Policy & Guidance</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decree / Circular / Regulations / Fact Finding</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Statements</strong>
                        <ul>
                            <li>Item #1:</li>
                            <li>Item #2:</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Submissions on behalf of the Respondent</strong>
                <ul>
                    <li>Item #1:</li>
                    <li>Item #1:</li>
                </ul>
            </li>
        </ul>
    </li>
</ol>

`;

export const mootCourtTemplateStep_5_Content = `
<p class="lead">
    Summary of arguments for each issue
</p>
<h2>
    Submissions on each claim
</h2>
<h3>
    Claim I - Whether the High Court has Jurisdiction to hear the matter.
</h3>
<p>
    The civil nature of this case and the belief of the petitioners that their fundamental rights are being denied,
    violated and threatened through an impending and ruthless eviction disguised in the name of a housing development
    project qualifies this case to be heard at the hight court.
</p>
<h3>
    Claim II - Whether the Issues can be addressed in lower courts
</h3>
<p>
    The lower courts are subjected to some limitation as per the pecuniary limitation which brings about a point of law. This limitation
    is likely limit and constrain judgement not on the basis of fairness but on the basis of administration and procedure.
</p>
<h3>
    Claim III - Whether the displacement plan constitute a violation of the Green Pastures resident's right to property
</h3>
<p>
    The displacement plan is not only unconstitutional, but also violates petitioners right to property amongst other rights like
    right to equality and freedom from discrimination, right to fair and administrative action and right to fair hearing.
</p>
<h3>
    Claim IV - Whether the relocation plan adequately address the right to a descent standard of living
</h3>
<p>
    The relocation plan lacks merit on process and content. With regards to process it fails on consultation with the affected persons. And on
    content it lacks basic international recommendations that guarantees a fair, just and inclusive resettlement option that is inter alia
    accessible, and with essential services like health and education.
</p>
<h3>
    Claim V - Whether Amani's constitution gaurantee of equality protect the residents from potential social-economic discrimination in the allocation of apartments.
</h3>
<p>
    Amani's constitution, though does not mention the term 'structural interdicts' and instead mentions 'appropriate reliefs'. The term 'relief' in the
    context of Article 23(3) may be a declaration of rights, an interdict, a mandamus, or such other relief as may be required to ensure that the rights enshrined
    in the constitution are protected and enforced. With this understanding, the constitution gauranee of equality actually protects potential and foreseeacable
    social-economic discrimination in the allocation of apartments.
</p>
<h3>
    Claim VI - Whether GPCAGs protests fall under the right to assembly and demostration
</h3>
<p>
    The right to assembly is guaranteed in the constitution under two conditions: the assembly must be "peaceably" and those attending the assembly must be "unarmed". The
    constitution provides a right to freedom of association which gaurantees the freedom to "form, join, or participate in the activities of an association of any kind".
    GPCAG's protests falls under the right to assembly and demonstration.
</p>
<h3>
    Claim VII - Whether AHA may restrict these protests?
</h3>
<p>
    The constituton allows the protests and assembly when they are peaceful, when the participants in the assembly are unarmed and the assembly and protests is done at the
    right time and the right place. Any arrangement contrary to these conditions will limit this right of assembly and demostration.
</p>
`;

export const mootCourtTemplateStep_6_Content = `
<p class="lead">
    Detailed arguments for each issue
</p>

`;

export const mootCourtTemplateStep_7_Content = `
<p class="lead">
    Moot conclusion
</p>

`;

export const mootCourtTemplateStep_8_Content = `
<p class="lead">
    Structured Applicant Memorial
</p>

`;

export const mootCourtTemplateStep_9_Content = `
<p class="lead">
    Structured Respondent Memorial
</p>

`;

export const mootCourtTemplateStep_10_Content = `
<p class="lead">
    Reviews and Comments from your network of friends
</p>

`;

export const mootCourtTemplateStep_11_Content = `
<p class="lead">
    Submission
</p>

`;

// unlawfullAssemblyAndRiotsCaseLawSteps
export const unlawfullAssemblyAndRiotsCaseLawStep_0_Content = `
<p class="lead">
    Page 1.
</p>
<h2>
    The issues to be presented as arguments before the court
</h2>
<p>
    The issues picked to be presented before the court of law are as below.
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>AHA restrict these protests in any way?</li>
</ol>
`;

export const unlawfullAssemblyAndRiotsCaseLawStep_1_Content = `
<p class="lead">
    Page 2.
</p>
<h2>
    The issues to be presented as arguments before the court
</h2>
<p>
    The issues picked to be presented before the court of law are as below.
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>AHA restrict these protests in any way?</li>
</ol>
`;

export const unlawfullAssemblyAndRiotsCaseLawStep_2_Content = `
<p class="lead">
    Page 3.
</p>
<h2>
    The issues to be presented as arguments before the court
</h2>
<p>
    The issues picked to be presented before the court of law are as below.
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>AHA restrict these protests in any way?</li>
</ol>
`;

export const unlawfullAssemblyAndRiotsCaseLawStep_3_Content = `
<p class="lead">
    Page 4.
</p>
<h2>
    The issues to be presented as arguments before the court
</h2>
<p>
    The issues picked to be presented before the court of law are as below.
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>AHA restrict these protests in any way?</li>
</ol>
`;

export const unlawfullAssemblyAndRiotsCaseLawStep_4_Content = `
<p class="lead">
    Page 5.
</p>
<h2>
    The issues to be presented as arguments before the court
</h2>
<p>
    The issues picked to be presented before the court of law are as below.
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>AHA restrict these protests in any way?</li>
</ol>
`;

export const unlawfullAssemblyAndRiotsCaseLawStep_5_Content = `
<p class="lead">
    Page 6.
</p>
<h2>
    The issues to be presented as arguments before the court
</h2>
<p>
    The issues picked to be presented before the court of law are as below.
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>AHA restrict these protests in any way?</li>
</ol>
`;

export const unlawfullAssemblyAndRiotsCaseLawStep_6_Content = `
<p class="lead">
    Page 7.
</p>
<h2>
    The issues to be presented as arguments before the court
</h2>
<p>
    The issues picked to be presented before the court of law are as below.
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>AHA restrict these protests in any way?</li>
</ol>
`;

export const unlawfullAssemblyAndRiotsCaseLawStep_7_Content = `
<p class="lead">
    Page 8.
</p>
<h2>
    The issues to be presented as arguments before the court
</h2>
<p>
    The issues picked to be presented before the court of law are as below.
</p>
<ol>
    <li>Was the High Court the appropriate forum for the GPCAG to challenge the AHA's actions?</li>
    <li>Should this be addressed in a lower court first?</li>
    <li>Does the AHA's displacement plan constitute a violation of the Green Pastures residents' right to property?</li>
    <li>Does the relocation plan adequately address the right to a decent standard of living?</li>
    <li>Does the Amani constitution's guarantee of equality protect the residents from potential socio-economic discrimination in the allocation of apartments?</li>
    <li>Do the GPCAG's protests fall under the right to assembly and demonstration?</li>
    <li>AHA restrict these protests in any way?</li>
</ol>
`;


// perjuryCaseLawSteps
export const perjuryCaseLawStep_0_Content = `
<p>
    <strong>CRIMINAL PETITION NO. 77 OF 2014</strong>
</p>
<p class="lead">
    This is a case between David Omwenga Maobe who commited perjury in his testimony against John Teleyio Ole Sawoyo denying he had sold his piece of land
    known as Transmara/Osinoni/49.
</p>
<h2>
    Chronology of events
</h2>
<p>
    This issue started from the high court and successful appealed at the court of appeal ruling on David Omwenga's favor.
</p>
<ol>
    <li>
        <strong>Civil Suit No. 149 of 2005 :</strong> David Vs John in order to recover land known as Transmara/Osinoni/49, about 16.5acres
        <ul>
            <li>Applicant was a teacher when ethnic clashes erupted the area</li>
            <li>Applicant feared for his life, moved from the land that he had developed, tilled and planted maize crops</li>
            <li>Applicant sold the maize to the defendant</li>
            <li>In 2005 applicant learnt the defandant had changed the records to remove the plaintiffs name and insert his</li>
            <li>During this period, land adjudication and demarcation was in progress and hence plainiff brought the case to recover the piece of land from the defendant.</li>
            <li>22nd July 2009 : Judgement made in favor of the applicant.</li>
        </ul>
    </li>
    <li>
        <strong>Defendant Appealed</strong> the Judgement at the Court of Appeal : John V David on three main grounds
        <ul>
            <li>The validity of the transactions between the appellant(defendant) and the respondent(plaintiff)</li>
            <li>The validity of the respondent’s claim in the light of limitation of actions act and in the absence of particulars of trust.</li>
            <li>The scope of the doctrine of trust and whether the respondent proved the same to the required standard.</li>
        </ul>
    </li>
    <li><strong>22nd November 2019 :</strong> Appeal is dismissed with costs to the respondent</li>
    <li><strong>25th August 2014 :</strong> Respondent is charged with perjury contrary to section 108(1)(a) as read with section 110 of the penal code</li>
    <li>
        <strong>The Perjury Charge:</strong>
        <ul>
            <li>
                On the 18th day of may, 2009 at Kisii High court in Kisii District within Kisii County in a judicial proceedings, civil suit No. 149 of 2005
                in the High Court of Kenya in which one David Omwenga Maobe was the appellant and John Teleyio Ole Sawoyo the respondent, while on a sworn
                oath before Justice Musinga, knowingly gave false testimony touching on a matter which was material to a suit pending in that proceeding,
                namely, denial of having sold a piece of land marked  49/Osinoni adjudication section to John Teleyio Ole Sawoyo.
            </li>
        </ul>
    </li>
</ol>
`;

export const perjuryCaseLawStep_1_Content = `
<p class="lead">
    The applicant David Omwenga Maobe brings a petition to the high court inter alia to quash the subordinates court's proceedings and that the said subordinate
    court be prohibited from further proceedings of perjury
</p>
<h2>
    Prosecutions Averment
</h2>
<p>
    The prosecution avers that:
</p>
<ol>
    <li>If the petitioner committed perjury, for which he is charged, the proceedings must proceed to conclusion at Kilgoris Magistrate Court.</li>
    <li>By virtue of section 4 and the 1st schedule of the criminal procedure code, any subordinate court of the first class has jurisdiction and powers in proceedings of perjury.</li>
</ol>
`;

export const perjuryCaseLawStep_2_Content = `
<p class="lead">
    What is the legal question that needs to be determined.
</p>
<h2>
    Issues for determination
</h2>
<p>
    Both prosecutor and respondent are interested in a specific question of law.
</p>
<ol>
    <li>
        Can the petitioner be charged with perjury even though the two courts, High Court and the Court of Appeal agreed with him
        and gave judgement in his favor?
    </li>
    <li>
        Should this process be countermanded by the High Court under article 165(7) of the Constitution and its supervisory powers confirred thereto simply
        because two superior courts, found for the applicant, superior court under article 165, High court and appellate superior court, under article 164,
        of the Kenyan constitution?
    </li>
</ol>
<h2>
    Respondent's submission
</h2>
<p>
    On the first issue, the respondent submits in the affirmative.
</p>
<ol>
    <li>A person can and will be charged with perjury even after obtaining judgment in his favour through perjury.</li>
    <li>Judges, (and indeed magistrates) often base their verdicts, sentences or other important decisions on sworn testimony and signed documents.</li>
    <li>Statements given under oath are presumed to be truthful or at least made in good faith.</li>
    <li>The legal effect of an oath is to subject the person to penalties for perjury if testimony is false.</li>
</ol>
<h2>
    Petitioner's submission
</h2>
<p>
    On the second issue, the petitioner brings this petition under article 23(1) and 165(7) of the Kenyan Constitution
</p>
<ol>
    <li>
        <strong>Article 23(1)</strong>
        “The High Court has jurisdiction, in accordance with Article 165, to hear and determine applications for redress of a denial, violation or infringement
        of, or threat of, a right or fundamental freedom in the Bill of Rights.
    </li>
    <li>
        <strong>Article 165(7)</strong>
        “For the purposes of clause (6) the High Court may recall for the record of any proceedings before any subordinate court or person, body or authority
        referred to in clause 6, and may make or order or give any direction it considers appropriate to ensure the fair administration of justice.
    </li>
</ol>
`;

export const perjuryCaseLawStep_3_Content = `
<p class="lead">
    The rules as per the issues raised
</p>
<h2>
    On whether a person can be charged for purjery even though the accused was found veracious by two courts
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1:</li>
                    <li>Treaty #2:</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        <p>
                            <strong>Case Law #1: R. Vs Archar [2002] All ER 1) 314</strong>
                        </p>
                        <ol>
                            <li>A fabloid newspaper reported that Jeffery Archar paid and had sexual intercourse with a prostitute.</li>
                            <li>Jeffery Archar sued for libel.</li>
                            <li>He won and was awarded $500,000 in damages.</li>
                            <li>Later on, it emerged that it was true he had indeed paid for sex with a prostitute and fabricated evidence to win the libel.</li>
                            <li>His alibi was a fabricated alibi that had convinced the Judge.</li>
                            <li>He was later tried in a criminal court.</li>
                            <li>He was found guilty of perjury and sentenced to four years imprisonment.</li>
                            <li>This conviction was upheld by the English Court of Appeal in R vs. Acher (2003) 1 Cr. Appeal R(5) 86.</li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <strong>Case Law #2: </strong>
                        </p>
                        <ol>
                            <li>
                                Point #1
                            </li>
                        </ol>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional references
                <ul>
                    <li>Reference #1:</li>
                    <li>Reference #2:</li>
                </ul>
            </li>
            <li>
                Relevant domestic satutes
                <ul>
                    <li>Statute #1:</li>
                    <li>Statute #2:</li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <p>
                            <strong>Case Law #1: </strong>
                        </p>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <strong>Case Law #2: </strong>
                        </p>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
<ul>
<h2>
    On whether this court should quash the criminal proceedings going on in the subordinate court and prevent the subordinate court
    from further proceedings on the matter.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1:</li>
                    <li>Treaty #2:</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        <p>
                            <strong>Case Law #1:</strong>
                        </p>
                        <ol>
                            <li>Point #1:</li>
                            <li>Point #2:</li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <strong>Case Law #2: </strong>
                        </p>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional references
                <ul>
                    <li>Reference #1:</li>
                    <li>Reference #2:</li>
                </ul>
            </li>
            <li>
                Relevant domestic satutes
                <ul>
                    <li>Statute #1:</li>
                    <li>Statute #2:</li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>
                            Zablon Mokua V Solomon M. Choti & 3 Others [2016] EKLR
                        </strong>
                        <ul>
                            <li>
                                <strong>Legal Reasoning</strong>
                                <ol>
                                    <li>The high court supervisory jurisdiction over subordinate matters has to be exercised in a structured manner</li>
                                    <li>
                                        It is not open for a High Court Judge to preside over matters before the subordinate court. In the event that a High Court calls for a
                                        subordinate court’s file or transfers the same from the subordinate court to the High Court, the most logical thing to do would be to open a file
                                        in the High Court in which the orders of the Judge would be made.
                                    </li>
                                    <li>
                                        There was an error of omission in failing to open a High Court file as soon as the lower court file was called by the High Court Judge and
                                        similarly, there was an error/mistake of commission when the Judge made the impugned orders inside the lower court file. The error is apparent
                                        on the face of the record and my view is that it is this same court that can correct the said error through the orders for review sought.
                                    </li>
                                    <li>
                                        The statutory grounds upon which orders for review can be obtained are;
                                        <ol>
                                            <li>there ought to exist an error or mistake apparent on the face of the record.</li>
                                            <li>the applicant has discovered a new and important matter in evidence which after the exercise of due diligence was not within his knowledge</li>
                                            <li>that there is sufficient reason to occasion the review where the impugned order if reviewed, would lead the court in promoting public interest and enhancing public confidence in the rule of law and the system of justice see <strong>Benjoh Amalgamated Limited & Another vs. Kenya Commercial Bank Limited (supra).</strong>.</li>
                                            <li>where the applicant was wrongly deprived of an opportunity to be heard</li>
                                            <li>where the impugned decision or order was procured illegally or by fraud or perjury see <strong>Serengeti Road Services -v- CRBD Bank Limited [2011] 2 EA 395</strong></li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <strong>Case Law #2: </strong>
                        </p>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
<ul>
`;

export const perjuryCaseLawStep_4_Content = `
<p class="lead">
    Analysis and Legal Reasoning
</p>
<h2>
    On whether a person can be charged for purjery even though the accused was found veracious by two courts
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Sessional Report #1:
        <ol>
            <li>Sessional Report Point #1:</li>
            <li>Sessional Report Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>At the centre of this perjury is the proprietary interest in the mentioned parcel of land.</li>
    <li>Perjury subverts and perverts public justice system and is abhorred.</li>
    <li>The perpetrator of perjury must be stopped if the public justice system were to retain credibility.</li>
    <li>Perjury subverts and perverts public justice system and is abhorred.</li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
<h2>
    On whether this court should quash the criminal proceedings going on in the subordinate court and prevent the subordinate court
    from further proceedings on the matter.
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Sessional Paper #1:
        <ol>
            <li>Sessional Paper Point #1:</li>
            <li>Sessional Paper Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>The perpetrator of perjury must be stopped if the public justice system were to retain credibility.</li>
    <li>
        If the perjury proceedings were to proceed to conclusion and the accused- the petitioner- were convicted but quashed by the order of this court,
        who is the aggrieved person vis-à-vis the Land Parcel No. Transmara/Osinoni/49.
    </li>
    <li>
        Question on What triggered the perjury charges
        <ol>
            <li>The sale agreement and letter written by the petitioner was dismissed by both the trial and the high court based on the petitioners sworn testimony</li>
            <li>These two documents were forwarded to National Registration Bureau in Nairobi and Document Examiners at CID headquarters for analysis which revealed that the signatures were made by the same person</li>
            <li>Petitioner denied the thumb print that appeared in the agreement and the analysis revealed they were his</li>
            <li>The upshot is, the petitioner lied under oath in order to aid him getting judgement in the suit at the high court in 2009</li>
            <li>This conviction was upheld by the English Court of Appeal in R vs. Acher (2003) 1 Cr. Appeal R(5) 86.</li>
        </ol>
    </li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
`;

export const perjuryCaseLawStep_5_Content = `
<p class="lead">
    What were the orders, penalties, charges, sentencing, costs e.t.c.
</p>
<h2>
    The holding
</h2>
<p>
    On whether the the perjury charge should be stopped and the subordinate court prevented from continuing the process.
</p>
<ol>
    <li>
        The petitioner has not demonstrated that his rights and fundamental freedoms will be denied, violated, infringed or threatened by the
        perjury proceedings going on at Kilgoris against him.
    </li>
    <li>
        The respondent has ably submitted that perjury proceedings does not in any way, act as an impediment to the constitutional rights and freedoms of the
        petitioner.
    </li>
</ol>
<h2>
    Orders
</h2>
<ol>
    <li>Petition dismissed with costs</li>
</ol>
<h2>
    Sentences
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Fines
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Damages
</h2>
<ul>
    <li>None</li>
</ul>
`;

export const perjuryCaseLawStep_6_Content = `
<p class="lead">
    Public reviews and comments
</p>
<h2>
    Member Comments
</h2>
<p>
    Some of the comments from members.
</p>
<ol>
    <li>Comment #1</li>
    <li>Comment #2</li>
</ol>
`;

export const perjuryCaseLawStep_7_Content = `
<p class="lead">
    Case Brief
</p>
<h2>
    To print or share this case brief with your network. Check all is well and then click on the appropriate button.
</h2>
<p>
    The case brief has not been generated yet.
</p>
`;

// trespassCaseLawSteps
export const trespassCaseLawStep_0_Content = `
<p>
    <strong>CRIMINAL PETITION NO. 77 OF 2014</strong>
</p>
<p class="lead">
    This is a case between David Omwenga Maobe who commited perjury in his testimony against John Teleyio Ole Sawoyo denying he had sold his piece of land
    known as Transmara/Osinoni/49.
</p>
<h2>
    Chronology of events
</h2>
<p>
    This issue started from the high court and successful appealed at the court of appeal ruling on David Omwenga's favor.
</p>
<ol>
    <li>
        <strong>Civil Suit No. 149 of 2005 :</strong> David Vs John in order to recover land known as Transmara/Osinoni/49, about 16.5acres
        <ul>
            <li>Applicant was a teacher when ethnic clashes erupted the area</li>
            <li>Applicant feared for his life, moved from the land that he had developed, tilled and planted maize crops</li>
            <li>Applicant sold the maize to the defendant</li>
            <li>In 2005 applicant learnt the defandant had changed the records to remove the plaintiffs name and insert his</li>
            <li>During this period, land adjudication and demarcation was in progress and hence plainiff brought the case to recover the piece of land from the defendant.</li>
            <li>22nd July 2009 : Judgement made in favor of the applicant.</li>
        </ul>
    </li>
    <li>
        <strong>Defendant Appealed</strong> the Judgement at the Court of Appeal : John V David on three main grounds
        <ul>
            <li>The validity of the transactions between the appellant(defendant) and the respondent(plaintiff)</li>
            <li>The validity of the respondent’s claim in the light of limitation of actions act and in the absence of particulars of trust.</li>
            <li>The scope of the doctrine of trust and whether the respondent proved the same to the required standard.</li>
        </ul>
    </li>
    <li><strong>22nd November 2019 :</strong> Appeal is dismissed with costs to the respondent</li>
    <li><strong>25th August 2014 :</strong> Respondent is charged with perjury contrary to section 108(1)(a) as read with section 110 of the penal code</li>
    <li>
        <strong>The Perjury Charge:</strong>
        <ul>
            <li>
                On the 18th day of may, 2009 at Kisii High court in Kisii District within Kisii County in a judicial proceedings, civil suit No. 149 of 2005
                in the High Court of Kenya in which one David Omwenga Maobe was the appellant and John Teleyio Ole Sawoyo the respondent, while on a sworn
                oath before Justice Musinga, knowingly gave false testimony touching on a matter which was material to a suit pending in that proceeding,
                namely, denial of having sold a piece of land marked  49/Osinoni adjudication section to John Teleyio Ole Sawoyo.
            </li>
        </ul>
    </li>
</ol>
`;

export const trespassCaseLawStep_1_Content = `
<p class="lead">
    The applicant David Omwenga Maobe brings a petition to the high court inter alia to quash the subordinates court's proceedings and that the said subordinate
    court be prohibited from further proceedings of perjury
</p>
<h2>
    Prosecutions Averment
</h2>
<p>
    The prosecution avers that:
</p>
<ol>
    <li>If the petitioner committed perjury, for which he is charged, the proceedings must proceed to conclusion at Kilgoris Magistrate Court.</li>
    <li>By virtue of section 4 and the 1st schedule of the criminal procedure code, any subordinate court of the first class has jurisdiction and powers in proceedings of perjury.</li>
</ol>
`;

export const trespassCaseLawStep_2_Content = `
<p class="lead">
    What is the legal question that needs to be determined.
</p>
<h2>
    Issues for determination
</h2>
<p>
    Both prosecutor and respondent are interested in a specific question of law.
</p>
<ol>
    <li>
        Can the petitioner be charged with perjury even though the two courts, High Court and the Court of Appeal agreed with him
        and gave judgement in his favor?
    </li>
    <li>
        Should this process be countermanded by the High Court under article 165(7) of the Constitution and its supervisory powers confirred thereto simply
        because two superior courts, found for the applicant, superior court under article 165, High court and appellate superior court, under article 164,
        of the Kenyan constitution?
    </li>
</ol>
<h2>
    Respondent's submission
</h2>
<p>
    On the first issue, the respondent submits in the affirmative.
</p>
<ol>
    <li>A person can and will be charged with perjury even after obtaining judgment in his favour through perjury.</li>
    <li>Judges, (and indeed magistrates) often base their verdicts, sentences or other important decisions on sworn testimony and signed documents.</li>
    <li>Statements given under oath are presumed to be truthful or at least made in good faith.</li>
    <li>The legal effect of an oath is to subject the person to penalties for perjury if testimony is false.</li>
</ol>
<h2>
    Petitioner's submission
</h2>
<p>
    On the second issue, the petitioner brings this petition under article 23(1) and 165(7) of the Kenyan Constitution
</p>
<ol>
    <li>
        <strong>Article 23(1)</strong>
        “The High Court has jurisdiction, in accordance with Article 165, to hear and determine applications for redress of a denial, violation or infringement
        of, or threat of, a right or fundamental freedom in the Bill of Rights.
    </li>
    <li>
        <strong>Article 165(7)</strong>
        “For the purposes of clause (6) the High Court may recall for the record of any proceedings before any subordinate court or person, body or authority
        referred to in clause 6, and may make or order or give any direction it considers appropriate to ensure the fair administration of justice.
    </li>
</ol>
`;

export const trespassCaseLawStep_3_Content = `
<p class="lead">
    The rules as per the issues raised
</p>
<h2>
    On whether a person can be charged for purjery even though the accused was found veracious by two courts
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1:</li>
                    <li>Treaty #2:</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        <p>
                            <strong>Case Law #1: R. Vs Archar [2002] All ER 1) 314</strong>
                        </p>
                        <ol>
                            <li>A fabloid newspaper reported that Jeffery Archar paid and had sexual intercourse with a prostitute.</li>
                            <li>Jeffery Archar sued for libel.</li>
                            <li>He won and was awarded $500,000 in damages.</li>
                            <li>Later on, it emerged that it was true he had indeed paid for sex with a prostitute and fabricated evidence to win the libel.</li>
                            <li>His alibi was a fabricated alibi that had convinced the Judge.</li>
                            <li>He was later tried in a criminal court.</li>
                            <li>He was found guilty of perjury and sentenced to four years imprisonment.</li>
                            <li>This conviction was upheld by the English Court of Appeal in R vs. Acher (2003) 1 Cr. Appeal R(5) 86.</li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <strong>Case Law #2: </strong>
                        </p>
                        <ol>
                            <li>
                                Point #1
                            </li>
                        </ol>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional references
                <ul>
                    <li>Reference #1:</li>
                    <li>Reference #2:</li>
                </ul>
            </li>
            <li>
                Relevant domestic satutes
                <ul>
                    <li>Statute #1:</li>
                    <li>Statute #2:</li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <p>
                            <strong>Case Law #1: </strong>
                        </p>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <strong>Case Law #2: </strong>
                        </p>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
<ul>
<h2>
    On whether this court should quash the criminal proceedings going on in the subordinate court and prevent the subordinate court
    from further proceedings on the matter.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1:</li>
                    <li>Treaty #2:</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        <p>
                            <strong>Case Law #1:</strong>
                        </p>
                        <ol>
                            <li>Point #1:</li>
                            <li>Point #2:</li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <strong>Case Law #2: </strong>
                        </p>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional references
                <ul>
                    <li>Reference #1:</li>
                    <li>Reference #2:</li>
                </ul>
            </li>
            <li>
                Relevant domestic satutes
                <ul>
                    <li>Statute #1:</li>
                    <li>Statute #2:</li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>
                            Zablon Mokua V Solomon M. Choti & 3 Others [2016] EKLR
                        </strong>
                        <ul>
                            <li>
                                <strong>Legal Reasoning</strong>
                                <ol>
                                    <li>The high court supervisory jurisdiction over subordinate matters has to be exercised in a structured manner</li>
                                    <li>
                                        It is not open for a High Court Judge to preside over matters before the subordinate court. In the event that a High Court calls for a
                                        subordinate court’s file or transfers the same from the subordinate court to the High Court, the most logical thing to do would be to open a file
                                        in the High Court in which the orders of the Judge would be made.
                                    </li>
                                    <li>
                                        There was an error of omission in failing to open a High Court file as soon as the lower court file was called by the High Court Judge and
                                        similarly, there was an error/mistake of commission when the Judge made the impugned orders inside the lower court file. The error is apparent
                                        on the face of the record and my view is that it is this same court that can correct the said error through the orders for review sought.
                                    </li>
                                    <li>
                                        The statutory grounds upon which orders for review can be obtained are;
                                        <ol>
                                            <li>there ought to exist an error or mistake apparent on the face of the record.</li>
                                            <li>the applicant has discovered a new and important matter in evidence which after the exercise of due diligence was not within his knowledge</li>
                                            <li>that there is sufficient reason to occasion the review where the impugned order if reviewed, would lead the court in promoting public interest and enhancing public confidence in the rule of law and the system of justice see <strong>Benjoh Amalgamated Limited & Another vs. Kenya Commercial Bank Limited (supra).</strong>.</li>
                                            <li>where the applicant was wrongly deprived of an opportunity to be heard</li>
                                            <li>where the impugned decision or order was procured illegally or by fraud or perjury see <strong>Serengeti Road Services -v- CRBD Bank Limited [2011] 2 EA 395</strong></li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <strong>Case Law #2: </strong>
                        </p>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
<ul>
`;

export const trespassCaseLawStep_4_Content = `
<p class="lead">
    Analysis and Legal Reasoning
</p>
<h2>
    On whether a person can be charged for purjery even though the accused was found veracious by two courts
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Sessional Report #1:
        <ol>
            <li>Sessional Report Point #1:</li>
            <li>Sessional Report Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>At the centre of this perjury is the proprietary interest in the mentioned parcel of land.</li>
    <li>Perjury subverts and perverts public justice system and is abhorred.</li>
    <li>The perpetrator of perjury must be stopped if the public justice system were to retain credibility.</li>
    <li>Perjury subverts and perverts public justice system and is abhorred.</li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
<h2>
    On whether this court should quash the criminal proceedings going on in the subordinate court and prevent the subordinate court
    from further proceedings on the matter.
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Sessional Paper #1:
        <ol>
            <li>Sessional Paper Point #1:</li>
            <li>Sessional Paper Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>The perpetrator of perjury must be stopped if the public justice system were to retain credibility.</li>
    <li>
        If the perjury proceedings were to proceed to conclusion and the accused- the petitioner- were convicted but quashed by the order of this court,
        who is the aggrieved person vis-à-vis the Land Parcel No. Transmara/Osinoni/49.
    </li>
    <li>
        Question on What triggered the perjury charges
        <ol>
            <li>The sale agreement and letter written by the petitioner was dismissed by both the trial and the high court based on the petitioners sworn testimony</li>
            <li>These two documents were forwarded to National Registration Bureau in Nairobi and Document Examiners at CID headquarters for analysis which revealed that the signatures were made by the same person</li>
            <li>Petitioner denied the thumb print that appeared in the agreement and the analysis revealed they were his</li>
            <li>The upshot is, the petitioner lied under oath in order to aid him getting judgement in the suit at the high court in 2009</li>
            <li>This conviction was upheld by the English Court of Appeal in R vs. Acher (2003) 1 Cr. Appeal R(5) 86.</li>
        </ol>
    </li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
`;

export const trespassCaseLawStep_5_Content = `
<p class="lead">
    What were the orders, penalties, charges, sentencing, costs e.t.c.
</p>
<h2>
    The holding
</h2>
<p>
    On whether the the perjury charge should be stopped and the subordinate court prevented from continuing the process.
</p>
<ol>
    <li>
        The petitioner has not demonstrated that his rights and fundamental freedoms will be denied, violated, infringed or threatened by the
        perjury proceedings going on at Kilgoris against him.
    </li>
    <li>
        The respondent has ably submitted that perjury proceedings does not in any way, act as an impediment to the constitutional rights and freedoms of the
        petitioner.
    </li>
</ol>
<h2>
    Orders
</h2>
<ol>
    <li>Petition dismissed with costs</li>
</ol>
<h2>
    Sentences
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Fines
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Damages
</h2>
<ul>
    <li>None</li>
</ul>
`;

export const trespassCaseLawStep_6_Content = `
<p class="lead">
    Public reviews and comments
</p>
<h2>
    Member Comments
</h2>
<p>
    Some of the comments from members.
</p>
<ol>
    <li>Comment #1</li>
    <li>Comment #2</li>
</ol>
`;

export const trespassCaseLawStep_7_Content = `
<p class="lead">
    Case Brief
</p>
<h2>
    To print or share this case brief with your network. Check all is well and then click on the appropriate button.
</h2>
<p>
    The case brief has not been generated yet.
</p>
`;
// trespassToLandCourtOfAppealAppealCaseLawSteps
export const trespassToLandCourtOfAppealAppealCaseLawStep_0_Content = `
<p>
    <strong>CIVIL APPEAL E247 & E248 OF 2020 (CONSOLIDATED)</strong>
</p>
<p class="lead">
    This is a consolidated appeal case (Court of Appeal) between KPLC Vs Eunice Nkirote Ringera (Civil Appeal No. E247 of 2020) and another case between
    George Joseph Kangethe and Ellah Karwitha Kangethe ("George and Ellah") Vs KPLC. Both cases having been heard against the appelant
    in the Environment and Land Court at Kajiado
</p>
<h2>
    Chronology of events
</h2>
<p>
    This issue started from the Environment and Land Court and parially successfully appealed at the court of appeal ruling. Environment & Land Case 869 of 2017
</p>
<ol>
    <li>
        <strong>Petitions at the trial court</strong>
        <ul>
            <li>
                <strong>ELC No. 869 of 2017:</strong> Eunice Nkirote Ringera v Kenya Power & Lighting Company [2020] eKLR
                <ul>
                    <li>
                        <strong>Plaintiff's Case</strong>
                        <ul>
                            <li>
                                <strong>Filing Date</strong>
                                <ul>
                                    <li>26th September, 2017</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Reliefs</strong>
                                <ul>
                                    <li>
                                        A declaration that the defendant has trespassed on the suit property without the plaintiff's consent and caused permanent and irreparable damage
                                        thereto
                                    </li>
                                    <li>
                                        An injunction compelling the defendant, its servants, agents or employees, to cease the trespass and remove all of the concrete poles with heavy
                                        duty electric cables from the suit property
                                    </li>
                                    <li>General damages</li>
                                    <li>Compensatory damages</li>
                                    <li>Cost of the suit</li>
                                    <li>Interest general damages, compensatory damages and cost of the suit</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Defendant's Case</strong>
                        <ul>
                            <li>
                                <strong>Filing Date</strong>
                                <ul>
                                    <li>16th October 2017</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Reliefs</strong>
                                <ul>
                                    <li>Prayed for this suit to be dismissed with costs.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>

            <li>
                <strong>ELC No. 797 of 2017:</strong> George Joseph Kangethe and Another v Kenya Power & Lighting Company [2020] eKLR
                <ul>
                    <li>
                        <strong>Plaintiff's Case</strong>
                        <ul>
                            <li>
                                <strong>Filing Date</strong>
                                <ul>
                                    <li>4th July, 2017</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Reliefs</strong>
                                <ul>
                                    <li>
                                        A declaration that the defendant has trespassed on the suit property without the plaintiff's consent and caused permanent and irreparable damage
                                        thereto
                                    </li>
                                    <li>
                                        An injunction compelling the defendant, its servants, agents or employees, to cease the trespass and remove all of the concrete poles with heavy
                                        duty electric cables from the suit property
                                    </li>
                                    <li>General damages</li>
                                    <li>Compensatory damages</li>
                                    <li>Cost of the suit</li>
                                    <li>Interest general damages, compensatory damages and cost of the suit</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Defendant's Case</strong>
                        <ul>
                            <li>
                                <strong>Filing Date</strong>
                                <ul>
                                    <li>26th July 2017</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Reliefs</strong>
                                <ul>
                                    <li>prayed for this suit to be dismissed with costs.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>1st Appeal at the Court of Appeal</strong>
        <ul>
            <li>
                <strong>Background :</strong> Suit properties
                <ul>
                    <li>
                        Respondents averred that they were seperately registered as proprietors of land parcels title numbers Ngong/Ngong/15676 comprising
                        0.81hectares in favour of Eunice, 15678 comprising 1.21 hectares in favour of George and lastly, 15677 comprising 0.40hectares in favour of
                        Ellah (hereinafter referred to as the 1st, 2nd and 3rd suit properties).
                    </li>
                </ul>
            </li>
            <li>
                <strong>Civil Appeal E247 & E248 of 2020 (Consolidated)</strong> Kenya Power & Lighting Company Ltd v Ringera & 2 others
                (Civil Appeal E247 & E248 of 2020 (Consolidated)) [2022] KECA 104 (KLR) (4 February 2022) (Judgment)
                <ul>
                    <li>
                        <strong>Appeallants Submissions </strong>
                        <ul>
                            <li>
                                Cumulatively the appellant denied each and every wrong doing attributed to it by Eunice, George and Ellah in their respective plaints
                                and put them to strict proof.
                            </li>
                            <li>The appellant also prayed for the suits to be dimissed with cost.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Respondents Submissions </strong> : The respondents cumulative submissions against the appellant
                        <ul>
                            <li>
                                On or about june 2013, the appellant, without their consent, permission or authority intentionally, knowingly and/or voluntarily and without
                                lawful justification trespassed on their respective suit properties and carried out the impugned activities causing permanent and irreparable
                                damage as more particularly set out in their respective plaints, in respect of which reliefs are sort.

                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
</ol>
`;

export const trespassToLandCourtOfAppealAppealCaseLawStep_1_Content = `
<p class="lead">
    This case is about an appeal at the Court of Appeal involving two cases of trespass having been heard and determined seperately at the Environment & Lands Court.
</p>
<ol>
    <li>
        <strong>Facts at the trial court</strong>
        <ul>
            <li>
                <strong>ELC No. 869 of 2017:</strong> Eunice Nkirote Ringera v Kenya Power & Lighting Company [2020] eKLR
                <ul>
                    <li>
                        <strong>Plaintiff's Case</strong>
                        <ul>
                            <li>
                                <strong>Plaintiff Evidence</strong>
                                <ul>
                                    <li>Plainiff testified that she is the owner of Ngong/Ngong/15676 hereinafter referred to as the ‘suit land’</li>
                                    <li>
                                        Plaintiff explained that around the year 2013, the Defendant entered the suit land without her permission, cut down trees, dug deep holes,
                                        erected high concrete poles including putting high voltage wires thereon.
                                    </li>
                                    <li>
                                        Plaintiff claimed to have incurred irreparable damages as she cannot use the land because of the high voltage wires thereon.
                                    </li>
                                    <li>
                                        Plaintiff testified that she could not sell the land or build on it but could only plant maize thereon.
                                    </li>
                                    <li>
                                        Plaintiff produced her Certificate of Title and Official Search; Photographs; Demand Letter and Certified Copy of the Valuation Report
                                        as her exhibits.
                                    </li>
                                    <li>
                                        <ul>
                                            <strong>Cross Examination</strong>
                                            <li>Testified that the Defendant trespassed on her land while she was away.</li>
                                            <li>She explained that around 7 up to 10 poles had been erected on her land.</li>
                                            <li>She testified that she had produced evidence in court on the type of trees that were cut including their value.</li>
                                            <li>She confirmed not residing on the suit land nor having built any structures thereon.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Defense's Case</strong>
                        <ul>
                            <li>
                                <strong>Defense Evidence</strong>
                                <ul>
                                    <li>
                                        Denied interfering with the Plaintiff’s possession and enjoyment of the suit land - contended that it did not enter the suit land, cut down trees, dig up
                                        holes or erect concrete poles with heavy duty electric cables without the Plaintiff’s consent thereby occasioning permanent and irreparable damage on it.
                                    </li>
                                    <li>The Defendant also denied being served with demand or notice of intention to sue</li>
                                    <li>
                                        <strong>Cross Examination</strong>
                                        <ul>
                                            <li></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>

            <li>
                <strong>ELC No. 797 of 2017:</strong> George Joseph Kangethe and Another v Kenya Power & Lighting Company [2020] eKLR
                <ul>
                    <li>
                        <strong>Plaintiff's Case</strong>
                        <ul>
                            <li>
                                <strong>Plaintiff's Evidence</strong>
                                <ul>
                                    <li>
                                        Alleged defendant entered the suit land without her permission, cut down trees, dug deep holes, erected high concrete poles
                                        including putting high voltage wires thereon
                                    </li>
                                    <li>claimed to have incurred irreparable damages as she cannot use the land because of the high voltage wires thereon.</li>
                                    <li>It was her testimony that she could not sell the land or build on it but could only plant maize thereon.</li>
                                    <li>
                                        produced her Certificate of Title and Official Search; Photographs; Demand Letter and Certified Copy of the Valuation Report as
                                        her exhibits
                                    </li>
                                    <li>
                                        <strong>Cross Examination</strong>
                                        <ul>
                                            <li>Claimed the defendant trespassed on her land while she was away.</li>
                                            <li>
                                                explained that around 7 up to 10 poles had been erected on her land. She testified that she had produced evidence in court on
                                                the type of trees that were cut including their value.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Defendant's Case</strong>
                        <ul>
                            <li>
                                <strong>Defense Evidence</strong>
                                <ul>
                                    <li>Defence never tendered any evidence;</li>
                                    <li>denied interfering with the Plaintiff’s possession and enjoyment of the suit land.</li>
                                    <li>
                                        denied entering the suit land, cut down trees, dig up holes or erect concrete poles with heavy duty electric cables without the
                                        Plaintiff’s consent thereby occasioning permanent and irreparable damage on it.
                                    </li>
                                    <li>denied being served with demand or notice of intention to sue</li>
                                    <li>
                                        <strong>Cross Examination</strong>
                                        <ul>
                                            <li></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>

    <li>
        <strong>1st Appeal at the Court of Appeal</strong>
        <ul>
            <li>
                <strong>Civil Appeal E247 & E248 of 2020 (Consolidated)</strong> Kenya Power & Lighting Company Ltd v Ringera & 2 others
                (Civil Appeal E247 & E248 of 2020 (Consolidated)) [2022] KECA 104 (KLR) (4 February 2022) (Judgment)
                <ul>
                    <li>
                        <strong>Appeallants Submissions </strong>
                        <ul>
                            <li>
                                Cumulatively the appellant denied each and every wrong doing attributed to it by Eunice, George and Ellah in their respective plaints
                                and put them to strict proof.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Respondents Submissions </strong> : The respondents cumulative submissions against the appellant
                        <ul>
                            <li>
                                On or about june 2013, the appellant, without their consent, permission or authority intentionally, knowingly and/or voluntarily and without
                                lawful justification trespassed on their respective suit properties and carried out the impugned activities causing permanent and irreparable
                                damage as more particularly set out in their respective plaints, in respect of which reliefs are sort.
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
</ol>
`;

export const trespassToLandCourtOfAppealAppealCaseLawStep_2_Content = `
<p class="lead">
    What are the legal questions raised by the paries
</p>
<h2>
    Findings and Issues
</h2>
<p>
    The issues identified by the plaintiff and the respondent are:
</p>
<ol>
    <li>
        <strong>Issues</strong>
        <ol>
            <li>
                <strong>Trial Court (High Court)</strong>
                <ol>
                    <li>
                        <strong>Plaintiff Submissions</strong>
                        <ol>
                            <li>
                                <strong>Issue #1</strong> : Whether the Defendant had trespassed on her land
                                <ul>
                                    <li>Submitted that she was the owner of the said land as evident in the Certificate of title</li>
                                    <li>
                                        She contended that the defendant never issued her with any notice requesting her permission to enter upon the suit land but instead proceeded to
                                        intentionally, willfully, knowingly and voluntarily enter thereon and cut down trees, dig up holes, erect concrete poles with heavy duty
                                        electric cables without her knowledge, consent, or any legal justification thus occasioning her permanent including irreparable damage.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Issue #2</strong> : If she was entitled to the orders sought in the Plaint
                                <ul>
                                    <li>She submitted that she had discharged the burden of proof on a balance of probability.</li>
                                    <li>She contended that once trespass to land is established, it is actionable per se and proof of general damages is unnecessary.</li>
                                    <li>Alleged continuing trespass and unlawful acquisition including alienation of her land</li>
                                    <li>Sought mesne profits at a rate of Kshs 100,000 per month from 2013 until defendant vacates the suit land</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Statutes</strong>
                                <ul>
                                    <li>Section 46 of the Energy Act, 2006</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Case Laws</strong>
                                <ul>
                                    <li>John Kiragu Kimani vs Rural Electrification Authority (2018) eKLR.</li>
                                    <li>Duncan Nderitu Ndegwa vs. Kenya Pipeline Company Limited & Another (2013) eKLR.</li>
                                    <li>Samwel Motari Nyambati vs KPLC (2018) eKLR.</li>
                                    <li>Kenya Tourist Development Corporation vs. Sundowner Lodge Limited (2018) eKLR.</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Respondent Submissions</strong>
                        <ol>
                            <li>
                                <strong>Issue #1</strong> : Whether the Plaintiff sufficiently proved that it was the Defendant that installed the electricity line
                                <ul>
                                    <li>submitted that the Plaintiff was absent at the time when the electricity poles were erected</li>
                                    <li>Submitted that the Plaintiff failed to adduce documentation and illustrate that she engaged the Defendant on the matter at the time the poles were erected.</li>
                                    <li>Submitted that it is not the only entity tasked with the laying of electricity lines.</li>
                                    <li>Submitted that the Plaintiff had not proved her case to a reasonable degree that it trespassed and placed the poles on the suit land</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Issue #2</strong> : If the Plaintiff is entitled to damages
                                <ul>
                                    <li>Submitted that compensation for the felling of trees is a specific damage and ought to have been proved during the hearing.</li>
                                    <li>Submitted that no documentation such as a Certificate of Costs from the Kenya Forest Service was produced to illustrate the number of trees felled and their value.</li>
                                    <li>Urged the Court to award General Damages to the sum of Kshs. 100,000</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Statutes</strong>
                                <ul>
                                    <li></li>
                                </ul>
                            </li>
                            <li>
                                <strong>Case Laws</strong>
                                <ul>
                                    <li>Obadiah k. Macharia vs. Kenya Power and Lighting Company Ltd (2016) eKLR.</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>
                <strong>1st Appeal Court (Court of Appeal)</strong>
                <ol>
                    <li>Issue #1</li>
                    <li>Issue #2</li>
                    <li>Issue #3</li>
                </ol>
            </li>
            <li>
                <strong>2nd Appeal Court (Supreme Court)</strong>
                <ol>
                    <li>Issue #1</li>
                    <li>Issue #2</li>
                    <li>Issue #3</li>
                </ol>
            </li>
        </ol>
    </li>

</ol>
`;

export const trespassToLandCourtOfAppealAppealCaseLawStep_3_Content = `
<p class="lead">
    The rules as per the issues raised
</p>
<h2>
    On whether the Defendant trespassed onto the Plaintiff’s land parcel no. Ngong/Ngong/15676.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>Reference #1</li>
                    <li>Reference #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>
                        <strong>Section 46 of the Energy Act, 2006</strong> - “No person shall enter upon any land, other than his own- (a) to lay or connect an electric supply
                        line; or (b) to carry out a survey of the land for the purpose of paragraph (a) except with the prior permission of the owner of such
                        land.”(2) Permission sought in subsection 1 above shall be done by way of notice which shall be accompanied by statement of particulars
                        of entry.
                    </li>
                    <li>
                        <strong>Section 171 of the Energy Act Cap 1 of 2019</strong> - “171 (1) A person who wishes to enter upon any land, other than his own to—(a)undertake
                        exploratory activities relating to exploitation of energy resources and development of energy infrastructure, including but not limited
                        to laying or connecting electric supply lines, petroleum or gas pipelines, or drilling exploratory wells;(b)carry out a survey of the
                        land for the purposes of paragraph (a);shall seek the prior consent of the owner of such land, which consent shall not be unreasonably
                        withheld: Provided that where the owner cannot be traced, the applicant shall give fifteen days' notice, through appropriate mechanisms
                        including public advertisement in at least two newspapers of nationwide circulation and an announcement in a radio station of local
                        coverage for a period of two weeks.”
                    </li>
                    <li>
                        <strong>Section 173 of the Energy Act Cap 1 of 2019</strong> - (1)An owner, after receipt of a request for consent under section 171 may consent in
                        writing to the development of energy infrastructure, upon agreement being reached with the applicant as to the amount of compensation
                        payable, if any, and any consent so given shall be binding on all parties having an interest in the land, subject to the following
                        provisions—(a)that any compensation to be paid by the licensee giving notice to the owner, in cases where the owner is under incapacity
                        or has no power to consent to the application except under this Act, shall be paid to the legal representative of the owner; and(b)that
                        an occupier or person other than the owner interested in the land shall be entitled to compensation for any loss or damage he may
                        sustain by the development of energy infrastructure, including but not limited to laying or connecting electric supply lines,
                        petroleum or gas pipelines, drilling geothermal wells or coal long as the claim is made within three months after the development.”
                    </li>
                    <li>
                        <strong>Section 3 of the Trespass Act</strong> - “(1)Any person who without reasonable excuse enters, is or remains upon, or erects any structure on,
                        or cultivates or tills, or grazes stock or permits stock to be on, private land without the consent of the occupier thereof shall be
                        guilty of an offence.(2)Where any person is charged with an offence under subsection (1) of this section the burden of proving that he
                        had reasonable excuse or the consent of the occupier shall lie upon him.”
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>John Kiragu Kimani vs Rural Electrification Authority (2018) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “Following that evidence, it is clear from the record that no consent, authority or permission of the Plaintiff was
                                        ever sought and/or obtained. No notice was given to him of the impending project as contemplated by section 46 of the
                                        Energy Act. The irresistible conclusion is that the Defendant is guilty of trespass.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Eliud Njoroge Gachiri vs. Stephen Kamau Nganga elc no. 121 of 2017</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “However in a case of continuing trespass, a trespass consists of a series acts done on consecutive days that are of
                                        the same nature and that are renewed or continued from day to day so that the acts are aggregate form one indivisible
                                        harm.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
</ul>

<h2>
    On whether the Plaintiff is entitled to the orders sought in the Plaint.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>Reference #1</li>
                    <li>Reference #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>Statute #1</li>
                    <li>Statute #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>Duncan Nderitu Ndegwa vs. Kenya Pipeline Company Limited & Another (2013) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Samwel Motari Nyambati vs KPLC (2018) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Tourist Development Corporation vs. Sundowner Lodge Limited (2018) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Obadiah k. Macharia vs. Kenya Power and Lighting Company Ltd (2016) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>SJ vs. Francesco Di Nello & Another [2015] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “Claims under the heads of loss of future earnings and loss of
                                        earning capacity are distinctively different. Loss of income which may be defined as real actual loss is loss of future earnings. Loss of
                                        earning capacity may be defined as diminution in earning capacity. Loss of income or future earnings is compensated for real assessable loss
                                        which is proved by evidence. On the other hand, loss of earning capacity is compensated by an award in general damages, once proved.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Capital Fish Kenya Limited Vs The Kenya Power & Lighting Company Limited [2016] eKLR</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        ’No evidence whatsoever was
                                        led by the appellant on this aspect. This, as we already stated elsewhere, was an abstract figure which was thrown to the court with a mere
                                        statement that “this is the loss the appellant has suffered. Please award it to the appellant.”
                                        In the case of Ryce Motors Ltd & Another vs Muchoki (1995-98) 2 E. A 363 (CAK) commenting on statements of accounts presented without more as
                                        in this case stated, this Court observed;

                                        “… The pieces of paper produced as evidence of income could not be accepted as correct accounting practice. They did not constitute proof of
                                        special damages.”

                                        For all the foregoing reasons, we are satisfied that although the trial court correctly found that the special damages had been specifically
                                        pleaded, there was no credible evidence whatsoever that proved the pleaded special damages. The trial court’s finding on that score can thus
                                        not be faulted.’
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Duncan Nderitu Ndegwa v. KP & LC Limited & Another (2013) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “…once a trespass to land is established it is actionable
                                        per se, and indeed no proof of damage is necessary for the court to award general damages. This court accordingly awards an amount of Kshs
                                        100,000/= as compensation of the infringement of the Plaintiff’s right to use and enjoy the suit property occasioned by the 1st and
                                        2nd Defendants’   trespass”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Kenya Power & Lighting Company Limited v Fleetwood Enterprises Limited [2017] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “Trespass is proved as in this case, the affected party such as the respondent need not prove that it suffered any damages or loss as a
                                        result so as to be awarded damages. The court is under the circumstances bound to award damages, of course, depending on the facts of each
                                        case.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Fleetwood Enterprises Ltd v Kenya Power & Lighting Co. Ltd [2015] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “The award of damages for trespass is discretionary in nature.
                                        The discretion by the court should however be exercised judiciously and all relevant factors should be considered. The value of land is a
                                        determinant factor where parties consent that the power line should not be moved. In this case the Plaintiff wanted the power lines removed.
                                        He proceeded to further state that, “ it is trite law that the value of land keeps on appreciating, and had the plaintiff developed the 16
                                        parcels of land 5 years ago, they would have generated income from them. In the circumstances of this case, I would agree with the Plaintiff’s
                                        advocate submissions that had the 16 plots been developed were it not for the connected electric line. The plaintiff would have earned at
                                        least Ksh. 5000 per month. However, due to the vagaries that come with such developments, I will reduce the said figure to an amount of
                                        Kshs. 4,000 per plot per month. The total payable damages to the defendant’s acts of trespass over the 16 plots for a period of 5 years will
                                        therefore be Kshs 4,000*12*5*16= Kshs. 3,840,000.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
</ul>

<h2>
    On whether the learned Judge (trial judge) erroneously and excessively awarded general and compensatory damages for continuing trespass to the respondents
    by failing to take into account relevant factors to the detriment of the appellant.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>Reference #1</li>
                    <li>Reference #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>
                        Statute #1
                    </li>
                    <li>
                        Statute #2
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>Kiambaa Dairy Farmers Co-operative Society Limited vs. Rhoda Njeri & 3 Others [2018] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “the extent of an award of
                                        compensatory damages lies in the discretion of the trial court and interference therewith on appeal must be approached with a measure of
                                        circumspection and well settled principles;”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Peter M. Kariuki vs. Attorney General [2014] eKLR</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “the principles that guide an appellate court in this country on an appeal
                                        for an award of damages are now well settled; Kemfro Africa Limited vs. Lubia & Another (No. 2) [1987] KLR 30, on the principles to be observed
                                        by an appellate court in deciding whether it is justified in disturbing the quantum of damages awarded by a trial court namely, that the
                                        appellate court must be satisfied that either the Judge in assessing the damages took into account an irrelevant factor, or left out of account
                                        a relevant one, or that short of this, the award is so inordinately low or so inordinately high that it must be a wholly erroneous estimate of
                                        the damages payable;”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Johnson Evans Gicheru vs. Andrew Morton & Another [2005] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “this Court (appellate court) will be disinclined to disturb
                                        the finding of a trial Judge as to the amount of damages awarded by the trial court merely because if they had tried the case in the first
                                        instance they would have awarded a higher sum.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Ephantus Mwangi vs. Duncan Mwangi Wambugu [1984] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “a Court of Appeal will not normally interfere with a finding of fact
                                        by the trial court, unless it is based on no evidence or on a misapprehension of the evidence or the Judge is shown demonstrably to have acted
                                        on wrong principles in reaching the findings he did;”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Farah Awad vs. CMC Motors Group Limited [2018] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “on the mandate of the first appellate court,namely, to reappraise,
                                        reassess and reanalyze the evidence on the record and arrive at own conclusions on the matter and give reason either way”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Sumaria & Another vs. Allied Industries Limited [2007] 2 KLR 1.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “an appellate court should be slow in moving to interfere with
                                        a finding of fact by a trial court unless it was based on no evidence or based on a misapprehension of the evidence or that the Judge had been
                                        shown demonstrably to have acted on a wrong principle in reaching the finding he/she did.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>United India Insurance Company Limited vs. East African Underwriters Kenya Ltd [1985] KLR 898.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “this Court wasexplicit that
                                        interference with exercise of judicial discretion only arises where there is clear demonstration of misdirection in law, misapprehension of the
                                        facts, taking into consideration factors the trial court ought not to have taken into consideration or failure to take into consideration
                                        factors that ought to have been taken into consideration or looking at the decision generally, the only plausible conclusion reached is that
                                        the decision albeit a discretionary one, is plainly wrong.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                </ul>
            </li>
        </ol>
    </li>
</ul>
`;

export const trespassToLandCourtOfAppealAppealCaseLawStep_4_Content = `
<p class="lead">
    Analysis and Legal Reasoning
</p>
<h2>
    On whether the Defendant trespassed onto the Plaintiff’s land parcel no. Ngong/Ngong/15676.
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Black’s Law Dictionary 8th edition defines Continous Trespass as “A trespass in the nature of a permanent invasion on another’s property.”</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Harlsburys Laws of England:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Definitions:
        <ol>
            <li>
                <strong>Eliud Njoroge Gachiri vs. Stephen Kamau Nganga elc no. 121 of 2017</strong> - “However in a case of continuing trespass, a trespass consists of a series
                acts done on consecutive days that are of the same nature and that are renewed or continued from day to day so that the acts are aggregate form
                one indivisible harm.”
            </li>
            <li>
                <strong>Municipal Council of Eldoret vs. Titus Gatitu Njau [2020] eKLR</strong> - “
                Trespass is a violation of the right to possession and a plaintiff must prove that he has the right to immediate and exclusive possession of
                the land which is different from ownership.”
            </li>
        </ol>
    </li>
    <li>
        Case Law Holdings:
        <ol>
            <li>
                <strong>John Kiragu Kimani vs. Rural Electrification Authority (2018) eKLR</strong> - “Following that evidence, it is clear from the record that no consent,
                authority or permission of the Plaintiff was ever sought and/or obtained. No notice was given to him of the impending project as contemplated
                by section 46 of the Energy Act. The irresistible conclusion is that the Defendant is guilty of trespass.”
            </li>
            <li>
                <strong>Philip Ayaya Aluchio vs. Crispinus Ngayo [2014] eKLR</strong> - “
                the measure of damages for trespass is the difference in the value of the plaintiff's property immediately before and immediately after the
                trespass or the cost of restoration, whichever is less;”
            </li>
            <li>
                <strong>Ephantus Mwangi & Another vs. Duncan Mwangi [1982 – 1988] I KAR 278</strong> - “an appellate court is not bound to accept and act on
                the trial court’s findings of fact if it appears clearly that the trial court failed to take account of particular circumstances or
                probabilities material to the issue in controversy in the case.”
            </li>
        </ol>
    </li>
    <li>
        Doctrines:
        <ol>
            <li>
                <strong>res ipsa loquitur:</strong> the principle that the mere occurrence of some types of accident is sufficient to imply negligence. Negligence particularly
                usually requires specific criteria to be met, but this doctrine allows for negligence to be proven indirectly.
            </li>
            <li>Doctrine Point #2:</li>
        </ol>
    </li>
    <li>
        Sessional Report #1:
        <ol>
            <li>Sessional Report Point #1:</li>
            <li>Sessional Report Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>It is trite that construction of a wayleave on private land requires consent of its owner.</li>
    <li>
        Defendant never adduced any evidence to confirm that the Plaintiff’s consent was obtained before the high voltage power lines were erected thereon and
        the alternative government institution that did so.
    </li>
    <li>
        Findings
        <ul>
            <li>
                Defendant overlooked procedural regulations in the construction of high voltage power lines on the suit land as set out in sections 171 and 173
                of the Energy Act Cap 1 of 2019
            </li>
            <li>the Defendant’s aforementioned actions amount to an illegal entry into private property and can be deemed as trespass.</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
<h2>
    On whether the Plaintiff is entitled to the orders sought in the Plaint.
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Harlsburys Laws of England:
        <ol>
            <li>
                4th Edition Vol. 45 at para 26 pg 1503: the owner of the land is entitled to nominal damages where there is no actual damage occasioned to the
                owner by the trespass, such amounts as will compensate the owner for loss of use resulting from the damage caused by the trespass, reasonable
                damages are payable where the trespasser has made use of the owner’s land, exemplary damages are payable where the trespassers conduct towards
                the owner is not only oppressive but also cynical and carried out in deliberate disregard of the right of the owner of the land with the object
                of making a gain by his/her unlawful conduct, general damages may be increased where the trespass is accompanied by aggravating circumstances to
                the detriment of the owner of the land.
            </li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Definitions:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Holdings:
        <ol>
            <li>
                <strong>Fleetwood Enterprises Ltd v Kenya Power & Lighting Co. Ltd [2015] eKLR</strong> - “The award of damages for trespass is discretionary in nature.
                The discretion by the court should however be exercised judiciously and all relevant factors should be considered. The value of land is a
                determinant factor where parties consent that the power line should not be moved. In this case the Plaintiff wanted the power lines removed.
                He proceeded to further state that, “ it is trite law that the value of land keeps on appreciating, and had the plaintiff developed the 16
                parcels of land 5 years ago, they would have generated income from them. In the circumstances of this case, I would agree with the Plaintiff’s
                advocate submissions that had the 16 plots been developed were it not for the connected electric line. The plaintiff would have earned at
                least Ksh. 5000 per month. However, due to the vagaries that come with such developments, I will reduce the said figure to an amount of
                Kshs. 4,000 per plot per month. The total payable damages to the defendant’s acts of trespass over the 16 plots for a period of 5 years will
                therefore be Kshs 4,000*12*5*16= Kshs. 3,840,000.”
            </li>
            <li>
                <strong>Kenya Power & Lighting Company Limited v Fleetwood Enterprises Limited [2017] eKLR</strong> - The court of appeal upheld the above decision and stated
                that “Trespass is proved as in this case, the affected party such as the respondent need not prove that it suffered any damages or loss as a
                result so as to be awarded damages. The court is under the circumstances bound to award damages, of course, depending on the facts of each
                case.”
            </li>
            <li>
                <strong>Duncan Nderitu Ndegwa v. KP & LC Limited & Another (2013) eKLR</strong> i was held that - “…once a trespass to land is established it is actionable
                per se, and indeed no proof of damage is necessary for the court to award general damages. This court accordingly awards an amount of Kshs
                100,000/= as compensation of the infringement of the Plaintiff’s right to use and enjoy the suit property occasioned by the 1st and
                2nd Defendants’   trespass” and that damages payable for trespass are the amount of diminution in value or the loss of reinstatement of the land
                with the overriding principle being to put the claimant in the position he was in prior to the infliction of harm.
            </li>
            <li>
                <strong>Philip Ayaya Aluchio vs. Crispinus Ngayo [2014] eKLR</strong> - “the measure of damages for trespass is the difference in the value of
                the plaintiffs’ property immediately before and immediately after the trespass or the cost of restoration whichever is less.”
            </li>
            <li>
                <strong>Capital Fish Kenya Limited Vs The Kenya Power & Lighting Company Limited [2016] eKLR</strong> the court of appeal held that - ’No evidence whatsoever was
                led by the appellant on this aspect. This, as we already stated elsewhere, was an abstract figure which was thrown to the court with a mere
                statement that “this is the loss the appellant has suffered. Please award it to the appellant.”
                In the case of Ryce Motors Ltd & Another vs Muchoki (1995-98) 2 E. A 363 (CAK) commenting on statements of accounts presented without more as
                in this case stated, this Court observed;

                “… The pieces of paper produced as evidence of income could not be accepted as correct accounting practice. They did not constitute proof of
                special damages.”

                For all the foregoing reasons, we are satisfied that although the trial court correctly found that the special damages had been specifically
                pleaded, there was no credible evidence whatsoever that proved the pleaded special damages. The trial court’s finding on that score can thus
                not be faulted.’
            </li>
            <li>
                <strong>SJ vs. Francesco Di Nello & Another [2015] eKLR</strong> the court of appeal held that - “Claims under the heads of loss of future earnings and loss of
                earning capacity are distinctively different. Loss of income which may be defined as real actual loss is loss of future earnings. Loss of
                earning capacity may be defined as diminution in earning capacity. Loss of income or future earnings is compensated for real assessable loss
                which is proved by evidence. On the other hand, loss of earning capacity is compensated by an award in general damages, once proved.”
            </li>
            <li>
                <strong>Kiambaa Dairy Farmers Co-operative Society Limited vs. Rhoda Njeri & 3 Others [2018] eKLR</strong> - “the extent of an award of
                compensatory damages lies in the discretion of the trial court and interference therewith on appeal must be approached with a measure of
                circumspection and well settled principles;”
            </li>
            <li>
                <strong>Peter M. Kariuki vs. Attorney General [2014] eKLR</strong> - “the principles that guide an appellate court in this country on an appeal
                for an award of damages are now well settled; Kemfro Africa Limited vs. Lubia & Another (No. 2) [1987] KLR 30, on the principles to be observed
                by an appellate court in deciding whether it is justified in disturbing the quantum of damages awarded by a trial court namely, that the
                appellate court must be satisfied that either the Judge in assessing the damages took into account an irrelevant factor, or left out of account
                a relevant one, or that short of this, the award is so inordinately low or so inordinately high that it must be a wholly erroneous estimate of
                the damages payable;”
            </li>
            <li>
                <strong>Johnson Evans Gicheru vs. Andrew Morton & Another [2005] eKLR</strong> - “this Court (appellate court) will be disinclined to disturb
                the finding of a trial Judge as to the amount of damages awarded by the trial court merely because if they had tried the case in the first
                instance they would have awarded a higher sum.” Justification for reversing a trial Judge on an award of damages only applies where the court
                is convinced either that the Judge acted upon some wrong principle of law or that the amount awarded was so extremely high or so very low as to
                make it an entirely erroneous estimate of the damage to which the aggrieved party is entitled.
            </li>
            <li>
                <strong>Ephantus Mwangi vs. Duncan Mwangi Wambugu [1984] eKLR</strong> - “a Court of Appeal will not normally interfere with a finding of fact
                by the trial court, unless it is based on no evidence or on a misapprehension of the evidence or the Judge is shown demonstrably to have acted
                on wrong principles in reaching the findings he did;”. An appellate court is not bound to accept and act on the trial court’s findings of fact
                if it appears clearly that the trial court failed to take account of particular circumstances or probabilities material to an estimate of
                evidence.
            </li>
            <li>
                <strong>Farah Awad vs. CMC Motors Group Limited [2018] eKLR</strong> - “on the mandate of the first appellate court,namely, to reappraise,
                reassess and reanalyze the evidence on the record and arrive at own conclusions on the matter and give reason either way”
            </li>
            <li>
                <strong>Sumaria & Another vs. Allied Industries Limited [2007] 2 KLR 1</strong> - “an appellate court should be slow in moving to interfere with
                a finding of fact by a trial court unless it was based on no evidence or based on a misapprehension of the evidence or that the Judge had been
                shown demonstrably to have acted on a wrong principle in reaching the finding he/she did.”
            </li>
            <li>
                <strong>United India Insurance Company Limited vs. East African Underwriters Kenya Ltd [1985] KLR 898</strong> - “this Court wasexplicit that
                interference with exercise of judicial discretion only arises where there is clear demonstration of misdirection in law, misapprehension of the
                facts, taking into consideration factors the trial court ought not to have taken into consideration or failure to take into consideration
                factors that ought to have been taken into consideration or looking at the decision generally, the only plausible conclusion reached is that
                the decision albeit a discretionary one, is plainly wrong.”
            </li>
            <li>
                <strong>Kemfro Africa Limited vs. Lubia & Another [No. 2] [1987] KLR 30</strong> - “before interference with the quantum of damages awarded by
                a trial court the appellate court must be satisfied that either the judge in assessing the damages took into account an irrelevant factor, or
                left out of account a relevant one or short of the above, the award is so inordinately low or so inordinately high that it must be a wholly
                erroneous estimate of the damages payable.”
            </li>
            <li>
                <strong>Butt vs. Khan [1981] KLR 349</strong> - “an appellate court will not disturb an award of damages unless it is so inordinately high or
                low as to represent an entirely erroneous estimate”
            </li>
            <li>
                <strong>Total (Kenya) Limited formerly Caltex Oil (Kenya) Limited vs. Janevans Limited [2015] eKLR</strong> - “whether the claim is in contract
                or tort, the only damages to which an aggrieved party is entitled to is the pecuniary loss; (b) the accruing awardable damages is aimed at
                putting the aggrieved party into as good a position as if there had been no such breach or interference. In other words, in the position
                it/he/she was in with regard to the object trespassed upon before the onset of such a trespass; (c) it is meant to cushion the aggrieved party
                against the expenses caused as a result of the trespass and loss of benefit over the period of the duration of the trespass.”
            </li>
        </ol>
    </li>
    <li>
        Doctrines:
        <ol>
            <li>Doctrine Point #1:</li>
            <li>Doctrine Point #2:</li>
        </ol>
    </li>
    <li>
        Sessional Paper #1:
        <ol>
            <li>Sessional Paper Point #1:</li>
            <li>Sessional Paper Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>Plaintiff is indeed entitled to Kshs. 2 million as general damages for the continuous trespass.</li>
    <li>
        The burden of proof was upon the Plaintiff to prove how she had arrived at the aforementioned proposed figures. The Plaintiff filed a schedule of loss
        and damages dated the 16th August, 2017 which she produced as an exhibit in Court but failed to tender evidence to support the itemized claims therein.
    </li>
    <li>Plaintiff failed to support her claim for mesne profits as indicated in her submissions.</li>
    <li>I further opine that it would be pertinent if the Plaintiff and Defendant had a structured Wayleave Agreement.</li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
`;

export const trespassToLandCourtOfAppealAppealCaseLawStep_5_Content = `
<p class="lead">
    What were the orders, penalties, charges, sentencing, costs e.t.c.
</p>
<h2>
    The holding
</h2>
<p>
    On whether the the perjury charge should be stopped and the subordinate court prevented from continuing the process.
</p>
<ol>
    <li>
        In so far as the Plaintiff did not provide evidence on the loss she had incurred due the Defendant’s acts of trespass but in associating myself with
        the decisions cited above and noting that the Defendant has been on the suit land from 2013 to date which is 7 years, I find that she is indeed
        entitled to Kshs. 2 million as general damages for the continuous trespass.
    </li>
    <li>
        The Defendant constructed high voltage power lines which have remained on the suit land since 2013 to todate as evident by the photographs produced as
        an exhibit and it is immaterial whether the Plaintiff was absent when the said powerlines were being constructed.  Further, I concur with the Plaintiff
        and find that its actions indeed amount to continuous trespass.
    </li>
</ol>
<h2>
    Orders
</h2>
<ol>
    <li>
        A declaration be and is hereby issued that the Defendant has trespassed on the suit property without the Plaintiff’s consent and caused permanent
        and irreparable damages thereto;
    </li>
    <li>General damages for the continuous trespass be and is hereby awarded at Kshs. 2 million.</li>
    <li>Compensatory damages be and is hereby awarded at Kshs. 12 million</li>
    <li>Costs of the suit is awarded to the Plaintiff.</li>
    <li> Interest on (ii), (iii) and (iv) above until payment in full.</li>
    <li>
        Court of Appeal Orders
        <ul>
            <li>Liability in favour of the respondents at 100% is affirmed.</li>
            <li>
                The award of damages for continuous trespass allowed by the trial court at Kshs.2,000,000.00 for Eunice and Kshs.4,000,000.00 for George
                and Ellah is confirmed.
            </li>
            <li>
                The award of compensatory damages awarded to the respondents by the trial court is set aside and substituted with an award of
                Kshs.6,000,000.00 for Eunice and Kshs.12,000,000.00 for George and Ellah.
            </li>
            <li>
                For the reasons that the trespass is still continuing and for the benefit of the appellant, there will be no award of costs to the appellant
                for the appeal partially allowed.
            </li>
        </ul>
    </li>
</ol>
<h2>
    Sentences
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Fines
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Damages
</h2>
<ul>
    <li>None</li>
</ul>
`;

export const trespassToLandCourtOfAppealAppealCaseLawStep_6_Content = `
<p class="lead">
    Public reviews and comments
</p>
<h2>
    Member Comments
</h2>
<p>
    Some of the comments from members.
</p>
<ol>
    <li>Comment #1</li>
    <li>Comment #2</li>
</ol>
`;

export const trespassToLandCourtOfAppealAppealCaseLawStep_7_Content = `
<p class="lead">
    Case Brief
</p>
<h2>
    To print or share this case brief with your network. Check all is well and then click on the appropriate button.
</h2>
<p>
    The case brief has not been generated yet.
</p>
`;

// contractDuressHighCourtCaseLawSteps
export const contractDuressHighCourtCaseLawStep_0_Content = `
<p>
    <strong>CIVIL APPEAL NO 42 OF 1975</strong>
</p>
<p class="lead">
    This is a consolidated appeal case (Court of Appeal) between KPLC Vs Eunice Nkirote Ringera (Civil Appeal No. E247 of 2020) and another case between
    George Joseph Kangethe and Ellah Karwitha Kangethe ("George and Ellah") Vs KPLC. Both cases having been heard against the appelant
    in the Environment and Land Court at Kajiado
</p>
<h2>
    Chronology of events
</h2>
<p>
    This issue started from the Environment and Land Court and parially successfully appealed at the court of appeal ruling.
</p>
<ol>
    <li>
        <strong>Background :</strong> Suit properties
        <ul>
            <li>
                Respondents averred that they were seperately registered as proprietors of land parcels title numbers Ngong/Ngong/15676 comprising
                0.81hectares in favour of Eunice, 15678 comprising 1.21 hectares in favour of George and lastly, 15677 comprising 0.40hectares in favour of
                Ellah (hereinafter referred to as the 1st, 2nd and 3rd suit properties).
            </li>
        </ul>
    </li>
    <li>
        <strong>Respondent Submissions :</strong> The respondents cumulative submissions against the appellant
        <ul>
            <li>
                On or about june 2013, the appellant, without their consent, permission or authority intentionally, knowingly and/or voluntarily and without
                lawful justification trespassed on their respective suit properties and carried out the impugned activities causing permanent and irreparable
                damage as more particularly set out in their respective plaints, in respect of which reliefs are sort as below:
                <ul>
                    <li>
                        Eunice sought reliefs in ELC No. 869 of 2017 as follows:
                        <ul>
                            <li>a declaration that the appellant had trespassed on the suit property without her consent and caused permanent and irreparable damage thereto;</li>
                            <li>an injunction compelling the appellant, its servants, agents or employees, to cease the trespass and remove all of the concrete poles with heavy duty electric cables from the first suit property;</li>
                            <li>general damages;</li>
                            <li>compensatory damages;</li>
                            <li>costs of the suit;</li>
                            <li>interest on damages and costs</li>
                            <li>any other relief this court may deem fit and just to grant</li>
                        </ul>
                    </li>
                    <li>
                        George and Ellah sought reliefs in ELC No. 797 of 2017 as follows:
                        <ul>
                            <li>a declaration that the appellant had trespassed on the 2nd and 3rd suit properties without their consent and caused permanent and irreparable damages thereto;</li>
                            <li>an injunction compelling the appellant, its servants, agents or employees, to cease the trespass and remove all of the concrete poles with heavy duty electric cables from the suit properties;</li>
                            <li>general damages;</li>
                            <li>compensatory damages;</li>
                            <li>costs of the suit;</li>
                            <li>interest on damages and costs</li>
                            <li>any other relief this court may deem fit and just to grant</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Appellant's Defence</strong> In a rebuttal of the aforementioned claims
        <ul>
            <li>The appellant filed a defence dated 16th October, 2017 in ELC No. 869 of 2017</li>
            <li>The appellant filed a defence dated 25th July, 2017 in ELC Case No. 797 of 2017</li>
            <li>
                Cumulatively the appellant denied each and every wrong doing attributed to it by Eunice, George and Ellah in their respective plaints
                and put them to strict proof.
            </li>
            <li>The appellant also prayed for the suits to be dimissed with cost.</li>
        </ul>
    </li>
    <li>
        <strong>ELC No. 869 of 2017:</strong> Eunice Nkirote Ringera v Kenya Power & Lighting Company [2020] eKLR
        <ul>
            <li>
                Plaintiff Submissions
                <ul>
                    <li>Plaint dated 26th September, 2017;</li>
                    <li>
                        Evidence
                        <ul>
                            <li>
                                Alleged defendant entered the suit land without her permission, cut down trees, dug deep holes, erected high concrete poles
                                including putting high voltage wires thereon
                            </li>
                            <li>claimed to have incurred irreparable damages as she cannot use the land because of the high voltage wires thereon.</li>
                            <li>It was her testimony that she could not sell the land or build on it but could only plant maize thereon.</li>
                            <li>claimed the defendant trespassed on her land while she was away.</li>
                            <li>
                                explained that around 7 up to 10 poles had been erected on her land. She testified that she had produced evidence in court on
                                the type of trees that were cut including their value.
                            </li>
                            <li>
                                produced her Certificate of Title and Official Search; Photographs; Demand Letter and Certified Copy of the Valuation Report as
                                her exhibits
                            </li>
                        </ul>
                    </li>
                    <li>Plaintiff prays for judgement with reliefs.</li>

                </ul>
            </li>
            <li>
                Defendant Submissions
                <ul>
                    <li>Defence dated 16th October 2017;</li>
                    <li>Defence never tendered any evidence;</li>
                    <li>denied interfering with the Plaintiff’s possession and enjoyment of the suit land.</li>
                    <li>
                        denied entering the suit land, cut down trees, dig up holes or erect concrete poles with heavy duty electric cables without the
                        Plaintiff’s consent thereby occasioning permanent and irreparable damage on it.
                    </li>
                    <li>denied being served with demand or notice of intention to sue, and it prayed for this suit to be dismissed with costs.</li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>ELC No. 797 of 2017:</strong> George Joseph Kangethe and Another v Kenya Power & Lighting Company [2020] eKLR
        <ul>
            <li>
                Plaintiff Submissions
                <ul>
                    <li>Plaint dated 4th July, 2017;</li>
                    <li>
                        Evidence
                        <ul>
                            <li>
                                Alleged defendant entered the suit land without her permission, cut down trees, dug deep holes, erected high concrete poles
                                including putting high voltage wires thereon
                            </li>
                            <li>claimed to have incurred irreparable damages as she cannot use the land because of the high voltage wires thereon.</li>
                            <li>It was her testimony that she could not sell the land or build on it but could only plant maize thereon.</li>
                            <li>claimed the defendant trespassed on her land while she was away.</li>
                            <li>
                                explained that around 7 up to 10 poles had been erected on her land. She testified that she had produced evidence in court on
                                the type of trees that were cut including their value.
                            </li>
                            <li>
                                produced her Certificate of Title and Official Search; Photographs; Demand Letter and Certified Copy of the Valuation Report as
                                her exhibits
                            </li>
                        </ul>
                    </li>
                    <li>Plaintiff prays for judgement with reliefs.</li>

                </ul>
            </li>
            <li>
                Defendant Submissions
                <ul>
                    <li>Defence dated 26th July 2017;</li>
                    <li>Defence never tendered any evidence;</li>
                    <li>denied interfering with the Plaintiff’s possession and enjoyment of the suit land.</li>
                    <li>
                        denied entering the suit land, cut down trees, dig up holes or erect concrete poles with heavy duty electric cables without the
                        Plaintiff’s consent thereby occasioning permanent and irreparable damage on it.
                    </li>
                    <li>denied being served with demand or notice of intention to sue, and it prayed for this suit to be dismissed with costs.</li>
                </ul>
            </li>
        </ul>
    </li>
</ol>
`;

export const contractDuressHighCourtCaseLawStep_1_Content = `
<p class="lead">
    This case is about an appeal at the Court of Appeal involving two cases of trespass having been heard and determined seperately at the Environment & Lands Court.
</p>
<h2>
    Plainiff Averment
</h2>
<p>
    The respondents have a common plaintiff who:
</p>
<ol>
    <li>Is in disagreement with the damages and compensation awarded to the respondents by the lower court</li>
</ol>
<h2>
    Respondents Averment
</h2>
<p>
    Both respondents avers:
</p>
<ol>
    <li>They deserve the damages and compensation awarded by the lower court.</li>
</ol>
`;

export const contractDuressHighCourtCaseLawStep_2_Content = `
<p class="lead">
    What are the legal questions raised by the paries
</p>
<h2>
    Issues for determination
</h2>
<p>
    Both prosecutor and respondent are interested in a specific question of law.
</p>
<ol>
    <li>
        Whether the defendant (in the lower court) trespassed onto the plaintiff's land parcel number Ngong/Ngong/15676
    </li>
    <li>
        Whether the plaintiff is entitled to the orders sought in the plaint
    </li>
    <li>
        Whether the learned Judge (trial judge) erroneously and excessively awarded general and compensatory damages for continuing trespass to the respondents
        by failing to take into account relevant factors to the detriment of the appellant.
    </li>
    <li>
        Whether the learned Judge fell into error when she failed to consider and apply the specified provisions of the Energy Act, 2006 in the discharge of
        her mandate in assessing damages in favour of the respondents as against the appellant.
    </li>
    <li>
        In light of the court’s findings with regard to issue number 3 and 4 above what are the appropriate orders to be made herein in the disposal of these
        consolidated appeals.
    </li>
</ol>
<h2>
    Submissions
</h2>
<p>
    On the first issue:
</p>
<ol>
    <li>The defendant deny's allegation of trespass.</li>
    <li>The plaintiff use section 3 of the trespass act and section 171 and 173 of the energy act to substantiate the allegations of trespass.</li>
</ol>
<p>
    On the Second issue:
</p>
<ol>
    <li>The defendant claims a compensation of 100,000 Shillings is sufficient for the plaintiff.</li>
    <li>The plaintiff on the other had claims they deserve more as awarded by the lower court</li>
</ol>
<p>
    On the Third issue:
</p>
<ol>
    <li>The appelant claims the award by the trial court is in excess and goes against common law.</li>
    <li>
        The respondents on the other had claims the trial judge used common law available evidence to come up with the award and that the appellate court should
        not interfere.
    </li>
</ol>
<p>
    On the Fourth issue:
</p>
<ol>
    <li>The appelant claims the trial judge fell into error by not applying section 46(1) and 50(1) of the Energy Act as the basis for resolving the issue</li>
    <li>
        The respondents claim that the principles applied in arriving at the award in their favor are the same as those underpinning the Energy Act, 2006 and
        urged the court to affirm the trial courts award.
    </li>
</ol>
`;

export const contractDuressHighCourtCaseLawStep_3_Content = `
<p class="lead">
    The rules as per the issues raised
</p>
<h2>
    On whether the Defendant trespassed onto the Plaintiff’s land parcel no. Ngong/Ngong/15676.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>Reference #1</li>
                    <li>Reference #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>
                        <strong>Section 46 of the Energy Act, 2006</strong> - “No person shall enter upon any land, other than his own- (a) to lay or connect an electric supply
                        line; or (b) to carry out a survey of the land for the purpose of paragraph (a) except with the prior permission of the owner of such
                        land.”(2) Permission sought in subsection 1 above shall be done by way of notice which shall be accompanied by statement of particulars
                        of entry.
                    </li>
                    <li>
                        <strong>Section 171 of the Energy Act Cap 1 of 2019</strong> - “171 (1) A person who wishes to enter upon any land, other than his own to—(a)undertake
                        exploratory activities relating to exploitation of energy resources and development of energy infrastructure, including but not limited
                        to laying or connecting electric supply lines, petroleum or gas pipelines, or drilling exploratory wells;(b)carry out a survey of the
                        land for the purposes of paragraph (a);shall seek the prior consent of the owner of such land, which consent shall not be unreasonably
                        withheld: Provided that where the owner cannot be traced, the applicant shall give fifteen days' notice, through appropriate mechanisms
                        including public advertisement in at least two newspapers of nationwide circulation and an announcement in a radio station of local
                        coverage for a period of two weeks.”
                    </li>
                    <li>
                        <strong>Section 173 of the Energy Act Cap 1 of 2019</strong> - (1)An owner, after receipt of a request for consent under section 171 may consent in
                        writing to the development of energy infrastructure, upon agreement being reached with the applicant as to the amount of compensation
                        payable, if any, and any consent so given shall be binding on all parties having an interest in the land, subject to the following
                        provisions—(a)that any compensation to be paid by the licensee giving notice to the owner, in cases where the owner is under incapacity
                        or has no power to consent to the application except under this Act, shall be paid to the legal representative of the owner; and(b)that
                        an occupier or person other than the owner interested in the land shall be entitled to compensation for any loss or damage he may
                        sustain by the development of energy infrastructure, including but not limited to laying or connecting electric supply lines,
                        petroleum or gas pipelines, drilling geothermal wells or coal long as the claim is made within three months after the development.”
                    </li>
                    <li>
                        <strong>Section 3 of the Trespass Act</strong> - “(1)Any person who without reasonable excuse enters, is or remains upon, or erects any structure on,
                        or cultivates or tills, or grazes stock or permits stock to be on, private land without the consent of the occupier thereof shall be
                        guilty of an offence.(2)Where any person is charged with an offence under subsection (1) of this section the burden of proving that he
                        had reasonable excuse or the consent of the occupier shall lie upon him.”
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>John Kiragu Kimani vs Rural Electrification Authority (2018) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “Following that evidence, it is clear from the record that no consent, authority or permission of the Plaintiff was
                                        ever sought and/or obtained. No notice was given to him of the impending project as contemplated by section 46 of the
                                        Energy Act. The irresistible conclusion is that the Defendant is guilty of trespass.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Eliud Njoroge Gachiri vs. Stephen Kamau Nganga elc no. 121 of 2017</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “However in a case of continuing trespass, a trespass consists of a series acts done on consecutive days that are of
                                        the same nature and that are renewed or continued from day to day so that the acts are aggregate form one indivisible
                                        harm.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
</ul>

<h2>
    On whether the Plaintiff is entitled to the orders sought in the Plaint.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>Reference #1</li>
                    <li>Reference #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>Statute #1</li>
                    <li>Statute #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>Duncan Nderitu Ndegwa vs. Kenya Pipeline Company Limited & Another (2013) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Samwel Motari Nyambati vs KPLC (2018) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Tourist Development Corporation vs. Sundowner Lodge Limited (2018) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Obadiah k. Macharia vs. Kenya Power and Lighting Company Ltd (2016) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>SJ vs. Francesco Di Nello & Another [2015] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “Claims under the heads of loss of future earnings and loss of
                                        earning capacity are distinctively different. Loss of income which may be defined as real actual loss is loss of future earnings. Loss of
                                        earning capacity may be defined as diminution in earning capacity. Loss of income or future earnings is compensated for real assessable loss
                                        which is proved by evidence. On the other hand, loss of earning capacity is compensated by an award in general damages, once proved.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Capital Fish Kenya Limited Vs The Kenya Power & Lighting Company Limited [2016] eKLR</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        ’No evidence whatsoever was
                                        led by the appellant on this aspect. This, as we already stated elsewhere, was an abstract figure which was thrown to the court with a mere
                                        statement that “this is the loss the appellant has suffered. Please award it to the appellant.”
                                        In the case of Ryce Motors Ltd & Another vs Muchoki (1995-98) 2 E. A 363 (CAK) commenting on statements of accounts presented without more as
                                        in this case stated, this Court observed;

                                        “… The pieces of paper produced as evidence of income could not be accepted as correct accounting practice. They did not constitute proof of
                                        special damages.”

                                        For all the foregoing reasons, we are satisfied that although the trial court correctly found that the special damages had been specifically
                                        pleaded, there was no credible evidence whatsoever that proved the pleaded special damages. The trial court’s finding on that score can thus
                                        not be faulted.’
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Duncan Nderitu Ndegwa v. KP & LC Limited & Another (2013) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “…once a trespass to land is established it is actionable
                                        per se, and indeed no proof of damage is necessary for the court to award general damages. This court accordingly awards an amount of Kshs
                                        100,000/= as compensation of the infringement of the Plaintiff’s right to use and enjoy the suit property occasioned by the 1st and
                                        2nd Defendants’   trespass”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Kenya Power & Lighting Company Limited v Fleetwood Enterprises Limited [2017] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “Trespass is proved as in this case, the affected party such as the respondent need not prove that it suffered any damages or loss as a
                                        result so as to be awarded damages. The court is under the circumstances bound to award damages, of course, depending on the facts of each
                                        case.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Fleetwood Enterprises Ltd v Kenya Power & Lighting Co. Ltd [2015] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “The award of damages for trespass is discretionary in nature.
                                        The discretion by the court should however be exercised judiciously and all relevant factors should be considered. The value of land is a
                                        determinant factor where parties consent that the power line should not be moved. In this case the Plaintiff wanted the power lines removed.
                                        He proceeded to further state that, “ it is trite law that the value of land keeps on appreciating, and had the plaintiff developed the 16
                                        parcels of land 5 years ago, they would have generated income from them. In the circumstances of this case, I would agree with the Plaintiff’s
                                        advocate submissions that had the 16 plots been developed were it not for the connected electric line. The plaintiff would have earned at
                                        least Ksh. 5000 per month. However, due to the vagaries that come with such developments, I will reduce the said figure to an amount of
                                        Kshs. 4,000 per plot per month. The total payable damages to the defendant’s acts of trespass over the 16 plots for a period of 5 years will
                                        therefore be Kshs 4,000*12*5*16= Kshs. 3,840,000.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
</ul>

<h2>
    On whether the learned Judge (trial judge) erroneously and excessively awarded general and compensatory damages for continuing trespass to the respondents
    by failing to take into account relevant factors to the detriment of the appellant.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>Reference #1</li>
                    <li>Reference #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>
                        Statute #1
                    </li>
                    <li>
                        Statute #2
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>Kiambaa Dairy Farmers Co-operative Society Limited vs. Rhoda Njeri & 3 Others [2018] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “the extent of an award of
                                        compensatory damages lies in the discretion of the trial court and interference therewith on appeal must be approached with a measure of
                                        circumspection and well settled principles;”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Peter M. Kariuki vs. Attorney General [2014] eKLR</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “the principles that guide an appellate court in this country on an appeal
                                        for an award of damages are now well settled; Kemfro Africa Limited vs. Lubia & Another (No. 2) [1987] KLR 30, on the principles to be observed
                                        by an appellate court in deciding whether it is justified in disturbing the quantum of damages awarded by a trial court namely, that the
                                        appellate court must be satisfied that either the Judge in assessing the damages took into account an irrelevant factor, or left out of account
                                        a relevant one, or that short of this, the award is so inordinately low or so inordinately high that it must be a wholly erroneous estimate of
                                        the damages payable;”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Johnson Evans Gicheru vs. Andrew Morton & Another [2005] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “this Court (appellate court) will be disinclined to disturb
                                        the finding of a trial Judge as to the amount of damages awarded by the trial court merely because if they had tried the case in the first
                                        instance they would have awarded a higher sum.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Ephantus Mwangi vs. Duncan Mwangi Wambugu [1984] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “a Court of Appeal will not normally interfere with a finding of fact
                                        by the trial court, unless it is based on no evidence or on a misapprehension of the evidence or the Judge is shown demonstrably to have acted
                                        on wrong principles in reaching the findings he did;”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Farah Awad vs. CMC Motors Group Limited [2018] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “on the mandate of the first appellate court,namely, to reappraise,
                                        reassess and reanalyze the evidence on the record and arrive at own conclusions on the matter and give reason either way”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Sumaria & Another vs. Allied Industries Limited [2007] 2 KLR 1.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “an appellate court should be slow in moving to interfere with
                                        a finding of fact by a trial court unless it was based on no evidence or based on a misapprehension of the evidence or that the Judge had been
                                        shown demonstrably to have acted on a wrong principle in reaching the finding he/she did.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>United India Insurance Company Limited vs. East African Underwriters Kenya Ltd [1985] KLR 898.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “this Court wasexplicit that
                                        interference with exercise of judicial discretion only arises where there is clear demonstration of misdirection in law, misapprehension of the
                                        facts, taking into consideration factors the trial court ought not to have taken into consideration or failure to take into consideration
                                        factors that ought to have been taken into consideration or looking at the decision generally, the only plausible conclusion reached is that
                                        the decision albeit a discretionary one, is plainly wrong.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                </ul>
            </li>
        </ol>
    </li>
</ul>
`;

export const contractDuressHighCourtCaseLawStep_4_Content = `
<p class="lead">
    Analysis and Legal Reasoning
</p>
<h2>
    On whether the Defendant trespassed onto the Plaintiff’s land parcel no. Ngong/Ngong/15676.
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Black’s Law Dictionary 8th edition defines Continous Trespass as “A trespass in the nature of a permanent invasion on another’s property.”</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Harlsburys Laws of England:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Definitions:
        <ol>
            <li>
                <strong>Eliud Njoroge Gachiri vs. Stephen Kamau Nganga elc no. 121 of 2017</strong> - “However in a case of continuing trespass, a trespass consists of a series
                acts done on consecutive days that are of the same nature and that are renewed or continued from day to day so that the acts are aggregate form
                one indivisible harm.”
            </li>
            <li>
                <strong>Municipal Council of Eldoret vs. Titus Gatitu Njau [2020] eKLR</strong> - “
                Trespass is a violation of the right to possession and a plaintiff must prove that he has the right to immediate and exclusive possession of
                the land which is different from ownership.”
            </li>
        </ol>
    </li>
    <li>
        Case Law Holdings:
        <ol>
            <li>
                <strong>John Kiragu Kimani vs. Rural Electrification Authority (2018) eKLR</strong> - “Following that evidence, it is clear from the record that no consent,
                authority or permission of the Plaintiff was ever sought and/or obtained. No notice was given to him of the impending project as contemplated
                by section 46 of the Energy Act. The irresistible conclusion is that the Defendant is guilty of trespass.”
            </li>
            <li>
                <strong>Philip Ayaya Aluchio vs. Crispinus Ngayo [2014] eKLR</strong> - “
                the measure of damages for trespass is the difference in the value of the plaintiff's property immediately before and immediately after the
                trespass or the cost of restoration, whichever is less;”
            </li>
            <li>
                <strong>Ephantus Mwangi & Another vs. Duncan Mwangi [1982 – 1988] I KAR 278</strong> - “an appellate court is not bound to accept and act on
                the trial court’s findings of fact if it appears clearly that the trial court failed to take account of particular circumstances or
                probabilities material to the issue in controversy in the case.”
            </li>
        </ol>
    </li>
    <li>
        Doctrines:
        <ol>
            <li>
                <strong>res ipsa loquitur:</strong> the principle that the mere occurrence of some types of accident is sufficient to imply negligence. Negligence particularly
                usually requires specific criteria to be met, but this doctrine allows for negligence to be proven indirectly.
            </li>
            <li>Doctrine Point #2:</li>
        </ol>
    </li>
    <li>
        Sessional Report #1:
        <ol>
            <li>Sessional Report Point #1:</li>
            <li>Sessional Report Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>It is trite that construction of a wayleave on private land requires consent of its owner.</li>
    <li>
        Defendant never adduced any evidence to confirm that the Plaintiff’s consent was obtained before the high voltage power lines were erected thereon and
        the alternative government institution that did so.
    </li>
    <li>
        Findings
        <ul>
            <li>
                Defendant overlooked procedural regulations in the construction of high voltage power lines on the suit land as set out in sections 171 and 173
                of the Energy Act Cap 1 of 2019
            </li>
            <li>the Defendant’s aforementioned actions amount to an illegal entry into private property and can be deemed as trespass.</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
<h2>
    On whether the Plaintiff is entitled to the orders sought in the Plaint.
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Harlsburys Laws of England:
        <ol>
            <li>
                4th Edition Vol. 45 at para 26 pg 1503: the owner of the land is entitled to nominal damages where there is no actual damage occasioned to the
                owner by the trespass, such amounts as will compensate the owner for loss of use resulting from the damage caused by the trespass, reasonable
                damages are payable where the trespasser has made use of the owner’s land, exemplary damages are payable where the trespassers conduct towards
                the owner is not only oppressive but also cynical and carried out in deliberate disregard of the right of the owner of the land with the object
                of making a gain by his/her unlawful conduct, general damages may be increased where the trespass is accompanied by aggravating circumstances to
                the detriment of the owner of the land.
            </li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Definitions:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Holdings:
        <ol>
            <li>
                <strong>Fleetwood Enterprises Ltd v Kenya Power & Lighting Co. Ltd [2015] eKLR</strong> - “The award of damages for trespass is discretionary in nature.
                The discretion by the court should however be exercised judiciously and all relevant factors should be considered. The value of land is a
                determinant factor where parties consent that the power line should not be moved. In this case the Plaintiff wanted the power lines removed.
                He proceeded to further state that, “ it is trite law that the value of land keeps on appreciating, and had the plaintiff developed the 16
                parcels of land 5 years ago, they would have generated income from them. In the circumstances of this case, I would agree with the Plaintiff’s
                advocate submissions that had the 16 plots been developed were it not for the connected electric line. The plaintiff would have earned at
                least Ksh. 5000 per month. However, due to the vagaries that come with such developments, I will reduce the said figure to an amount of
                Kshs. 4,000 per plot per month. The total payable damages to the defendant’s acts of trespass over the 16 plots for a period of 5 years will
                therefore be Kshs 4,000*12*5*16= Kshs. 3,840,000.”
            </li>
            <li>
                <strong>Kenya Power & Lighting Company Limited v Fleetwood Enterprises Limited [2017] eKLR</strong> - The court of appeal upheld the above decision and stated
                that “Trespass is proved as in this case, the affected party such as the respondent need not prove that it suffered any damages or loss as a
                result so as to be awarded damages. The court is under the circumstances bound to award damages, of course, depending on the facts of each
                case.”
            </li>
            <li>
                <strong>Duncan Nderitu Ndegwa v. KP & LC Limited & Another (2013) eKLR</strong> i was held that - “…once a trespass to land is established it is actionable
                per se, and indeed no proof of damage is necessary for the court to award general damages. This court accordingly awards an amount of Kshs
                100,000/= as compensation of the infringement of the Plaintiff’s right to use and enjoy the suit property occasioned by the 1st and
                2nd Defendants’   trespass” and that damages payable for trespass are the amount of diminution in value or the loss of reinstatement of the land
                with the overriding principle being to put the claimant in the position he was in prior to the infliction of harm.
            </li>
            <li>
                <strong>Philip Ayaya Aluchio vs. Crispinus Ngayo [2014] eKLR</strong> - “the measure of damages for trespass is the difference in the value of
                the plaintiffs’ property immediately before and immediately after the trespass or the cost of restoration whichever is less.”
            </li>
            <li>
                <strong>Capital Fish Kenya Limited Vs The Kenya Power & Lighting Company Limited [2016] eKLR</strong> the court of appeal held that - ’No evidence whatsoever was
                led by the appellant on this aspect. This, as we already stated elsewhere, was an abstract figure which was thrown to the court with a mere
                statement that “this is the loss the appellant has suffered. Please award it to the appellant.”
                In the case of Ryce Motors Ltd & Another vs Muchoki (1995-98) 2 E. A 363 (CAK) commenting on statements of accounts presented without more as
                in this case stated, this Court observed;

                “… The pieces of paper produced as evidence of income could not be accepted as correct accounting practice. They did not constitute proof of
                special damages.”

                For all the foregoing reasons, we are satisfied that although the trial court correctly found that the special damages had been specifically
                pleaded, there was no credible evidence whatsoever that proved the pleaded special damages. The trial court’s finding on that score can thus
                not be faulted.’
            </li>
            <li>
                <strong>SJ vs. Francesco Di Nello & Another [2015] eKLR</strong> the court of appeal held that - “Claims under the heads of loss of future earnings and loss of
                earning capacity are distinctively different. Loss of income which may be defined as real actual loss is loss of future earnings. Loss of
                earning capacity may be defined as diminution in earning capacity. Loss of income or future earnings is compensated for real assessable loss
                which is proved by evidence. On the other hand, loss of earning capacity is compensated by an award in general damages, once proved.”
            </li>
            <li>
                <strong>Kiambaa Dairy Farmers Co-operative Society Limited vs. Rhoda Njeri & 3 Others [2018] eKLR</strong> - “the extent of an award of
                compensatory damages lies in the discretion of the trial court and interference therewith on appeal must be approached with a measure of
                circumspection and well settled principles;”
            </li>
            <li>
                <strong>Peter M. Kariuki vs. Attorney General [2014] eKLR</strong> - “the principles that guide an appellate court in this country on an appeal
                for an award of damages are now well settled; Kemfro Africa Limited vs. Lubia & Another (No. 2) [1987] KLR 30, on the principles to be observed
                by an appellate court in deciding whether it is justified in disturbing the quantum of damages awarded by a trial court namely, that the
                appellate court must be satisfied that either the Judge in assessing the damages took into account an irrelevant factor, or left out of account
                a relevant one, or that short of this, the award is so inordinately low or so inordinately high that it must be a wholly erroneous estimate of
                the damages payable;”
            </li>
            <li>
                <strong>Johnson Evans Gicheru vs. Andrew Morton & Another [2005] eKLR</strong> - “this Court (appellate court) will be disinclined to disturb
                the finding of a trial Judge as to the amount of damages awarded by the trial court merely because if they had tried the case in the first
                instance they would have awarded a higher sum.” Justification for reversing a trial Judge on an award of damages only applies where the court
                is convinced either that the Judge acted upon some wrong principle of law or that the amount awarded was so extremely high or so very low as to
                make it an entirely erroneous estimate of the damage to which the aggrieved party is entitled.
            </li>
            <li>
                <strong>Ephantus Mwangi vs. Duncan Mwangi Wambugu [1984] eKLR</strong> - “a Court of Appeal will not normally interfere with a finding of fact
                by the trial court, unless it is based on no evidence or on a misapprehension of the evidence or the Judge is shown demonstrably to have acted
                on wrong principles in reaching the findings he did;”. An appellate court is not bound to accept and act on the trial court’s findings of fact
                if it appears clearly that the trial court failed to take account of particular circumstances or probabilities material to an estimate of
                evidence.
            </li>
            <li>
                <strong>Farah Awad vs. CMC Motors Group Limited [2018] eKLR</strong> - “on the mandate of the first appellate court,namely, to reappraise,
                reassess and reanalyze the evidence on the record and arrive at own conclusions on the matter and give reason either way”
            </li>
            <li>
                <strong>Sumaria & Another vs. Allied Industries Limited [2007] 2 KLR 1</strong> - “an appellate court should be slow in moving to interfere with
                a finding of fact by a trial court unless it was based on no evidence or based on a misapprehension of the evidence or that the Judge had been
                shown demonstrably to have acted on a wrong principle in reaching the finding he/she did.”
            </li>
            <li>
                <strong>United India Insurance Company Limited vs. East African Underwriters Kenya Ltd [1985] KLR 898</strong> - “this Court wasexplicit that
                interference with exercise of judicial discretion only arises where there is clear demonstration of misdirection in law, misapprehension of the
                facts, taking into consideration factors the trial court ought not to have taken into consideration or failure to take into consideration
                factors that ought to have been taken into consideration or looking at the decision generally, the only plausible conclusion reached is that
                the decision albeit a discretionary one, is plainly wrong.”
            </li>
            <li>
                <strong>Kemfro Africa Limited vs. Lubia & Another [No. 2] [1987] KLR 30</strong> - “before interference with the quantum of damages awarded by
                a trial court the appellate court must be satisfied that either the judge in assessing the damages took into account an irrelevant factor, or
                left out of account a relevant one or short of the above, the award is so inordinately low or so inordinately high that it must be a wholly
                erroneous estimate of the damages payable.”
            </li>
            <li>
                <strong>Butt vs. Khan [1981] KLR 349</strong> - “an appellate court will not disturb an award of damages unless it is so inordinately high or
                low as to represent an entirely erroneous estimate”
            </li>
            <li>
                <strong>Total (Kenya) Limited formerly Caltex Oil (Kenya) Limited vs. Janevans Limited [2015] eKLR</strong> - “whether the claim is in contract
                or tort, the only damages to which an aggrieved party is entitled to is the pecuniary loss; (b) the accruing awardable damages is aimed at
                putting the aggrieved party into as good a position as if there had been no such breach or interference. In other words, in the position
                it/he/she was in with regard to the object trespassed upon before the onset of such a trespass; (c) it is meant to cushion the aggrieved party
                against the expenses caused as a result of the trespass and loss of benefit over the period of the duration of the trespass.”
            </li>
        </ol>
    </li>
    <li>
        Doctrines:
        <ol>
            <li>Doctrine Point #1:</li>
            <li>Doctrine Point #2:</li>
        </ol>
    </li>
    <li>
        Sessional Paper #1:
        <ol>
            <li>Sessional Paper Point #1:</li>
            <li>Sessional Paper Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>Plaintiff is indeed entitled to Kshs. 2 million as general damages for the continuous trespass.</li>
    <li>
        The burden of proof was upon the Plaintiff to prove how she had arrived at the aforementioned proposed figures. The Plaintiff filed a schedule of loss
        and damages dated the 16th August, 2017 which she produced as an exhibit in Court but failed to tender evidence to support the itemized claims therein.
    </li>
    <li>Plaintiff failed to support her claim for mesne profits as indicated in her submissions.</li>
    <li>I further opine that it would be pertinent if the Plaintiff and Defendant had a structured Wayleave Agreement.</li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
`;

export const contractDuressHighCourtCaseLawStep_5_Content = `
<p class="lead">
    What were the orders, penalties, charges, sentencing, costs e.t.c.
</p>
<h2>
    The holding
</h2>
<p>
    On whether the the perjury charge should be stopped and the subordinate court prevented from continuing the process.
</p>
<ol>
    <li>
        In so far as the Plaintiff did not provide evidence on the loss she had incurred due the Defendant’s acts of trespass but in associating myself with
        the decisions cited above and noting that the Defendant has been on the suit land from 2013 to date which is 7 years, I find that she is indeed
        entitled to Kshs. 2 million as general damages for the continuous trespass.
    </li>
    <li>
        The Defendant constructed high voltage power lines which have remained on the suit land since 2013 to todate as evident by the photographs produced as
        an exhibit and it is immaterial whether the Plaintiff was absent when the said powerlines were being constructed.  Further, I concur with the Plaintiff
        and find that its actions indeed amount to continuous trespass.
    </li>
</ol>
<h2>
    Orders
</h2>
<ol>
    <li>
        A declaration be and is hereby issued that the Defendant has trespassed on the suit property without the Plaintiff’s consent and caused permanent
        and irreparable damages thereto;
    </li>
    <li>General damages for the continuous trespass be and is hereby awarded at Kshs. 2 million.</li>
    <li>Compensatory damages be and is hereby awarded at Kshs. 12 million</li>
    <li>Costs of the suit is awarded to the Plaintiff.</li>
    <li> Interest on (ii), (iii) and (iv) above until payment in full.</li>
    <li>
        Court of Appeal Orders
        <ul>
            <li>Liability in favour of the respondents at 100% is affirmed.</li>
            <li>
                The award of damages for continuous trespass allowed by the trial court at Kshs.2,000,000.00 for Eunice and Kshs.4,000,000.00 for George
                and Ellah is confirmed.
            </li>
            <li>
                The award of compensatory damages awarded to the respondents by the trial court is set aside and substituted with an award of
                Kshs.6,000,000.00 for Eunice and Kshs.12,000,000.00 for George and Ellah.
            </li>
            <li>
                For the reasons that the trespass is still continuing and for the benefit of the appellant, there will be no award of costs to the appellant
                for the appeal partially allowed.
            </li>
        </ul>
    </li>
</ol>
<h2>
    Sentences
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Fines
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Damages
</h2>
<ul>
    <li>None</li>
</ul>
`;

export const contractDuressHighCourtCaseLawStep_6_Content = `
<p class="lead">
    Public reviews and comments
</p>
<h2>
    Member Comments
</h2>
<p>
    Some of the comments from members.
</p>
<ol>
    <li>Comment #1</li>
    <li>Comment #2</li>
</ol>
`;

export const contractDuressHighCourtCaseLawStep_7_Content = `
<p class="lead">
    Case Brief
</p>
<h2>
    To print or share this case brief with your network. Check all is well and then click on the appropriate button.
</h2>
<p>
    The case brief has not been generated yet.
</p>
`;

// contractEconomicDuressCaseLawSteps
export const contractEconomicDuressHighCourtCaseLawStep_0_Content = `
<p>
    <strong>Civil Case 1263 of 1992 - High Court</strong>
</p>
<p class="lead">
    This is a case where the plaintiff are claiming 56 Million from the defendants using the principle of unjust enrichment whose object is the reversal of a
    defendant's unjust enrichment or unjust benefit received at the underserved expense of a plaintiff, and in this case, by means of duress and coercive
    pressure.
</p>
<h2>
    Chronology of events
</h2>
<p>
    This issue started from the Environment and Land Court and parially successfully appealed at the court of appeal ruling.
</p>
<ol>
    <li>
        <strong>Background :</strong> Suit properties
        <ul>
            <li>
                Respondents averred that they were seperately registered as proprietors of land parcels title numbers Ngong/Ngong/15676 comprising
                0.81hectares in favour of Eunice, 15678 comprising 1.21 hectares in favour of George and lastly, 15677 comprising 0.40hectares in favour of
                Ellah (hereinafter referred to as the 1st, 2nd and 3rd suit properties).
            </li>
        </ul>
    </li>
    <li>
        <strong>Respondent Submissions :</strong> The respondents cumulative submissions against the appellant
        <ul>
            <li>
                On or about june 2013, the appellant, without their consent, permission or authority intentionally, knowingly and/or voluntarily and without
                lawful justification trespassed on their respective suit properties and carried out the impugned activities causing permanent and irreparable
                damage as more particularly set out in their respective plaints, in respect of which reliefs are sort as below:
                <ul>
                    <li>
                        Eunice sought reliefs in ELC No. 869 of 2017 as follows:
                        <ul>
                            <li>a declaration that the appellant had trespassed on the suit property without her consent and caused permanent and irreparable damage thereto;</li>
                            <li>an injunction compelling the appellant, its servants, agents or employees, to cease the trespass and remove all of the concrete poles with heavy duty electric cables from the first suit property;</li>
                            <li>general damages;</li>
                            <li>compensatory damages;</li>
                            <li>costs of the suit;</li>
                            <li>interest on damages and costs</li>
                            <li>any other relief this court may deem fit and just to grant</li>
                        </ul>
                    </li>
                    <li>
                        George and Ellah sought reliefs in ELC No. 797 of 2017 as follows:
                        <ul>
                            <li>a declaration that the appellant had trespassed on the 2nd and 3rd suit properties without their consent and caused permanent and irreparable damages thereto;</li>
                            <li>an injunction compelling the appellant, its servants, agents or employees, to cease the trespass and remove all of the concrete poles with heavy duty electric cables from the suit properties;</li>
                            <li>general damages;</li>
                            <li>compensatory damages;</li>
                            <li>costs of the suit;</li>
                            <li>interest on damages and costs</li>
                            <li>any other relief this court may deem fit and just to grant</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>Appellant's Defence</strong> In a rebuttal of the aforementioned claims
        <ul>
            <li>The appellant filed a defence dated 16th October, 2017 in ELC No. 869 of 2017</li>
            <li>The appellant filed a defence dated 25th July, 2017 in ELC Case No. 797 of 2017</li>
            <li>
                Cumulatively the appellant denied each and every wrong doing attributed to it by Eunice, George and Ellah in their respective plaints
                and put them to strict proof.
            </li>
            <li>The appellant also prayed for the suits to be dimissed with cost.</li>
        </ul>
    </li>
    <li>
        <strong>ELC No. 869 of 2017:</strong> Eunice Nkirote Ringera v Kenya Power & Lighting Company [2020] eKLR
        <ul>
            <li>
                Plaintiff Submissions
                <ul>
                    <li>Plaint dated 26th September, 2017;</li>
                    <li>
                        Evidence
                        <ul>
                            <li>
                                Alleged defendant entered the suit land without her permission, cut down trees, dug deep holes, erected high concrete poles
                                including putting high voltage wires thereon
                            </li>
                            <li>claimed to have incurred irreparable damages as she cannot use the land because of the high voltage wires thereon.</li>
                            <li>It was her testimony that she could not sell the land or build on it but could only plant maize thereon.</li>
                            <li>claimed the defendant trespassed on her land while she was away.</li>
                            <li>
                                explained that around 7 up to 10 poles had been erected on her land. She testified that she had produced evidence in court on
                                the type of trees that were cut including their value.
                            </li>
                            <li>
                                produced her Certificate of Title and Official Search; Photographs; Demand Letter and Certified Copy of the Valuation Report as
                                her exhibits
                            </li>
                        </ul>
                    </li>
                    <li>Plaintiff prays for judgement with reliefs.</li>

                </ul>
            </li>
            <li>
                Defendant Submissions
                <ul>
                    <li>Defence dated 16th October 2017;</li>
                    <li>Defence never tendered any evidence;</li>
                    <li>denied interfering with the Plaintiff’s possession and enjoyment of the suit land.</li>
                    <li>
                        denied entering the suit land, cut down trees, dig up holes or erect concrete poles with heavy duty electric cables without the
                        Plaintiff’s consent thereby occasioning permanent and irreparable damage on it.
                    </li>
                    <li>denied being served with demand or notice of intention to sue, and it prayed for this suit to be dismissed with costs.</li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>ELC No. 797 of 2017:</strong> George Joseph Kangethe and Another v Kenya Power & Lighting Company [2020] eKLR
        <ul>
            <li>
                Plaintiff Submissions
                <ul>
                    <li>Plaint dated 4th July, 2017;</li>
                    <li>
                        Evidence
                        <ul>
                            <li>
                                Alleged defendant entered the suit land without her permission, cut down trees, dug deep holes, erected high concrete poles
                                including putting high voltage wires thereon
                            </li>
                            <li>claimed to have incurred irreparable damages as she cannot use the land because of the high voltage wires thereon.</li>
                            <li>It was her testimony that she could not sell the land or build on it but could only plant maize thereon.</li>
                            <li>claimed the defendant trespassed on her land while she was away.</li>
                            <li>
                                explained that around 7 up to 10 poles had been erected on her land. She testified that she had produced evidence in court on
                                the type of trees that were cut including their value.
                            </li>
                            <li>
                                produced her Certificate of Title and Official Search; Photographs; Demand Letter and Certified Copy of the Valuation Report as
                                her exhibits
                            </li>
                        </ul>
                    </li>
                    <li>Plaintiff prays for judgement with reliefs.</li>

                </ul>
            </li>
            <li>
                Defendant Submissions
                <ul>
                    <li>Defence dated 26th July 2017;</li>
                    <li>Defence never tendered any evidence;</li>
                    <li>denied interfering with the Plaintiff’s possession and enjoyment of the suit land.</li>
                    <li>
                        denied entering the suit land, cut down trees, dig up holes or erect concrete poles with heavy duty electric cables without the
                        Plaintiff’s consent thereby occasioning permanent and irreparable damage on it.
                    </li>
                    <li>denied being served with demand or notice of intention to sue, and it prayed for this suit to be dismissed with costs.</li>
                </ul>
            </li>
        </ul>
    </li>
</ol>
`;

export const contractEconomicDuressHighCourtCaseLawStep_1_Content = `
<p class="lead">
    This case is about an appeal at the Court of Appeal involving two cases of trespass having been heard and determined seperately at the Environment & Lands Court.
</p>
<h2>
    Plainiff Averment
</h2>
<p>
    The respondents have a common plaintiff who:
</p>
<ol>
    <li>Is in disagreement with the damages and compensation awarded to the respondents by the lower court</li>
</ol>
<h2>
    Respondents Averment
</h2>
<p>
    Both respondents avers:
</p>
<ol>
    <li>They deserve the damages and compensation awarded by the lower court.</li>
</ol>
`;

export const contractEconomicDuressHighCourtCaseLawStep_2_Content = `
<p class="lead">
    What are the legal questions raised by the paries
</p>
<h2>
    Issues for determination
</h2>
<p>
    Both prosecutor and respondent are interested in a specific question of law.
</p>
<ol>
    <li>
        Whether the defendant (in the lower court) trespassed onto the plaintiff's land parcel number Ngong/Ngong/15676
    </li>
    <li>
        Whether the plaintiff is entitled to the orders sought in the plaint
    </li>
    <li>
        Whether the learned Judge (trial judge) erroneously and excessively awarded general and compensatory damages for continuing trespass to the respondents
        by failing to take into account relevant factors to the detriment of the appellant.
    </li>
    <li>
        Whether the learned Judge fell into error when she failed to consider and apply the specified provisions of the Energy Act, 2006 in the discharge of
        her mandate in assessing damages in favour of the respondents as against the appellant.
    </li>
    <li>
        In light of the court’s findings with regard to issue number 3 and 4 above what are the appropriate orders to be made herein in the disposal of these
        consolidated appeals.
    </li>
</ol>
<h2>
    Submissions
</h2>
<p>
    On the first issue:
</p>
<ol>
    <li>The defendant deny's allegation of trespass.</li>
    <li>The plaintiff use section 3 of the trespass act and section 171 and 173 of the energy act to substantiate the allegations of trespass.</li>
</ol>
<p>
    On the Second issue:
</p>
<ol>
    <li>The defendant claims a compensation of 100,000 Shillings is sufficient for the plaintiff.</li>
    <li>The plaintiff on the other had claims they deserve more as awarded by the lower court</li>
</ol>
<p>
    On the Third issue:
</p>
<ol>
    <li>The appelant claims the award by the trial court is in excess and goes against common law.</li>
    <li>
        The respondents on the other had claims the trial judge used common law available evidence to come up with the award and that the appellate court should
        not interfere.
    </li>
</ol>
<p>
    On the Fourth issue:
</p>
<ol>
    <li>The appelant claims the trial judge fell into error by not applying section 46(1) and 50(1) of the Energy Act as the basis for resolving the issue</li>
    <li>
        The respondents claim that the principles applied in arriving at the award in their favor are the same as those underpinning the Energy Act, 2006 and
        urged the court to affirm the trial courts award.
    </li>
</ol>
`;

export const contractEconomicDuressHighCourtCaseLawStep_3_Content = `
<p class="lead">
    The rules as per the issues raised
</p>
<h2>
    On whether the Defendant trespassed onto the Plaintiff’s land parcel no. Ngong/Ngong/15676.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>Reference #1</li>
                    <li>Reference #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>
                        <strong>Section 46 of the Energy Act, 2006</strong> - “No person shall enter upon any land, other than his own- (a) to lay or connect an electric supply
                        line; or (b) to carry out a survey of the land for the purpose of paragraph (a) except with the prior permission of the owner of such
                        land.”(2) Permission sought in subsection 1 above shall be done by way of notice which shall be accompanied by statement of particulars
                        of entry.
                    </li>
                    <li>
                        <strong>Section 171 of the Energy Act Cap 1 of 2019</strong> - “171 (1) A person who wishes to enter upon any land, other than his own to—(a)undertake
                        exploratory activities relating to exploitation of energy resources and development of energy infrastructure, including but not limited
                        to laying or connecting electric supply lines, petroleum or gas pipelines, or drilling exploratory wells;(b)carry out a survey of the
                        land for the purposes of paragraph (a);shall seek the prior consent of the owner of such land, which consent shall not be unreasonably
                        withheld: Provided that where the owner cannot be traced, the applicant shall give fifteen days' notice, through appropriate mechanisms
                        including public advertisement in at least two newspapers of nationwide circulation and an announcement in a radio station of local
                        coverage for a period of two weeks.”
                    </li>
                    <li>
                        <strong>Section 173 of the Energy Act Cap 1 of 2019</strong> - (1)An owner, after receipt of a request for consent under section 171 may consent in
                        writing to the development of energy infrastructure, upon agreement being reached with the applicant as to the amount of compensation
                        payable, if any, and any consent so given shall be binding on all parties having an interest in the land, subject to the following
                        provisions—(a)that any compensation to be paid by the licensee giving notice to the owner, in cases where the owner is under incapacity
                        or has no power to consent to the application except under this Act, shall be paid to the legal representative of the owner; and(b)that
                        an occupier or person other than the owner interested in the land shall be entitled to compensation for any loss or damage he may
                        sustain by the development of energy infrastructure, including but not limited to laying or connecting electric supply lines,
                        petroleum or gas pipelines, drilling geothermal wells or coal long as the claim is made within three months after the development.”
                    </li>
                    <li>
                        <strong>Section 3 of the Trespass Act</strong> - “(1)Any person who without reasonable excuse enters, is or remains upon, or erects any structure on,
                        or cultivates or tills, or grazes stock or permits stock to be on, private land without the consent of the occupier thereof shall be
                        guilty of an offence.(2)Where any person is charged with an offence under subsection (1) of this section the burden of proving that he
                        had reasonable excuse or the consent of the occupier shall lie upon him.”
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>John Kiragu Kimani vs Rural Electrification Authority (2018) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “Following that evidence, it is clear from the record that no consent, authority or permission of the Plaintiff was
                                        ever sought and/or obtained. No notice was given to him of the impending project as contemplated by section 46 of the
                                        Energy Act. The irresistible conclusion is that the Defendant is guilty of trespass.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Eliud Njoroge Gachiri vs. Stephen Kamau Nganga elc no. 121 of 2017</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “However in a case of continuing trespass, a trespass consists of a series acts done on consecutive days that are of
                                        the same nature and that are renewed or continued from day to day so that the acts are aggregate form one indivisible
                                        harm.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
</ul>

<h2>
    On whether the Plaintiff is entitled to the orders sought in the Plaint.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>Reference #1</li>
                    <li>Reference #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>Statute #1</li>
                    <li>Statute #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>Duncan Nderitu Ndegwa vs. Kenya Pipeline Company Limited & Another (2013) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Samwel Motari Nyambati vs KPLC (2018) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Tourist Development Corporation vs. Sundowner Lodge Limited (2018) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Obadiah k. Macharia vs. Kenya Power and Lighting Company Ltd (2016) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>SJ vs. Francesco Di Nello & Another [2015] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “Claims under the heads of loss of future earnings and loss of
                                        earning capacity are distinctively different. Loss of income which may be defined as real actual loss is loss of future earnings. Loss of
                                        earning capacity may be defined as diminution in earning capacity. Loss of income or future earnings is compensated for real assessable loss
                                        which is proved by evidence. On the other hand, loss of earning capacity is compensated by an award in general damages, once proved.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Capital Fish Kenya Limited Vs The Kenya Power & Lighting Company Limited [2016] eKLR</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        ’No evidence whatsoever was
                                        led by the appellant on this aspect. This, as we already stated elsewhere, was an abstract figure which was thrown to the court with a mere
                                        statement that “this is the loss the appellant has suffered. Please award it to the appellant.”
                                        In the case of Ryce Motors Ltd & Another vs Muchoki (1995-98) 2 E. A 363 (CAK) commenting on statements of accounts presented without more as
                                        in this case stated, this Court observed;

                                        “… The pieces of paper produced as evidence of income could not be accepted as correct accounting practice. They did not constitute proof of
                                        special damages.”

                                        For all the foregoing reasons, we are satisfied that although the trial court correctly found that the special damages had been specifically
                                        pleaded, there was no credible evidence whatsoever that proved the pleaded special damages. The trial court’s finding on that score can thus
                                        not be faulted.’
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Duncan Nderitu Ndegwa v. KP & LC Limited & Another (2013) eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “…once a trespass to land is established it is actionable
                                        per se, and indeed no proof of damage is necessary for the court to award general damages. This court accordingly awards an amount of Kshs
                                        100,000/= as compensation of the infringement of the Plaintiff’s right to use and enjoy the suit property occasioned by the 1st and
                                        2nd Defendants’   trespass”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Kenya Power & Lighting Company Limited v Fleetwood Enterprises Limited [2017] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “Trespass is proved as in this case, the affected party such as the respondent need not prove that it suffered any damages or loss as a
                                        result so as to be awarded damages. The court is under the circumstances bound to award damages, of course, depending on the facts of each
                                        case.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Fleetwood Enterprises Ltd v Kenya Power & Lighting Co. Ltd [2015] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “The award of damages for trespass is discretionary in nature.
                                        The discretion by the court should however be exercised judiciously and all relevant factors should be considered. The value of land is a
                                        determinant factor where parties consent that the power line should not be moved. In this case the Plaintiff wanted the power lines removed.
                                        He proceeded to further state that, “ it is trite law that the value of land keeps on appreciating, and had the plaintiff developed the 16
                                        parcels of land 5 years ago, they would have generated income from them. In the circumstances of this case, I would agree with the Plaintiff’s
                                        advocate submissions that had the 16 plots been developed were it not for the connected electric line. The plaintiff would have earned at
                                        least Ksh. 5000 per month. However, due to the vagaries that come with such developments, I will reduce the said figure to an amount of
                                        Kshs. 4,000 per plot per month. The total payable damages to the defendant’s acts of trespass over the 16 plots for a period of 5 years will
                                        therefore be Kshs 4,000*12*5*16= Kshs. 3,840,000.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
</ul>

<h2>
    On whether the learned Judge (trial judge) erroneously and excessively awarded general and compensatory damages for continuing trespass to the respondents
    by failing to take into account relevant factors to the detriment of the appellant.
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>Reference #1</li>
                    <li>Reference #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>
                        Statute #1
                    </li>
                    <li>
                        Statute #2
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>Kiambaa Dairy Farmers Co-operative Society Limited vs. Rhoda Njeri & 3 Others [2018] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “the extent of an award of
                                        compensatory damages lies in the discretion of the trial court and interference therewith on appeal must be approached with a measure of
                                        circumspection and well settled principles;”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Peter M. Kariuki vs. Attorney General [2014] eKLR</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “the principles that guide an appellate court in this country on an appeal
                                        for an award of damages are now well settled; Kemfro Africa Limited vs. Lubia & Another (No. 2) [1987] KLR 30, on the principles to be observed
                                        by an appellate court in deciding whether it is justified in disturbing the quantum of damages awarded by a trial court namely, that the
                                        appellate court must be satisfied that either the Judge in assessing the damages took into account an irrelevant factor, or left out of account
                                        a relevant one, or that short of this, the award is so inordinately low or so inordinately high that it must be a wholly erroneous estimate of
                                        the damages payable;”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Johnson Evans Gicheru vs. Andrew Morton & Another [2005] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “this Court (appellate court) will be disinclined to disturb
                                        the finding of a trial Judge as to the amount of damages awarded by the trial court merely because if they had tried the case in the first
                                        instance they would have awarded a higher sum.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Ephantus Mwangi vs. Duncan Mwangi Wambugu [1984] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “a Court of Appeal will not normally interfere with a finding of fact
                                        by the trial court, unless it is based on no evidence or on a misapprehension of the evidence or the Judge is shown demonstrably to have acted
                                        on wrong principles in reaching the findings he did;”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Farah Awad vs. CMC Motors Group Limited [2018] eKLR.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “on the mandate of the first appellate court,namely, to reappraise,
                                        reassess and reanalyze the evidence on the record and arrive at own conclusions on the matter and give reason either way”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Sumaria & Another vs. Allied Industries Limited [2007] 2 KLR 1.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “an appellate court should be slow in moving to interfere with
                                        a finding of fact by a trial court unless it was based on no evidence or based on a misapprehension of the evidence or that the Judge had been
                                        shown demonstrably to have acted on a wrong principle in reaching the finding he/she did.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>United India Insurance Company Limited vs. East African Underwriters Kenya Ltd [1985] KLR 898.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>
                                        “this Court wasexplicit that
                                        interference with exercise of judicial discretion only arises where there is clear demonstration of misdirection in law, misapprehension of the
                                        facts, taking into consideration factors the trial court ought not to have taken into consideration or failure to take into consideration
                                        factors that ought to have been taken into consideration or looking at the decision generally, the only plausible conclusion reached is that
                                        the decision albeit a discretionary one, is plainly wrong.”
                                    </li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                </ul>
            </li>
        </ol>
    </li>
</ul>
`;

export const contractEconomicDuressHighCourtCaseLawStep_4_Content = `
<p class="lead">
    Analysis and Legal Reasoning
</p>
<h2>
    On whether the Defendant trespassed onto the Plaintiff’s land parcel no. Ngong/Ngong/15676.
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Black’s Law Dictionary 8th edition defines Continous Trespass as “A trespass in the nature of a permanent invasion on another’s property.”</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Harlsburys Laws of England:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Definitions:
        <ol>
            <li>
                <strong>Eliud Njoroge Gachiri vs. Stephen Kamau Nganga elc no. 121 of 2017</strong> - “However in a case of continuing trespass, a trespass consists of a series
                acts done on consecutive days that are of the same nature and that are renewed or continued from day to day so that the acts are aggregate form
                one indivisible harm.”
            </li>
            <li>
                <strong>Municipal Council of Eldoret vs. Titus Gatitu Njau [2020] eKLR</strong> - “
                Trespass is a violation of the right to possession and a plaintiff must prove that he has the right to immediate and exclusive possession of
                the land which is different from ownership.”
            </li>
        </ol>
    </li>
    <li>
        Case Law Holdings:
        <ol>
            <li>
                <strong>John Kiragu Kimani vs. Rural Electrification Authority (2018) eKLR</strong> - “Following that evidence, it is clear from the record that no consent,
                authority or permission of the Plaintiff was ever sought and/or obtained. No notice was given to him of the impending project as contemplated
                by section 46 of the Energy Act. The irresistible conclusion is that the Defendant is guilty of trespass.”
            </li>
            <li>
                <strong>Philip Ayaya Aluchio vs. Crispinus Ngayo [2014] eKLR</strong> - “
                the measure of damages for trespass is the difference in the value of the plaintiff's property immediately before and immediately after the
                trespass or the cost of restoration, whichever is less;”
            </li>
            <li>
                <strong>Ephantus Mwangi & Another vs. Duncan Mwangi [1982 – 1988] I KAR 278</strong> - “an appellate court is not bound to accept and act on
                the trial court’s findings of fact if it appears clearly that the trial court failed to take account of particular circumstances or
                probabilities material to the issue in controversy in the case.”
            </li>
        </ol>
    </li>
    <li>
        Doctrines:
        <ol>
            <li>
                <strong>res ipsa loquitur:</strong> the principle that the mere occurrence of some types of accident is sufficient to imply negligence. Negligence particularly
                usually requires specific criteria to be met, but this doctrine allows for negligence to be proven indirectly.
            </li>
            <li>Doctrine Point #2:</li>
        </ol>
    </li>
    <li>
        Sessional Report #1:
        <ol>
            <li>Sessional Report Point #1:</li>
            <li>Sessional Report Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>It is trite that construction of a wayleave on private land requires consent of its owner.</li>
    <li>
        Defendant never adduced any evidence to confirm that the Plaintiff’s consent was obtained before the high voltage power lines were erected thereon and
        the alternative government institution that did so.
    </li>
    <li>
        Findings
        <ul>
            <li>
                Defendant overlooked procedural regulations in the construction of high voltage power lines on the suit land as set out in sections 171 and 173
                of the Energy Act Cap 1 of 2019
            </li>
            <li>the Defendant’s aforementioned actions amount to an illegal entry into private property and can be deemed as trespass.</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
<h2>
    On whether the Plaintiff is entitled to the orders sought in the Plaint.
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Harlsburys Laws of England:
        <ol>
            <li>
                4th Edition Vol. 45 at para 26 pg 1503: the owner of the land is entitled to nominal damages where there is no actual damage occasioned to the
                owner by the trespass, such amounts as will compensate the owner for loss of use resulting from the damage caused by the trespass, reasonable
                damages are payable where the trespasser has made use of the owner’s land, exemplary damages are payable where the trespassers conduct towards
                the owner is not only oppressive but also cynical and carried out in deliberate disregard of the right of the owner of the land with the object
                of making a gain by his/her unlawful conduct, general damages may be increased where the trespass is accompanied by aggravating circumstances to
                the detriment of the owner of the land.
            </li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Definitions:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Holdings:
        <ol>
            <li>
                <strong>Fleetwood Enterprises Ltd v Kenya Power & Lighting Co. Ltd [2015] eKLR</strong> - “The award of damages for trespass is discretionary in nature.
                The discretion by the court should however be exercised judiciously and all relevant factors should be considered. The value of land is a
                determinant factor where parties consent that the power line should not be moved. In this case the Plaintiff wanted the power lines removed.
                He proceeded to further state that, “ it is trite law that the value of land keeps on appreciating, and had the plaintiff developed the 16
                parcels of land 5 years ago, they would have generated income from them. In the circumstances of this case, I would agree with the Plaintiff’s
                advocate submissions that had the 16 plots been developed were it not for the connected electric line. The plaintiff would have earned at
                least Ksh. 5000 per month. However, due to the vagaries that come with such developments, I will reduce the said figure to an amount of
                Kshs. 4,000 per plot per month. The total payable damages to the defendant’s acts of trespass over the 16 plots for a period of 5 years will
                therefore be Kshs 4,000*12*5*16= Kshs. 3,840,000.”
            </li>
            <li>
                <strong>Kenya Power & Lighting Company Limited v Fleetwood Enterprises Limited [2017] eKLR</strong> - The court of appeal upheld the above decision and stated
                that “Trespass is proved as in this case, the affected party such as the respondent need not prove that it suffered any damages or loss as a
                result so as to be awarded damages. The court is under the circumstances bound to award damages, of course, depending on the facts of each
                case.”
            </li>
            <li>
                <strong>Duncan Nderitu Ndegwa v. KP & LC Limited & Another (2013) eKLR</strong> i was held that - “…once a trespass to land is established it is actionable
                per se, and indeed no proof of damage is necessary for the court to award general damages. This court accordingly awards an amount of Kshs
                100,000/= as compensation of the infringement of the Plaintiff’s right to use and enjoy the suit property occasioned by the 1st and
                2nd Defendants’   trespass” and that damages payable for trespass are the amount of diminution in value or the loss of reinstatement of the land
                with the overriding principle being to put the claimant in the position he was in prior to the infliction of harm.
            </li>
            <li>
                <strong>Philip Ayaya Aluchio vs. Crispinus Ngayo [2014] eKLR</strong> - “the measure of damages for trespass is the difference in the value of
                the plaintiffs’ property immediately before and immediately after the trespass or the cost of restoration whichever is less.”
            </li>
            <li>
                <strong>Capital Fish Kenya Limited Vs The Kenya Power & Lighting Company Limited [2016] eKLR</strong> the court of appeal held that - ’No evidence whatsoever was
                led by the appellant on this aspect. This, as we already stated elsewhere, was an abstract figure which was thrown to the court with a mere
                statement that “this is the loss the appellant has suffered. Please award it to the appellant.”
                In the case of Ryce Motors Ltd & Another vs Muchoki (1995-98) 2 E. A 363 (CAK) commenting on statements of accounts presented without more as
                in this case stated, this Court observed;

                “… The pieces of paper produced as evidence of income could not be accepted as correct accounting practice. They did not constitute proof of
                special damages.”

                For all the foregoing reasons, we are satisfied that although the trial court correctly found that the special damages had been specifically
                pleaded, there was no credible evidence whatsoever that proved the pleaded special damages. The trial court’s finding on that score can thus
                not be faulted.’
            </li>
            <li>
                <strong>SJ vs. Francesco Di Nello & Another [2015] eKLR</strong> the court of appeal held that - “Claims under the heads of loss of future earnings and loss of
                earning capacity are distinctively different. Loss of income which may be defined as real actual loss is loss of future earnings. Loss of
                earning capacity may be defined as diminution in earning capacity. Loss of income or future earnings is compensated for real assessable loss
                which is proved by evidence. On the other hand, loss of earning capacity is compensated by an award in general damages, once proved.”
            </li>
            <li>
                <strong>Kiambaa Dairy Farmers Co-operative Society Limited vs. Rhoda Njeri & 3 Others [2018] eKLR</strong> - “the extent of an award of
                compensatory damages lies in the discretion of the trial court and interference therewith on appeal must be approached with a measure of
                circumspection and well settled principles;”
            </li>
            <li>
                <strong>Peter M. Kariuki vs. Attorney General [2014] eKLR</strong> - “the principles that guide an appellate court in this country on an appeal
                for an award of damages are now well settled; Kemfro Africa Limited vs. Lubia & Another (No. 2) [1987] KLR 30, on the principles to be observed
                by an appellate court in deciding whether it is justified in disturbing the quantum of damages awarded by a trial court namely, that the
                appellate court must be satisfied that either the Judge in assessing the damages took into account an irrelevant factor, or left out of account
                a relevant one, or that short of this, the award is so inordinately low or so inordinately high that it must be a wholly erroneous estimate of
                the damages payable;”
            </li>
            <li>
                <strong>Johnson Evans Gicheru vs. Andrew Morton & Another [2005] eKLR</strong> - “this Court (appellate court) will be disinclined to disturb
                the finding of a trial Judge as to the amount of damages awarded by the trial court merely because if they had tried the case in the first
                instance they would have awarded a higher sum.” Justification for reversing a trial Judge on an award of damages only applies where the court
                is convinced either that the Judge acted upon some wrong principle of law or that the amount awarded was so extremely high or so very low as to
                make it an entirely erroneous estimate of the damage to which the aggrieved party is entitled.
            </li>
            <li>
                <strong>Ephantus Mwangi vs. Duncan Mwangi Wambugu [1984] eKLR</strong> - “a Court of Appeal will not normally interfere with a finding of fact
                by the trial court, unless it is based on no evidence or on a misapprehension of the evidence or the Judge is shown demonstrably to have acted
                on wrong principles in reaching the findings he did;”. An appellate court is not bound to accept and act on the trial court’s findings of fact
                if it appears clearly that the trial court failed to take account of particular circumstances or probabilities material to an estimate of
                evidence.
            </li>
            <li>
                <strong>Farah Awad vs. CMC Motors Group Limited [2018] eKLR</strong> - “on the mandate of the first appellate court,namely, to reappraise,
                reassess and reanalyze the evidence on the record and arrive at own conclusions on the matter and give reason either way”
            </li>
            <li>
                <strong>Sumaria & Another vs. Allied Industries Limited [2007] 2 KLR 1</strong> - “an appellate court should be slow in moving to interfere with
                a finding of fact by a trial court unless it was based on no evidence or based on a misapprehension of the evidence or that the Judge had been
                shown demonstrably to have acted on a wrong principle in reaching the finding he/she did.”
            </li>
            <li>
                <strong>United India Insurance Company Limited vs. East African Underwriters Kenya Ltd [1985] KLR 898</strong> - “this Court wasexplicit that
                interference with exercise of judicial discretion only arises where there is clear demonstration of misdirection in law, misapprehension of the
                facts, taking into consideration factors the trial court ought not to have taken into consideration or failure to take into consideration
                factors that ought to have been taken into consideration or looking at the decision generally, the only plausible conclusion reached is that
                the decision albeit a discretionary one, is plainly wrong.”
            </li>
            <li>
                <strong>Kemfro Africa Limited vs. Lubia & Another [No. 2] [1987] KLR 30</strong> - “before interference with the quantum of damages awarded by
                a trial court the appellate court must be satisfied that either the judge in assessing the damages took into account an irrelevant factor, or
                left out of account a relevant one or short of the above, the award is so inordinately low or so inordinately high that it must be a wholly
                erroneous estimate of the damages payable.”
            </li>
            <li>
                <strong>Butt vs. Khan [1981] KLR 349</strong> - “an appellate court will not disturb an award of damages unless it is so inordinately high or
                low as to represent an entirely erroneous estimate”
            </li>
            <li>
                <strong>Total (Kenya) Limited formerly Caltex Oil (Kenya) Limited vs. Janevans Limited [2015] eKLR</strong> - “whether the claim is in contract
                or tort, the only damages to which an aggrieved party is entitled to is the pecuniary loss; (b) the accruing awardable damages is aimed at
                putting the aggrieved party into as good a position as if there had been no such breach or interference. In other words, in the position
                it/he/she was in with regard to the object trespassed upon before the onset of such a trespass; (c) it is meant to cushion the aggrieved party
                against the expenses caused as a result of the trespass and loss of benefit over the period of the duration of the trespass.”
            </li>
        </ol>
    </li>
    <li>
        Doctrines:
        <ol>
            <li>Doctrine Point #1:</li>
            <li>Doctrine Point #2:</li>
        </ol>
    </li>
    <li>
        Sessional Paper #1:
        <ol>
            <li>Sessional Paper Point #1:</li>
            <li>Sessional Paper Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>Plaintiff is indeed entitled to Kshs. 2 million as general damages for the continuous trespass.</li>
    <li>
        The burden of proof was upon the Plaintiff to prove how she had arrived at the aforementioned proposed figures. The Plaintiff filed a schedule of loss
        and damages dated the 16th August, 2017 which she produced as an exhibit in Court but failed to tender evidence to support the itemized claims therein.
    </li>
    <li>Plaintiff failed to support her claim for mesne profits as indicated in her submissions.</li>
    <li>I further opine that it would be pertinent if the Plaintiff and Defendant had a structured Wayleave Agreement.</li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
`;

export const contractEconomicDuressHighCourtCaseLawStep_5_Content = `
<p class="lead">
    What were the orders, penalties, charges, sentencing, costs e.t.c.
</p>
<h2>
    The holding
</h2>
<p>
    On whether the the perjury charge should be stopped and the subordinate court prevented from continuing the process.
</p>
<ol>
    <li>
        In so far as the Plaintiff did not provide evidence on the loss she had incurred due the Defendant’s acts of trespass but in associating myself with
        the decisions cited above and noting that the Defendant has been on the suit land from 2013 to date which is 7 years, I find that she is indeed
        entitled to Kshs. 2 million as general damages for the continuous trespass.
    </li>
    <li>
        The Defendant constructed high voltage power lines which have remained on the suit land since 2013 to todate as evident by the photographs produced as
        an exhibit and it is immaterial whether the Plaintiff was absent when the said powerlines were being constructed.  Further, I concur with the Plaintiff
        and find that its actions indeed amount to continuous trespass.
    </li>
</ol>
<h2>
    Orders
</h2>
<ol>
    <li>
        A declaration be and is hereby issued that the Defendant has trespassed on the suit property without the Plaintiff’s consent and caused permanent
        and irreparable damages thereto;
    </li>
    <li>General damages for the continuous trespass be and is hereby awarded at Kshs. 2 million.</li>
    <li>Compensatory damages be and is hereby awarded at Kshs. 12 million</li>
    <li>Costs of the suit is awarded to the Plaintiff.</li>
    <li> Interest on (ii), (iii) and (iv) above until payment in full.</li>
    <li>
        Court of Appeal Orders
        <ul>
            <li>Liability in favour of the respondents at 100% is affirmed.</li>
            <li>
                The award of damages for continuous trespass allowed by the trial court at Kshs.2,000,000.00 for Eunice and Kshs.4,000,000.00 for George
                and Ellah is confirmed.
            </li>
            <li>
                The award of compensatory damages awarded to the respondents by the trial court is set aside and substituted with an award of
                Kshs.6,000,000.00 for Eunice and Kshs.12,000,000.00 for George and Ellah.
            </li>
            <li>
                For the reasons that the trespass is still continuing and for the benefit of the appellant, there will be no award of costs to the appellant
                for the appeal partially allowed.
            </li>
        </ul>
    </li>
</ol>
<h2>
    Sentences
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Fines
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Damages
</h2>
<ul>
    <li>None</li>
</ul>
`;

export const contractEconomicDuressHighCourtCaseLawStep_6_Content = `
<p class="lead">
    Public reviews and comments
</p>
<h2>
    Member Comments
</h2>
<p>
    Some of the comments from members.
</p>
<ol>
    <li>Comment #1</li>
    <li>Comment #2</li>
</ol>
`;

export const contractEconomicDuressHighCourtCaseLawStep_7_Content = `
<p class="lead">
    Case Brief
</p>
<h2>
    To print or share this case brief with your network. Check all is well and then click on the appropriate button.
</h2>
<p>
    The case brief has not been generated yet.
</p>
`;

// constitutionalLawHumanRightsSupremeCourtCaseLawSteps
export const constitutionalLawHumanRightsSupremeCourtCaseLawStep_0_Content = `
<p>
    <strong>Petition 3 of 2018 - Supreme Court</strong>
</p>
<p class="lead">
    This is a case between Kenya Airports Authority and Members of Mitumba Village near Wilson Airport. Wilson Airport demolished houses despite
    conservatory orders being in place. This case has been traced from the high court, court of appeal and finally at the supreme court.
</p>
<h2>
    Chronology of events
</h2>
<p>
    This issue started from the High Court at Nairobi, milimani law courts Petition 164 of 2011.
</p>
<ol>
    <li>
        <strong>Petition at the trial court :</strong>
        <ul>
            <li>Petitioner comprimises residents of mitumba village having 3065 households, or approximately 15,325 men, women and children.</li>
            <li>
                Petition filed <strong>21st September 2011</strong> when all members of the society were residents of Mitumba VIllage located in plot number
                <strong>Plot Number 209/12908</strong>
            </li>
            <li>
                Children from the village went to Mitumba Primary on plot number <strong>Plo number 209/12921</strong>
            </li>
            <li>
                With the knowledge, support and facilitation from the government through the Chairman of the Nairobi City Commission, Mr. Fred Gumo and the
                second respondent, they came into Mitumba Village from their previous location, Bella-Vue village along Mombasa Road in 1992.
            </li>
            <li>
                Since 1992, they have setup their houses and businesses, as well as schools, churches and other social amenities at their new settlement. They
                have known Mitumba Village as their home and it forms the source of their livelihood, social life and education.
            </li>
            <li>
                The government recognized them by recognizing their schools including issuance of Consituency Development Funds for the area. However, they have
                unsuccessfully bid for the issuance of title deeds
            </li>
            <li>
                Despite order preventing demolishons of their houses, their houses were demolished by the 2nd respondent on 19th November 2011, 2 months
                after being issued with an order against the demolishions.
            </li>
            <li>
                <strong>The Petition</strong>
                <ul>
                    <li>
                        A declaration that the demolition by the 2nd Respondent is illegal, irregular, unprocedural and contrary to Art. 26, 27(2) (4) & (6)
                        Art. 28 Art 29, Art 39. 40 Art. 43, Art.47, Art 56, of the Constitution of the Republic of Kenya and is therefore null and void.
                    </li>
                    <li>
                        A declaration that any forceful eviction and or demolition without a relocation option is illegal, oppressive and  violates the
                        rights of the petitioners.
                    </li>
                    <li>
                        An order restraining any purported demolition and or forceful  eviction by the 2nd Respondent against the Petitioners.
                    </li>
                    <li>
                        A declaration that the resident of Mitumba Village are legally entitled to plot number 209/12921 under file number 226958  for Mitumba
                        primary school and plot Number 209/12908  under file number 176952 for the village respectively and in  the alternative they are entitled
                        to compensation and reallocation of another land or alternative shelter with access to education facilities, clean water, health care
                        and food at the state’s expense.
                    </li>
                    <li>
                        A declaration that the Petitioners herein are entitled to the  full protection from discrimination and the same right has been  violated
                        and they are entitled to full compensation as a result of loss suffered during and after the illegal demolition or their structures.
                    </li>
                    <li>
                        A declaration that the Petitioner’s herein and other members of the public are entitled to the full enjoyment of the right to  economic
                        and social rights that are about to be violated and or already violated.
                    </li>
                    <li>
                        Costs of this Petition.
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>1st Appeal at the Court of Appeal :</strong>
        <ul>
            <li>
                Issue #1:
                <ul>
                    <li>
                        Sub-Issue #1:
                        <ul>
                            <li>Point #1</li>
                            <li>Point #2</li>
                            <li>Point #2</li>
                        </ul>
                    </li>
                    <li>
                        Sub-Issue #2:
                        <ul>
                            <li>Point #1</li>
                            <li>Point #2</li>
                            <li>Point #2</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li>
        <strong>2nd Appeal at the Supreme Court</strong>
        <ul>
            <li>Point #1</li>
            <li>Point #1</li>
            <li>Point #1</li>
            <li>Point #1</li>
        </ul>
    </li>

</ol>
`;

export const constitutionalLawHumanRightsSupremeCourtCaseLawStep_1_Content = `
<p class="lead">
    These facts are those at the trial court.
</p>
<h2>
    The Petitioner's Case
</h2>
<p>
    The facts as submitted by the petitioner at the trial court:
</p>
<ol>
    <li>
        Submitted that this petition was as a result of the 7 days notice period they had recieved on 15th September 2011 through media publication from the second respondent.
    </li>
    <li>
        Petitioners, filed a Chambers Summons application seeking inter alia a conservatory order prevening any eviction and demolishn of the buildings, installations, or erections
        in Mitumba Village.
    </li>
    <li>
        Despite getting the order, the 2nd respondents went ahead and demolished the petitioners houses, the primary school, and other institutions within the village.
        The petitioners submitted that their households goods, and building materials were all destroyed during the demolition, and they were left homeless.
    </li>
    <li>
        Petitioners allege violation of fundamental rights and freedoms as gauranteed within the constitution. They allege the 2nd respondent violated their rights to life,
        human dignity, security of person, freedom of movement and residence, social economic rights, right to property, equality and non-discrimination  and fair administrative
        action as guaranteed under Articles 26, 27, 28, 29, 39, 40, 43 and 47 of the Constitution.
    </li>
    <li>
        Relying on <strong>Ibrahim Sangor Osman –vs- Minister of State for Provincial Administration and Internal Security & 3 Others, Embu HCCC No. 2 of 2011</strong>, they contend
        that a 7 days notice period is insufficient.
    </li>
    <li>
        They contend that wihtout having reasons in the notice for vacation, the demolitions were in total disregard of the law, particularly with regard to the right to adequate
        housing, and that it was also carried out in disregard of international prohibitions against forced evictions contained in Article 11 of the <strong>International Covenant on
        Economic, Social and Cultural Rights.</strong>
    </li>
    <li>
        While acknowledging the role of he 2nd respondent, they ask the court to be guided by the case of <strong>Susan Waithera Kariuki & 4 Others –vs- Town Clerk, Nairobi City
        Council & 2 Others, Petition No. 66 of 2006</strong>
    </li>
    <li>
        They allege, however, that the suit land does not belong to the 2nd respondent, and if it did, its right to the land had been extinguished and the petitioners were entitled
        to the land by virtue of the doctrine of adverse possession as they had lived on the land for well over 19 years.
    </li>
    <li>
        They assert that contrary to claims by the 2nd respondent, their village was not on the flight path, and that even if it was, the acts of the 2nd respondent were
        discriminatory as there are other, multi-storied structures around the airport that were not demolished.
    </li>
    <li>
        The petitioners allege further that the 2nd respondent did not consult with them before carrying out the demolition, and that it treated them in an inhumane and
        degrading manner. They therefore claim that their right to be treated with dignity guaranteed by Article 28 of the Constitution was violated;
    </li>
    <li>
        Relying on on the provisions of the <strong>Universal Declaration of Human Rights and Article 21(2) of the African Charter on Human and People's Rights</strong> which
        provide that in case of eviction, the dispossessed people shall have the right to the lawful recovery of their property, restoration to their original situation as well
        as to an adequate compensation.
    </li>
    <li>
        Relying on the decision of Muchelule J, in <strong>Ibrahim Sangor Osman –vs- Minister For State for Provincial Administration and Internal Security & 3 Others (supra)</strong>
        to claim that they are entitled to compensation and relocation to another parcel of land or alternative shelter with access to educational facilities, clean water,
        health care and food at the state\'s expenses. In this case the respondents were ordered to pay each of the 1,123 petitioners in that case Kshs 200,000, return them to
        the land they were evicted from, and construct houses for them.
    </li>
    <li>
        The petitioners allege violation of the children rights to basic nutrition, shelter, health care, and education, among others, guaranteed by Article 21(3) and 53 of the
        Constitution.
    </li>
</ol>
<h2>
    The Respondents Case
</h2>
<p>
    <strong>The facts as submitted by the 1st and 3rd Respondents at the trial court:</strong>
</p>
<ol>
    <li>
        The position taken by the 1st and 3rd respondents is that as the petitioners have failed to provide any evidence of their ownership of the suit land, they cannot claim
        violation of the right to property.
    </li>
    <li>
        They also submit that as the land belongs to the 2nd respondent which is a state entity, the doctrine of adverse possession cannot apply.
    </li>
    <li>
        The respondents argue that the basis of the petitioners’ claim is social economic rights, which rights are progressive in nature and are limited as provided under
        Article 25 of the Constitution; that the enjoyment of these rights and freedoms by any individual, including the petitioners, should not prejudice the rights and freedoms
        of others.
    </li>
    <li>
        They take the view that the 3rd respondent has acted in accordance with the law by protecting the interests of property owners and not allocating to the petitioners
        what has already been allocated to another party.
    </li>
    <li>
        Guided by the courts decision on the case <strong>Rashid Odhiambo Aloggoh and 245 Others –vs- Haco Industries Misc. Appli. 1520 of 1999</strong> They contend also that
        there was nothing before the court to demonstrate any violation of the petitioners’ constitutional rights as alleged, and that the petitioners had failed to raise any
        constitutional issue for the court to determine. In the subject case, the court held that any applicant who alleges that his rights have been infringed must state clearly
        with supporting facts and instances where such rights have been infringed.
    </li>
    <li>
        The respondents also submit that the petitioners are guilty of material non-disclosure as they have failed to disclose that they encroached on the 2nd respondent’s land.
        They urged the court to dismiss the petition for non-disclosure of material facts and relied in this regard on the decision in <strong>Kenya Bus Services Limited –vs- Attorney
        General and Others Misc. Civil Application No. 413 of 2005</strong> where it was held that non-disclosure of material facts is sufficient to warrant the dismissal of a
        constitutional application.
    </li>
</ol>
<p>
    <strong>The facts as submitted by the 2nd Respondents at the trial court:</strong>
</p>
<ol>
    <li>
        They contend that since the land the subject of this dispute belongs to it, there has been no violation of the petitioners’ right to property under Article 40 of the
        Constitution.
    </li>
    <li>
        Contends that it did not carry out the demolitions which it claims were carried out by state and security agents as the settlement posed a security threat due to the war
        in Somalia.
    </li>
    <li>
        Relying on the decision of the Supreme Court of India in <strong>Olga Tellis –vs- Bombay Municipal Corporation (1985) Supp SCR 51</strong> where it was held that the
        respondent was justified in directing the removal of the petitioners who had encroached on pavements and footpaths the respondent quesioned the justiciability
        of social economic rights. He submitted that such rights are second and third generation rights which impose a duty on the state to do certain things to guarantee the
        protection of these rights.  Therefore, even if these rights are justiciable, a balance has to be struck, and the court must strive to address the question of availability
        of funds from the executive for their enforcement.
    </li>
    <li>
        Relying on the case of <strong>Thiagray Soobramoney –vs- Minister of Health (Kwa-Zulu Natal) 9188 (1) SA 765</strong> and submitted that in that decision, the
        Constitutional Court of South Africa stated that one of the limiting factors to the attainment of the constitutional guarantee to social economic rights is that of limited
        resources.
    </li>
    <li>
        Relying on the decision of the Constitutional Court of South Africa in <strong>Irene Grootboom and Others v The Government of the Republic of South Africa and Others (2001)
        (1) SA 46</strong> in which the court held that section 26 and 28 of the Constitution of South Africa do not entitle the petitioners to claim shelter or housing immediately upon
        demand.  Similarly, the petitioners were not entitled to claim social economic rights two years after the promulgation of the Constitution.
    </li>
</ol>
`;

export const constitutionalLawHumanRightsSupremeCourtCaseLawStep_2_Content = `
<p class="lead">
    What are the legal questions raised by the parties
</p>
<h2>
    Findings and Issues by the Courts
</h2>
<p>
    The basic facts that gave rise to this petition are undisputed. The petitioners were all resident in the informal settlement known as Mitumba Village,
    which was situated near Wilson Airport, Nairobi. Some of the findings of the court are:
</p>
<ol>
    <li>
        <strong>Findings</strong>
        <ol>
            <li>
                <strong>Trial Court (High Court)</strong>
                <ol>
                    <li>
                        The 1st and 2nd respondents are therefore, in my view, responsible for the evictions and demolition of Mitumba village.
                    </li>
                    <li>
                        The eviction of the petitioners and demolition of their homes during the pendency of an order of the court went against all the tenets of the Constitution.
                    </li>
                    <li>
                        The 2nd respondent, as the party which had issued the notice to vacate and which alleged ownership of the land, was in contempt of the order of the court
                    </li>
                    <li>
                        It is worth observing that the Constitution vests, at Article 159, judicial authority in the judiciary.
                    </li>
                    <li>
                        The constitution vests in the High Court, under Article 165(3) (b), the jurisdiction to determine whether a right or fundamental freedom has been denied, infringed,
                        violated or threatened with violation.
                    </li>
                    <li>
                        At Article 22, the Constitution grants to every person the right to approach the court claiming that a fundamental right or freedom in the Bill of Rights has been
                        violated or is threatened with violation.
                    </li>
                    <li>
                        Upon such an application, the court has jurisdiction, under Article 23(3) (c), to grant a conservatory order. This is what the court (Gacheche J) did when the petitioners
                        first appeared before her on 21st September 2011.
                    </li>
                    <li>
                        Upon such an application, the court has jurisdiction, under Article 23(3) (c), to grant a conservatory order. This is what the court (Gacheche J) did when the petitioners
                        first appeared before her on 21st September 2011.
                    </li>
                    <li>
                        Article 2(1) provides that ‘This Constitution is the supreme law of the Republic and binds all persons and all State organs at both levels of government.’
                    </li>
                    <li>
                        At Article 20(1), the Constitution provides that ‘The Bill of Rights applies to all law and binds all State organs and all persons.’ It follows, therefore,
                        that the respondents, even before the demolitions took place, were under an obligation to abide by the provisions of the Constitution not only not to violate any of the
                        rights of the petitioners, but also to respect judicial authority and obey all such orders as the court may issue for the protection of the fundamental rights of the
                        petitioners.
                    </li>
                </ol>
            </li>
            <li>
                <strong>1st Appeal Court (Court of Appeal)</strong>
                <ol>
                    <li>Finding #1</li>
                    <li>Finding #2</li>
                    <li>Finding #3</li>
                </ol>
            </li>
            <li>
                <strong>2nd Appeal Court (Supreme Court)</strong>
                <ol>
                    <li>Finding #1</li>
                    <li>Finding #2</li>
                    <li>Finding #3</li>
                </ol>
            </li>
        </ol>
    </li>
    <li>
        <strong>Issues</strong>
        <ol>
            <li>
                <strong>Trial Court (High Court)</strong>
                <ol>
                    <li>What rights, if any, do the petitioners have over the subject property?</li>
                    <li>If the answer to i) above is in the negative, was their eviction and the demolition of their houses a Violation of their Rights under the Constitution?</li>
                    <li>If the answer to ii) above is in the positive, what relief should the court grant to the petitioners?</li>
                </ol>
            </li>
            <li>
                <strong>1st Appeal Court (Court of Appeal)</strong>
                <ol>
                    <li>Issue #1</li>
                    <li>Issue #2</li>
                    <li>Issue #3</li>
                </ol>
            </li>
            <li>
                <strong>2nd Appeal Court (Supreme Court)</strong>
                <ol>
                    <li>Issue #1</li>
                    <li>Issue #2</li>
                    <li>Issue #3</li>
                <ol>
            </li>
        </ol>
    </li>
</ol>
`;

export const constitutionalLawHumanRightsSupremeCourtCaseLawStep_3_Content = `
<p class="lead">
    The rules as per the issues raised
</p>
<h2>
    On the question of rights over the subject property, we look at adverse posession and allocation
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>
                        <strong>Article 40(1)</strong>
                        <ol>
                            <li>
                                Subject to Article 65, every person has the right, either individually or in association with others, to acquire and own property––
                                (a) of any description; and
                                (b) in any part of Kenya.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Article 65</strong>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Government Lands Act, Cap 281 of the Laws of Kenya (now repealed)</strong>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>
                        <strong>Limitation of Actions Act Section 41(a)(i)</strong>
                        <ol>
                            <li>
                                “This Act does not-
                                (a)  Enable a person to acquire any title to, or any easement over-
                                (i)  Government land or land otherwise enjoyed by the  government"
                            </li>
                            <li>Point #2</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Statute #1</strong>
                        <ol>
                            <li>Point #1</li>
                            <li>Point #2</li>
                            <li>Point #3</li>
                        </ol>
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>Peter Mwashi & Anor -vs- Javan Mwashi & Others, Eldoret HCC 38 OF 2004</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Beatrice Syokau -vs- Kenya Airports Authority & Another Petition No 1 of 2012.</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
</ul>

<h2>
    On whether the eviction and demolition of petitioner's houses are a violation of their rights under the constitution
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>
                        <strong>Article 2(5)</strong>
                        <ol>
                            <li>Point #1</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Article 2(6)</strong>
                        <ol>
                            <li>Point #1</li>
                        </ol>
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>Statute #1</li>
                    <li>Statute #2</li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>Case Law #1</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Case Law #2</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Case Law #3</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Case Law #4</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #5</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #6</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #7</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #8</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #9</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
</ul>

<h2>
    On the question of relief
</h2>
<ul>
    <li>
        <strong>Common Law References</strong>
        <ol>
            <li>
                Relevant Treaties
                <ul>
                    <li>Treaty #1</li>
                    <li>Treaty #2</li>
                </ul>
            </li>
            <li>
                Relevant foreign case laws
                <ul>
                    <li>
                        Case Law #1
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Case Law #2
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <strong>Domestic References</strong>
        <ol>
            <li>
                Relevant constitutional reference
                <ul>
                    <li>
                        <strong>Article 43</strong>
                        <ul>
                            <li>
                                43. (1) Every person has the right—
                                (a) ….
                                (b) to accessible and adequate housing, and to reasonable standards of sanitation;
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 21</strong>
                        <ul>
                            <li>
                                ‘It is a fundamental duty of the State and every state organ to observe, respect, protect, promote and fulfill the rights and fundamental freedoms
                                in the Bill of Rights'
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 21</strong>
                        <ul>
                            <li>Point #1</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 27</strong>
                        <ul>
                            <li>Point #1</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 28</strong>
                        <ul>
                            <li>Point #1</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 29</strong>
                        <ul>
                            <li>Point #1</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 47</strong>
                        <ul>
                            <li>Point #1</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 10</strong>
                        <ul>
                            <li>Point #1</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 53</strong>
                        <ul>
                            <li>Point #1</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 54</strong>
                        <ul>
                            <li>Point #1</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Article 56</strong>
                        <ul>
                            <li>Point #1</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic statutes
                <ul>
                    <li>
                        Statute #1
                    </li>
                    <li>
                        Statute #2
                    </li>
                </ul>
            </li>
            <li>
                Relevant domestic case laws
                <ul>
                    <li>
                        <strong>Case Law #1</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Case Law #2</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #3</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #4</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #5</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #6</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <strong>Case Law #7</strong>
                        <ul>
                            <li>
                                Holding
                                <ul>
                                    <li>Holding #1</li>
                                    <li>Holding #2</li>
                                    <li>Holding #3</li>
                                </ul>
                            </li>
                            <li>
                                Orders
                                <ul>
                                    <li>Order #1</li>
                                    <li>Order #2</li>
                                    <li>Order #3</li>
                                </ul>
                            </li>
                            <li>
                                Sentences
                                <ul>
                                    <li>Sentence #1</li>
                                    <li>Sentence #2</li>
                                    <li>Sentence #3</li>
                                </ul>
                            </li>
                            <li>
                                Fines
                                <ul>
                                    <li>Fine #1</li>
                                    <li>Fine #2</li>
                                    <li>Fine #3</li>
                                </ul>
                            </li>
                            <li>
                                Damages
                                <ul>
                                    <li>Damages #1</li>
                                    <li>Damages #2</li>
                                    <li>Damages #3</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                </ul>
            </li>
        </ol>
    </li>
</ul>
`;

export const constitutionalLawHumanRightsSupremeCourtCaseLawStep_4_Content = `
<p class="lead">
    Analysis and Legal Reasoning
</p>
<h2>
    On the question of rights over the subject property, we look at adverse posession and allocation
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Harlsburys Laws of England:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Definitions:
        <ol>
            <li>Definition #1</li>
            <li>Definition #2</li>
        </ol>
    </li>
    <li>
        Constitutional Definitions:
        <ol>
            <li>Holding #1</li>
            <li>Holding #2</li>
            <li>Holding #3</li>
        </ol>
    </li>
    <li>
        Case Law Holdings:
        <ol>
            <li>Holding #1</li>
            <li>Holding #2</li>
            <li>Holding #3</li>
        </ol>
    </li>
    <li>
        Doctrines:
        <ol>
            <li>Doctrine #1</li>
            <li>Doctrine #2:</li>
        </ol>
    </li>
    <li>
        Sessional Report #1:
        <ol>
            <li>Sessional Report Point #1:</li>
            <li>Sessional Report Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>
                <strong>General Comment No. 9 on the Domestic Application of the International Covenant on Economic, Social and Cultural Rights, CESCR</strong>
                <ul>
                    <li>
                        Paragraph 10 - ‘The adoption of a rigid classification of economic, social and cultural rights which puts them, by definition, beyond the reach of the
                        courts would thus be arbitrary and incompatible with the principle that the two sets of human rights are indivisible and interdependent. It would also
                        drastically curtail the capacity of the courts to protect the rights of the most vulnerable and disadvantaged groups in society.’
                    </li>
                </ul>
            </li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>
                <strong>Vienna Declaration and Programme of Actions</strong>
                <ul>
                    <li>Paragraph 5 - ‘All human rights are universal, indivisible and interdependent and interrelated.’</li>
                </ul>
            </li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>
        The 2nd respondent is a state corporation established under section 3(1) of the Kenya Airports Authority Act, Chapter 395 of the Laws of Kenya. It is not possible therefore
        for the petitioners to maintain a claim in adverse possession over its land in light of the above statutory provisions.
    </li>
    <li>
        In any event, under the law then in force as provided in the Government Lands Act, Cap 281 of the Laws of Kenya (now repealed), the Provincial Commissioner had no authority
        in law to allocate land.
    </li>
    <li>
        Suffice to say that there is nothing before me to support the petitioners’ claim that they have a legal basis for alleging ownership of the land. Consequently, I find that
        the petitioners have no legitimate claim to the land, and cannot therefore maintain a claim for violation of their right to property under Article 40 in respect of the land.
    </li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
<h2>
    On whether the eviction and demolition of petitioner's houses a violation of their rights under the constitution
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Harlsburys Laws of England:
        <ol>
            <li>Definition #1</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Constitution Definitions:
        <ol>
            <li>
                <strong>Article 260</strong>
                <ul>
                    <li>
                    <strong>“property”</strong> includes any vested or contingent right to, or interest in or arising from—

                    (a) land, or permanent fixtures on, or improvements to, land;

                   (b) goods or personal property;
                   (c) intellectual property; or
                   (d) money, choses in action or negotiable instruments;
                    </li>
                </ul>
            </li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Definitions:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Holdings:
        <ol>
            <li>
                <strong>Peter Mwashi & Anor -vs- Javan Mwashi & Others, Eldoret HCC 38 OF 2004</strong>
                <ol>
                    <li>Holding #1</li>
                    <li>Holding #2</li>
                </ol>
            </li>
            <li>
                <strong>Beatrice Syokau -vs- Kenya Airports Authority & Another Petition No 1 of 2012.</strong>
                <ol>
                    <li>Holding #1</li>
                    <li>Holding #2</li>
                </ol>
            </li>
        </ol>
    </li>
    <li>
        Doctrines:
        <ol>
            <li>Doctrine Point #1:</li>
            <li>Doctrine Point #2:</li>
        </ol>
    </li>
    <li>
        Sessional Paper #1:
        <ol>
            <li>Sessional Paper Point #1:</li>
            <li>Sessional Paper Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>
                <strong>General Comment No. 7 “The right to adequate housing (Art.11.1): forced evictions:. (20/05/97) CESCR General comment 7. (General Comments).”</strong>
                <ol>
                    <li>
                        <strong>Paragraph 15 provides as follows:</strong> “15. Appropriate procedural protection and due process are essential aspects of all human rights but are especially
                        pertinent in relation to a matter such as forced evictions which directly invokes a large number of rights recognized in both the international covenant on
                        human rights. The committee considers that the procedural protections which should be applied in relation to forced evictions include:

                        (a) an opportunity for genuine consultation with those affected;

                        (b) adequate and reasonable notice for all affected persons prior to the scheduled date of eviction;

                        (c) information on the proposed evictions, and, where applicable, on the alternative purpose for which the land or housing is to be used, to be made
                        available in reasonable time to all those affected;

                    (d) especially where groups of people are involved, government officials or their representatives to be present during an eviction;

                    (e) all persons carrying out the eviction to be properly identified;

                    (f) evictions not to take place in particularly bad weather or at night unless the affected persons consent otherwise;

                    (g) provision of legal remedies; and

                    (h) provision, where possible, of legal aid to persons who are in need of it to seek redress from the courts."
                    </li>
                    <li>
                        <strong>Paragraph 16 provides as follows:</strong> "Evictions should not result in individuals being rendered homeless or vulnerable to the violation of other human rights.
                        Where those affected are unable to provide for themselves, the State party must take all reasonable measures, to the maximum of its available resources, to
                        ensure that adequate alternative housing, resettlement or access to productive land, as the case may be is available.”  (Emphasis added)"
                    </li>
                    <li>
                        <strong>Paragraph 10 provides as follows:</strong> ‘Women, children, youth, older persons, indigenous people, ethnic and other minorities, and other
                        vulnerable individuals and groups all suffer disproportionately from the practice of forced eviction.’
                    </li>
                </ol>
            </li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>
                <strong>Case Law Statements</strong>
                <ol>
                    <li>
                        <strong>Satrose Ayuma & 11 Others -vs- Registered Trustees of the Kenya Railways Staff Retirement Benefit Scheme & 2 Others Petition No. 65 of 2010.</strong>
                        <ol>
                            <li>
                                In granting an injunction restraining the eviction of the petitioners in the matter, Justice Musinga stated in his judgement "At some particular point
                                in time the tenants will have to move out of the estate but when that time comes, that ought to be done in a humane manner. The challenge of providing
                                 accessible and adequate housing as required under Article 43(b) of the Constitution is all evident. The problem of informal settlements in urban
                                 areas cannot be wished away, it is here with us. There is therefore need to address the issue of forced evictions and <strong>develop clear policy and legal
                                 guidelines</strong> relating thereto."
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Susan Waithera Kariuki & 4 Others –vs- Town Clerk Nairobi City Council & 2 Others Petition No. 66 of 2011</strong>
                        <ol>
                            <li>
                                While holding that the eviction of the residents from their homes in the settlement would be a violation of the petitioners right to housing,
                                Justice Masinga observed that "......While I agree that the 1st respondent has a duty to control developments in the city of Nairobi as required
                                under the Local Government Act as well as the Physical Planning Act, the protection of the petitioner\'s fundamental right as guaranteed under the
                                constitution overrides the aforesaid duty and responsibility of the 1st respondent.  The petitioners have resided on the properties where they are
                                being evicted from for many years....."
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Ibrahim Sangor Osman & Another –vs- Minister for State for Provincial Administration & Another (Supra)</strong>
                        <ol>
                            <li>
                                a notice to vacate within 21 days issued to the petitioners in that case was unreasonable, and the subsequent evictions were a violation of the
                                rights of the petitioners to accessible and adequate housing under the Constitution.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Olga Tellis & Others v Bombay Municipal Corporation (supra)</strong>
                        <ol>
                            <li>
                                'It is therefore essential that the procedure prescribed by law for depriving a person of his fundamental right, must conform to the means of
                                justice and fair play. Procedure which is unjust or unfair in the circumstances of a case, attracts the vice of unreasonableness, thereby vitiating
                                the law which prescribes that procedure and consequently, the action taken under it. Any action taken by a public authority which is invested with
                                statutory powers has, therefore, to be tested by the application of two standards; the action must be within the scope of the authority conferred
                                by law and secondly, it must be reasonable.’
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>
        This country has yet to develop legislation and guidelines for eviction of persons occupying land which they are not legally entitled to occupy. However, as a member of the
        international community and a signatory to various United Nations treaties and conventions, it is bound by such international guidelines as exist that are intended to
        safeguard the rights of persons liable to eviction. Article 2(5) and (6) of the Constitution make the general rules of international law and any treaty or convention that
        Kenya has ratified part of the law of Kenya.
    </li>
    <li>
        Consequently, the state, state organs and all persons, in carrying out evictions, should do so in accordance with the United Nations Guidelines on Evictions as enunciated
        by The United Nations Office of the High Commissioner for Human Rights in General Comment No. 7 “The right to adequate housing (Art.11.1): forced evictions:. (20/05/97)
        CESCR General comment 7. (General Comments).” as captured in paragraph 15 and 16.
    </li>
    <li>
        With reference made to <strong>Satrose Ayuma & 11 Others -vs- Registered Trustees of the Kenya Railways Staff Retirement Benefit Scheme & 2 Others Petition No. 65
        of 2010.</strong>, <strong>Susan Waithera Kariuki & 4 Others –vs- Town Clerk Nairobi City Council & 2 Others Petition No. 66 of 2011</strong>, <strong>Ibrahim Sangor
        Osman & Another –vs- Minister for State for Provincial Administration & Another (Supra)</strong>, It is unreasonable, unconscionable and unconstitutional to give
        persons in the position of the petitioners seven days’ notice within which to vacate their homes, and then demolish their homes without giving them alternative
        accommodation. These cases also highlighted the lack of legal guidelines governing evictions in Kenya, whether such intended evictions are from formal or informal
        settlements. I therefore find and hold that the eviction of the petitioners from Mitumba Village after a 7 day notice was unreasonable.
        <ol>
            <li>
                <strong>Violation of the Right to Property under Article 40</strong>
                <ul>
                    <li>
                        As per the definition of the word 'property' as found in Article 260, the right o property extends to other property besides land. The constitutional right to
                        property thus extends to, and must therefore include protection of, goods and personal property.
                    </li>
                </ul>
            </li>

            <li>
                <strong>Violation of the Right to Housing and Other Social Economic Rights</strong>
                <ul>
                    <li>
                        The indivisibility and interdependence of human rights as captured in <strong>Paragraph 5 of the 1993 Vienna Declaration and Programme of Actions adopted by the
                        World Conference on Human Rights on 25th June 1993</strong> and in <strong>Paragraph 10 of General Comment No. 9 on the Domestic Application of the International Covenant on
                        Economic, Social and Cultural Rights, CESCR, General Comment 9, The Domestic Application of the Covenant (Nineteenth session, 1998), U.N. Doc.E/C.12/1998/24 (1998)</strong> ought to be recognized.
                    </li>
                    <li>
                        Article 21 and 43 require that there should be ‘progressive realization’ of social economic rights, implying that the state must begin to take steps, and I might add be
                        seen to take steps, towards realization of these rights. While it is true access to these rights is progressive in nature and is dependent on the availability of
                        resources by the state, Article 21(1) is clear on the expected duty of the state where it calls on every organ of state to observe, respect, protect, promote, and fullfil
                        the rights and fundamental freedoms in the Bill of Rights. Therefore, the state has an obligation to <strong>observe, respect, protect, promote and fulfil</strong>
                        socio-economic rights and in particular, the right to adequate and accessible housing. The duty to respect implies that the state has a duty to refrain from interfering
                        directly or indirectly with the enjoyment of the right. In other words, the state not only has a positive duty to fulfil the rights guaranteed under Article 43 by taking
                        positive steps to ensure access by citizens, but it also has a negative obligation not to do anything that impairs the enjoyment of these rights.
                    </li>
                    <li>
                        The risk of violation of rights is not only to the petitioners but also in the national values and principles of governance as set out in Article 10 which include
                        ‘(b) human dignity, equity, social justice, inclusiveness, equality, human rights, non-discrimination and protection of the marginalized.’
                    </li>
                </ul>
            </li>

            <li>
                <strong>Violation of Civil and Political Rights</strong>
                <ul>
                    <li>
                        The Constitution guarantees to all citizens, at Article 26, the right to life, Article 27 contains the non-discrimination provisions and guarantees to everyone equal
                        protection and benefit of the law, while Article 28 recognises the inherent dignity of all and guarantees to all the right to have that dignity respected.
                        At Article 29, the right of everyone to security of the person and to freedom from violence from either private or public sources is guaranteed. Article 47 contains
                        the constitutional guarantee to fair administrative action.
                    </li>
                    <li>
                        A failure by the state to ensure that citizens have access to the rights guaranteed by Article 43 directly impacts on the ability of citizens to enjoy all the other
                        rights set out in the Constitution.
                    </li>
                </ul>
            </li>

            <li>
                <strong>Constitutional Requirement for Consultation and Participation</strong>
                <ul>
                    <li>
                        While allowing evictions as was the case in <strong>Olga Tellis & Others v Bombay Municipal Corporation (supra)</strong>, the court court did recognize their right to
                        be heard and that any process that did not allow this would violate the right to shelter and a livelihood.
                    </li>
                    <li>
                        In the ruling of <strong>Susan Waithera -vs- city Council of Nairobi (supra)</strong>, Justice Musinga held that performance of a statutory duty cannot excuse
                        violation of citizens’ constitutional rights.
                    </li>
                    <li>
                        The principle of consultation and participation of the people is entrenched in the Constitution in the national values and principles; Article 47 requires fair
                        administrative action, which encompasses the notion of notice, consultation and a right to be heard before adverse action is taken.
                    </li>
                </ul>
            </li>

            <li>
                <strong>Right to Non-discrimination and Equal Protection of the Law</strong>
                <ul>
                    <li>
                        The constitutional provision as found in Article 27(2), (4) and (6) provides clear safeguards to discrimination. There is evident therefore, in the selective
                        demolition of Mitumba village, violation of the right to non-discrimination and equal protection of the law guaranteed under Article 27(1), (2) and (4) of the
                        Constitution.
                    </li>
                </ul>
            </li>

            <li>
                <strong>Violation of the rights of Children</strong>
                <ul>
                    <li>
                        Children are perhaps the most vulnerable of the vulnerable and marginalised groups that the state has an obligation to protect in accordance with Article 10 on
                        the National Values and Principles, Articles 53 and 54 on protection of children and persons with disabilities respectively, and Article 56 on protection of the
                        marginalised.
                    </li>
                    <li>
                        The state’s constitutional obligation to ‘observe, respect, protect, promote and fulfil’ enjoyment of social economic rights is particularly important with
                        regard to these groups, who, more often than not, are limited in their capacity to provide for themselves.
                    </li>
                    <li>
                        As the United Nations Office of the High Commissioner for Human Rights also observed at paragraph 10 of General Comment No. 7 (supra). There has been no averment
                        by any of the respondents that in the period leading up to and during the demolition, they took any action to provide for the needs of vulnerable groups,
                        particularly children. It is therefore uncontested that the actions of the respondents in demolishing the village resulted in a violation of the rights of the
                        petitioners’ children under the Constitution.
                    </li>
                </ul>
            </li>
        </ol>
    </li>

    <li>
        <strong>Disposition</strong>
        <ul>
            <li>
                The petitioners have made out a clear case of violation of their constitutional rights by the 1st and 2nd respondents following the demolition of Mitumba Village on
                19th November 2011 and on a subsequent occasion thereafter.
            </li>
            <li>
                The actions of the respondents in this case are all the more reprehensible as the demolition was carried out while there was in force an order of this court
                restraining the demolition of the petitioners’ houses and other institutions in the village.
            </li>
            <li>
                The inescapable conclusion is that, whether alone or with other state agents, the 2nd respondent, which had issued to the petitioners the notice to vacate within
                7 days, committed egregious violations of the rights of the petitioners. The state and the 2nd respondent are, in my view, jointly and severally liable for the
                violation of the petitioners’ rights in the demolition of the petitioners’ houses.
            </li>
        </ul>
    </li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
<h2>
    On the question of relief
</h2>
<p>
    <strong>Written literature and other references</strong>
</p>
<ol>
    <li>
        Black Law Dictionary:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Harlsburys Laws of England:
        <ol>
            <li>Definition #1</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Definitions:
        <ol>
            <li>Definition #1:</li>
            <li>Definition #2:</li>
        </ol>
    </li>
    <li>
        Case Law Holdings:
        <ol>
            <li>Holding #1</li>
            <li>Holding #2</li>
            <li>Holding #3</li>
        </ol>
    </li>
    <li>
        Doctrines:
        <ol>
            <li>Doctrine Point #1:</li>
            <li>Doctrine Point #2:</li>
        </ol>
    </li>
    <li>
        Sessional Paper #1:
        <ol>
            <li>Sessional Paper Point #1:</li>
            <li>Sessional Paper Point #2:</li>
        </ol>
    </li>
    <li>
        Judicial Reviews #1:
        <ol>
            <li>Review Point #1:</li>
            <li>Review Point #2:</li>
        </ol>
    </li>
    <li>
        General Comments
        <ul>
            <li>Comment #1:</li>
            <li>Comment #2:</li>
        </ul>
    </li>
    <li>
        Policy & Guidance
        <ul>
            <li>Item #1</li>
            <li>Item #2</li>
        </ul>
    </li>
    <li>
        Decree / Circular / Regulations / Fact Finding
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
    <li>
        Statements
        <ul>
            <li>Reports #1</li>
            <li>Reports #2</li>
        </ul>
    </li>
</ol>
<p>
    <strong>Findings & Opinion of the concurring Judge(s)</strong>
</p>
<ol>
    <li>
        The petitioners have made out a clear case of violation of their constitutional rights by the 1st and 2nd respondents following the demolition of Mitumba Village on 19th
        November 2011 and on a subsequent occasion thereafter.
    </li>
    <li>
        As I have stated before, the actions of the respondents in this case are all the more reprehensible as the demolition was carried out while there was in force an order
        of this court restraining the demolition of the petitioners’ houses and other institutions in the village.
    </li>
</ol>
<p>
    <strong>Opinion of the dissenting Judge(s)</strong>
</p>
<ol>
    <li>Opinion #1:</li>
    <li>Opinion #2:</li>
    <li>Opinion #3:</li>
</ol>
`;

export const constitutionalLawHumanRightsSupremeCourtCaseLawStep_5_Content = `
<p class="lead">
    What were the orders, penalties, charges, sentencing, costs e.t.c.
</p>
<h2>
    The holding
</h2>
<p>
    On the violation of petitioners rights.
</p>
<ol>
    <li>
        Whether alone or with other state agents, the 2nd respondent, which had issued to the petitioners the notice to vacate within 7 days, committed egregious violations of the
        rights of the petitioners.
    </li>
    <li>
        The state and the 2nd respondent are, in my view, jointly and severally liable for the violation of the petitioners’ rights in the demolition of the petitioners’ houses.
    </li>
</ol>
<h2>
    Orders & Directions
</h2>
<ol>
    <li>
        <strong>
            That the demolition by the 2nd Respondent of the petitioners’  houses situate in Mitumba Village near Wilson Airport was illegal, irregular,
            unprocedural and contrary to Articles   26, 27(1) (2) and (4), 28, 29, 40, 43, 53 and 56 of the Constitution.
        </strong>
    </li>
    <li>
        <strong>
            That any forceful eviction and or demolition without  a relocation option is illegal, oppressive and violates the rights of the petitioners.
        </strong>
    </li>
    <li>
        <strong>
            That the respondents do provide, by way of affidavit, within 60 days of today, the current state policies and programmes on provision of shelter and access to
            housing for the marginalised groups such as residents of informal and slum settlements.
        </strong>
    </li>
    <li>
        <strong>
            That the respondents do furnish copies of such policies and programmes to the petitioners, other relevant state agencies, Pamoja Trust (and such other civil
            society organisation as the petitioners and the respondents may agree upon as having the requisite knowledge and expertise in the area of housing and shelter
            provision as would assist in arriving at an appropriate resolution to the petitioners’ grievances), to     analyse and comment on the policies and programmes
            provided by the respondents.
        </strong>
    </li>
    <li>
        <strong>
            That the respondents do engage with the petitioners, Pamoja Trust, other relevant state agencies and civil society organizations with a view to identifying an
            appropriate resolution to the petitioners’ grievances following their eviction from Mitumba Village.
        </strong>
    </li>
    <li>
        <strong>
            That the parties report back on the progress made towards a resolution of the petitioners’ grievances within 90 days from today.
        </strong>
    </li>
</ol>
<h2>
    Sentences
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Fines
</h2>
<ul>
    <li>None</li>
</ul>
<h2>
    Damages
</h2>
<ul>
    <li>None</li>
</ul>
`;

export const constitutionalLawHumanRightsSupremeCourtCaseLawStep_6_Content = `
<p class="lead">
    Public reviews and comments
</p>
<h2>
    Member Comments
</h2>
<p>
    Some of the comments from members.
</p>
<ol>
    <li>Comment #1</li>
    <li>Comment #2</li>
</ol>
`;

export const constitutionalLawHumanRightsSupremeCourtCaseLawStep_7_Content = `
<p class="lead">
    Case Brief
</p>
<h2>
    To print or share this case brief with your network. Check all is well and then click on the appropriate button.
</h2>
<p>
    The case brief has not been generated yet.
</p>
`;

export const demoCourseSteps = [
    {
        order   : 0,
        title   : 'Introduction',
        subtitle: 'Introducing the library and how it works',
        content : `<h2 class="text-2xl sm:text-3xl">Introduction</h1> ${demoCourseContent}`,
    },
    {
        order   : 1,
        title   : 'Get the sample code',
        subtitle: 'Where to find the sample code and how to access it',
        content : `<h2 class="text-2xl sm:text-3xl">Get the sample code</h1> ${demoCourseContent}`,
    },
    {
        order   : 2,
        title   : 'Create a Firebase project and Set up your app',
        subtitle: 'How to create a basic Firebase project and how to run it locally',
        content : `<h2 class="text-2xl sm:text-3xl">Create a Firebase project and Set up your app</h1> ${demoCourseContent}`,
    },
    {
        order   : 3,
        title   : 'Install the Firebase Command Line Interface',
        subtitle: 'Setting up the Firebase CLI to access command line tools',
        content : `<h2 class="text-2xl sm:text-3xl">Install the Firebase Command Line Interface</h1> ${demoCourseContent}`,
    },
    {
        order   : 4,
        title   : 'Deploy and run the web app',
        subtitle: 'How to build, push and run the project remotely',
        content : `<h2 class="text-2xl sm:text-3xl">Deploy and run the web app</h1> ${demoCourseContent}`,
    },
    {
        order   : 5,
        title   : 'The Functions Directory',
        subtitle: 'Introducing the Functions and Functions Directory',
        content : `<h2 class="text-2xl sm:text-3xl">The Functions Directory</h1> ${demoCourseContent}`,
    },
    {
        order   : 6,
        title   : 'Import the Cloud Functions and Firebase Admin modules',
        subtitle: 'Create your first Function and run it to administer your app',
        content : `<h2 class="text-2xl sm:text-3xl">Import the Cloud Functions and Firebase Admin modules</h1> ${demoCourseContent}`,
    },
    {
        order   : 7,
        title   : 'Welcome New Users',
        subtitle: 'How to create a welcome message for the new users',
        content : `<h2 class="text-2xl sm:text-3xl">Welcome New Users</h1> ${demoCourseContent}`,
    },
    {
        order   : 8,
        title   : 'Images moderation',
        subtitle: 'How to moderate images; crop, resize, optimize',
        content : `<h2 class="text-2xl sm:text-3xl">Images moderation</h1> ${demoCourseContent}`,
    },
    {
        order   : 9,
        title   : 'New Message Notifications',
        subtitle: 'How to create and push a notification to a user',
        content : `<h2 class="text-2xl sm:text-3xl">New Message Notifications</h1> ${demoCourseContent}`,
    },
    {
        order   : 10,
        title   : 'Congratulations!',
        subtitle: 'Nice work, you have created your first application',
        content : `<h2 class="text-2xl sm:text-3xl">Congratulations!</h1> ${demoCourseContent}`,
    },
];

export const newCaseBlankTemplateSteps = [
    {
        order   : 0,
        title   : 'Introduction',
        subtitle: 'Introducing the library and how it works',
        content : `<h2 class="text-2xl sm:text-3xl">Introduction</h1> ${demoCourseContent}`,
    }
];

export const mootCourtSteps = [
    {
        order   : 0,
        title   : 'Case Summary',
        subtitle: 'What is the case about, the court handling the case, the parties, what the parties want the court to do, case procedural history.',
        content : `<h2 class="text-2xl sm:text-3xl">Moot Court Compromis</h1> ${mootCourtTemplateStep_0_Content}`,
    },
    {
        order   : 1,
        title   : 'Facts of the Case',
        subtitle: 'The dispute (before the act/omission, the act/omission, after the act/omission), relevant statements, question of fact (verifiable statements), questions of law (legal issues), important (pivotal) facts',
        content : `<h2 class="text-2xl sm:text-3xl">Facts of the Case</h1> ${mootCourtTemplateStep_1_Content}`,
    },
    {
        order   : 2,
        title   : 'Issues',
        subtitle: 'The legal issues raised by the facts that the court is being asked to resolve and the process in which the court resolved it. Legal errors by trial court.',
        content : `<h2 class="text-2xl sm:text-3xl">Issues</h1> ${mootCourtTemplateStep_2_Content}`,
    },
    {
        order   : 3,
        title   : 'Rules',
        subtitle: 'Legal rules used by the court, precedents',
        content : `<h2 class="text-2xl sm:text-3xl">Rules</h1> ${mootCourtTemplateStep_3_Content}`,
    },
    {
        order   : 4,
        title   : 'Analysis',
        subtitle: 'Courts reasoning and analysis, dissenting voices, concurring voices, opinions, application of the rules to the case, application of legal precedent, application of rules on different facts.',
        content : `<h2 class="text-2xl sm:text-3xl">Analysis</h1> ${mootCourtTemplateStep_4_Content}`,
    },
    {
        order   : 5,
        title   : 'Summary of Arguments',
        subtitle: 'Summary submissions on each of the issues raised.',
        content : `<h2 class="text-2xl sm:text-3xl">Summary of Arguments</h1> ${mootCourtTemplateStep_5_Content}`,
    },
    {
        order   : 6,
        title   : 'Arguments',
        subtitle: 'Admisibility - Questions on Jurisdictions, Qualifying statements on requirements; Merits - Questions on each of the claims raised. ',
        content : `<h2 class="text-2xl sm:text-3xl">Arguments</h1> ${mootCourtTemplateStep_6_Content}`,
    },
    {
        order   : 7,
        title   : 'Conclusion',
        subtitle: 'Binding Precedents & Authorities, Persuasive Precedents & Authorities and Prayers',
        content : `<h2 class="text-2xl sm:text-3xl">Conclusion</h1> ${mootCourtTemplateStep_7_Content}`,
    },
    {
        order   : 8,
        title   : 'Applicant Memorial',
        subtitle: 'List of Abbreviations, Table of Authorities, Summary of Arguments, Arguments and Prayers',
        content : `<h2 class="text-2xl sm:text-3xl">Applicant Memorial</h1> ${mootCourtTemplateStep_8_Content}`,
    },
    {
        order   : 9,
        title   : 'Respondent Memorial',
        subtitle: 'List of Abbreviations, Table of Authorities, Summary of Arguments, Arguments and Prayers',
        content : `<h2 class="text-2xl sm:text-3xl">Respondent Memorial</h1> ${mootCourtTemplateStep_9_Content}`,
    },
    {
        order   : 10,
        title   : 'Reviews and Comments',
        subtitle: 'Reviews and comments from other members',
        content : `<h2 class="text-2xl sm:text-3xl">Reviews and Comments</h1> ${mootCourtTemplateStep_10_Content}`,
    },
    {
        order   : 11,
        title   : 'Congratulations!',
        subtitle: 'Print and Submit your work!',
        content : `<h2 class="text-2xl sm:text-3xl">Congratulations!</h1> ${mootCourtTemplateStep_11_Content}`,
    },
];

export const unlawfullAssemblyAndRiotsCaseLawSteps = [
    {
        order   : 0,
        title   : 'Case Summary',
        subtitle: 'What the case is about, the court handling the case, the parties, what the parties want the court to do, case procedural history',
        content : `<h2 class="text-2xl sm:text-3xl">Case Summary</h1> ${unlawfullAssemblyAndRiotsCaseLawStep_0_Content}`,
    },
    {
        order   : 1,
        title   : 'Facts of the Case',
        subtitle: 'The dispute (before the act/omission, the act/omission, after the act/omission), relevant statements, question of fact (verifiable statements), question of law (legal issues), important (pivotal) facts',
        content : `<h2 class="text-2xl sm:text-3xl">Facts of the Case</h1> ${unlawfullAssemblyAndRiotsCaseLawStep_1_Content}`,
    },
    {
        order   : 2,
        title   : 'Issues',
        subtitle: 'The legal issues raised by the facts that the court is being asked to resolve and the process in which the court resolved it. Legal errors by trial courts',
        content : `<h2 class="text-2xl sm:text-3xl">Issues</h1> ${unlawfullAssemblyAndRiotsCaseLawStep_2_Content}`,
    },
    {
        order   : 3,
        title   : 'Rules',
        subtitle: 'Legal rules used by the court, precedents',
        content : `<h2 class="text-2xl sm:text-3xl">Rules</h1> ${unlawfullAssemblyAndRiotsCaseLawStep_3_Content}`,
    },
    {
        order   : 4,
        title   : 'Analysis',
        subtitle: 'Court\'s reasoning and analysis,dessenting voices, concurring voices, opinions, application of the rules to the case, application of legal precedent, application of rules on different facts',
        content : `<h2 class="text-2xl sm:text-3xl">Analysis</h1> ${unlawfullAssemblyAndRiotsCaseLawStep_4_Content}`,
    },
    {
        order   : 5,
        title   : 'Conclusion',
        subtitle: 'Orders, Compensation, Sentencing, Costs',
        content : `<h2 class="text-2xl sm:text-3xl">Conclusion</h1> ${unlawfullAssemblyAndRiotsCaseLawStep_5_Content}`,
    },
    {
        order   : 6,
        title   : 'Reviews and Comments',
        subtitle: 'Reviews and comments from other members',
        content : `<h2 class="text-2xl sm:text-3xl">Reviews and Comments</h1> ${unlawfullAssemblyAndRiotsCaseLawStep_6_Content}`,
    },
    {
        order   : 7,
        title   : 'Final',
        subtitle: 'Single page document, downloadable case brief',
        content : `<h2 class="text-2xl sm:text-3xl">Reviews and Comments</h1> ${unlawfullAssemblyAndRiotsCaseLawStep_7_Content}`,
    },
];

export const perjuryCaseLawSteps = [
    {
        order   : 0,
        title   : 'Case Summary',
        subtitle: 'What the case is about, the court handling the case, the parties, what the parties want the court to do, case procedural history',
        content : `<h2 class="text-2xl sm:text-3xl">Case Summary</h1> ${perjuryCaseLawStep_0_Content}`,
    },
    {
        order   : 1,
        title   : 'Facts of the Case',
        subtitle: 'The dispute (before the act/omission, the act/omission, after the act/omission), relevant statements, question of fact (verifiable statements), question of law (legal issues), important (pivotal) facts',
        content : `<h2 class="text-2xl sm:text-3xl">Facts of the Case</h1> ${perjuryCaseLawStep_1_Content}`,
    },
    {
        order   : 2,
        title   : 'Issues',
        subtitle: 'The legal issues raised by the facts that the court is being asked to resolve and the process in which he court resolved it. Legal errors by trial courts',
        content : `<h2 class="text-2xl sm:text-3xl">Issues</h1> ${perjuryCaseLawStep_2_Content}`,
    },
    {
        order   : 3,
        title   : 'Rules',
        subtitle: 'Legal rules used by the court, precedents',
        content : `<h2 class="text-2xl sm:text-3xl">Rules</h1> ${perjuryCaseLawStep_3_Content}`,
    },
    {
        order   : 4,
        title   : 'Analysis',
        subtitle: 'Court\'s reasoning and analysis,dessenting voices, concurring voices, opinions, application of the rules to the case, application of legal precedent, application of rules on different facts',
        content : `<h2 class="text-2xl sm:text-3xl">Analysis</h1> ${perjuryCaseLawStep_4_Content}`,
    },
    {
        order   : 5,
        title   : 'Conclusion',
        subtitle: 'Orders, Compensation, Sentencing, Costs',
        content : `<h2 class="text-2xl sm:text-3xl">Conclusion</h1> ${perjuryCaseLawStep_5_Content}`,
    },
    {
        order   : 6,
        title   : 'Reviews and Comments',
        subtitle: 'Reviews and comments from other members',
        content : `<h2 class="text-2xl sm:text-3xl">Reviews and Comments</h1> ${perjuryCaseLawStep_6_Content}`,
    },
    {
        order   : 7,
        title   : 'Final',
        subtitle: 'Single page document, downloadable case brief with additional information like definition, summary history, detailed notes on perjury, Perjury law by jurisdiction, sentences, Subornation of perjury, relevant Case Laws and more, convicted perjurs ',
        content : `<h2 class="text-2xl sm:text-3xl">Detailed Case Brief</h1> ${perjuryCaseLawStep_7_Content}`,
    },
];

export const trespassCaseLawSteps = [
    {
        order   : 0,
        title   : 'Case Summary',
        subtitle: 'What the case is about, the court handling the case, the parties, what the parties want the court to do, case procedural history',
        content : `<h2 class="text-2xl sm:text-3xl">Case Summary</h1> ${trespassCaseLawStep_0_Content}`,
    },
    {
        order   : 1,
        title   : 'Facts of the Case',
        subtitle: 'The dispute (before the act/omission, the act/omission, after the act/omission), relevant statements, question of fact (verifiable statements), question of law (legal issues), important (pivotal) facts',
        content : `<h2 class="text-2xl sm:text-3xl">Facts of the Case</h1> ${trespassCaseLawStep_1_Content}`,
    },
    {
        order   : 2,
        title   : 'Issues',
        subtitle: 'The legal issues raised by the facts that the court is being asked to resolve and the process in which he court resolved it. Legal errors by trial courts',
        content : `<h2 class="text-2xl sm:text-3xl">Issues</h1> ${trespassCaseLawStep_2_Content}`,
    },
    {
        order   : 3,
        title   : 'Rules',
        subtitle: 'Legal rules used by the court, precedents',
        content : `<h2 class="text-2xl sm:text-3xl">Rules</h1> ${trespassCaseLawStep_3_Content}`,
    },
    {
        order   : 4,
        title   : 'Analysis',
        subtitle: 'Court\'s reasoning and analysis,dessenting voices, concurring voices, opinions, application of the rules to the case, application of legal precedent, application of rules on different facts',
        content : `<h2 class="text-2xl sm:text-3xl">Analysis</h1> ${trespassCaseLawStep_4_Content}`,
    },
    {
        order   : 5,
        title   : 'Conclusion',
        subtitle: 'Orders, Compensation, Sentencing, Costs',
        content : `<h2 class="text-2xl sm:text-3xl">Conclusion</h1> ${trespassCaseLawStep_5_Content}`,
    },
    {
        order   : 6,
        title   : 'Reviews and Comments',
        subtitle: 'Reviews and comments from other members',
        content : `<h2 class="text-2xl sm:text-3xl">Reviews and Comments</h1> ${trespassCaseLawStep_6_Content}`,
    },
    {
        order   : 7,
        title   : 'Final',
        subtitle: 'Single page document, downloadable case brief with additional information like definition, summary history, detailed notes on perjury, Perjury law by jurisdiction, sentences, Subornation of perjury, relevant Case Laws and more, convicted perjurs ',
        content : `<h2 class="text-2xl sm:text-3xl">Detailed Case Brief</h1> ${trespassCaseLawStep_7_Content}`,
    },
];

export const trespassToLandCourtOfAppealAppealCaseLawSteps = [
    {
        order   : 0,
        title   : 'Case Summary',
        subtitle: 'What the case is about, the court handling the case, the parties, what the parties want the court to do, case procedural history',
        content : `<h2 class="text-2xl sm:text-3xl">Case Summary</h1> ${trespassToLandCourtOfAppealAppealCaseLawStep_0_Content}`,
    },
    {
        order   : 1,
        title   : 'Facts of the Case',
        subtitle: 'The dispute (before the act/omission, the act/omission, after the act/omission), relevant statements, question of fact (verifiable statements), question of law (legal issues), important (pivotal) facts',
        content : `<h2 class="text-2xl sm:text-3xl">Facts of the Case</h1> ${trespassToLandCourtOfAppealAppealCaseLawStep_1_Content}`,
    },
    {
        order   : 2,
        title   : 'Issues',
        subtitle: 'The legal issues raised by the facts that the court is being asked to resolve and the process in which he court resolved it. Legal errors by trial courts',
        content : `<h2 class="text-2xl sm:text-3xl">Issues</h1> ${trespassToLandCourtOfAppealAppealCaseLawStep_2_Content}`,
    },
    {
        order   : 3,
        title   : 'Rules',
        subtitle: 'Legal rules used by the court, precedents',
        content : `<h2 class="text-2xl sm:text-3xl">Rules</h1> ${trespassToLandCourtOfAppealAppealCaseLawStep_3_Content}`,
    },
    {
        order   : 4,
        title   : 'Analysis',
        subtitle: 'Court\'s reasoning and analysis,dessenting voices, concurring voices, opinions, application of the rules to the case, application of legal precedent, application of rules on different facts',
        content : `<h2 class="text-2xl sm:text-3xl">Analysis</h1> ${trespassToLandCourtOfAppealAppealCaseLawStep_4_Content}`,
    },
    {
        order   : 5,
        title   : 'Conclusion',
        subtitle: 'Orders, Compensation, Sentencing, Costs',
        content : `<h2 class="text-2xl sm:text-3xl">Conclusion</h1> ${trespassToLandCourtOfAppealAppealCaseLawStep_5_Content}`,
    },
    {
        order   : 6,
        title   : 'Reviews and Comments',
        subtitle: 'Reviews and comments from other members',
        content : `<h2 class="text-2xl sm:text-3xl">Reviews and Comments</h1> ${trespassToLandCourtOfAppealAppealCaseLawStep_6_Content}`,
    },
    {
        order   : 7,
        title   : 'Final',
        subtitle: 'Single page document, downloadable case brief with additional information like definition, summary history, detailed notes on perjury, Perjury law by jurisdiction, sentences, Subornation of perjury, relevant Case Laws and more, convicted perjurs ',
        content : `<h2 class="text-2xl sm:text-3xl">Detailed Case Brief</h1> ${trespassToLandCourtOfAppealAppealCaseLawStep_7_Content}`,
    },
];

export const contractDuressHighCourtCaseLawSteps = [
    {
        order   : 0,
        title   : 'Case Summary',
        subtitle: 'What the case is about, the court handling the case, the parties, what the parties want the court to do, case procedural history',
        content : `<h2 class="text-2xl sm:text-3xl">Case Summary</h1> ${contractDuressHighCourtCaseLawStep_0_Content}`,
    },
    {
        order   : 1,
        title   : 'Facts of the Case',
        subtitle: 'The dispute (before the act/omission, the act/omission, after the act/omission), relevant statements, question of fact (verifiable statements), question of law (legal issues), important (pivotal) facts',
        content : `<h2 class="text-2xl sm:text-3xl">Facts of the Case</h1> ${contractDuressHighCourtCaseLawStep_1_Content}`,
    },
    {
        order   : 2,
        title   : 'Issues',
        subtitle: 'The legal issues raised by the facts that the court is being asked to resolve and the process in which he court resolved it. Legal errors by trial courts',
        content : `<h2 class="text-2xl sm:text-3xl">Issues</h1> ${contractDuressHighCourtCaseLawStep_2_Content}`,
    },
    {
        order   : 3,
        title   : 'Rules',
        subtitle: 'Legal rules used by the court, precedents',
        content : `<h2 class="text-2xl sm:text-3xl">Rules</h1> ${contractDuressHighCourtCaseLawStep_3_Content}`,
    },
    {
        order   : 4,
        title   : 'Analysis',
        subtitle: 'Court\'s reasoning and analysis,dessenting voices, concurring voices, opinions, application of the rules to the case, application of legal precedent, application of rules on different facts',
        content : `<h2 class="text-2xl sm:text-3xl">Analysis</h1> ${contractDuressHighCourtCaseLawStep_4_Content}`,
    },
    {
        order   : 5,
        title   : 'Conclusion',
        subtitle: 'Orders, Compensation, Sentencing, Costs',
        content : `<h2 class="text-2xl sm:text-3xl">Conclusion</h1> ${contractDuressHighCourtCaseLawStep_5_Content}`,
    },
    {
        order   : 6,
        title   : 'Reviews and Comments',
        subtitle: 'Reviews and comments from other members',
        content : `<h2 class="text-2xl sm:text-3xl">Reviews and Comments</h1> ${contractDuressHighCourtCaseLawStep_6_Content}`,
    },
    {
        order   : 7,
        title   : 'Final',
        subtitle: 'Single page document, downloadable case brief with additional information like definition, summary history, detailed notes on perjury, Perjury law by jurisdiction, sentences, Subornation of perjury, relevant Case Laws and more, convicted perjurs ',
        content : `<h2 class="text-2xl sm:text-3xl">Detailed Case Brief</h1> ${contractDuressHighCourtCaseLawStep_7_Content}`,
    },
];

export const contractEconomicDuressHighCourtCaseLawSteps = [
    {
        order   : 0,
        title   : 'Case Summary',
        subtitle: 'What the case is about, the court handling the case, the parties, what the parties want the court to do, case procedural history',
        content : `<h2 class="text-2xl sm:text-3xl">Case Summary</h1> ${contractEconomicDuressHighCourtCaseLawStep_0_Content}`,
    },
    {
        order   : 1,
        title   : 'Facts of the Case',
        subtitle: 'The dispute (before the act/omission, the act/omission, after the act/omission), relevant statements, question of fact (verifiable statements), question of law (legal issues), important (pivotal) facts',
        content : `<h2 class="text-2xl sm:text-3xl">Facts of the Case</h1> ${contractEconomicDuressHighCourtCaseLawStep_1_Content}`,
    },
    {
        order   : 2,
        title   : 'Issues',
        subtitle: 'The legal issues raised by the facts that the court is being asked to resolve and the process in which he court resolved it. Legal errors by trial courts',
        content : `<h2 class="text-2xl sm:text-3xl">Issues</h1> ${contractEconomicDuressHighCourtCaseLawStep_2_Content}`,
    },
    {
        order   : 3,
        title   : 'Rules',
        subtitle: 'Legal rules used by the court, precedents',
        content : `<h2 class="text-2xl sm:text-3xl">Rules</h1> ${contractEconomicDuressHighCourtCaseLawStep_3_Content}`,
    },
    {
        order   : 4,
        title   : 'Analysis',
        subtitle: 'Court\'s reasoning and analysis,dessenting voices, concurring voices, opinions, application of the rules to the case, application of legal precedent, application of rules on different facts',
        content : `<h2 class="text-2xl sm:text-3xl">Analysis</h1> ${contractEconomicDuressHighCourtCaseLawStep_4_Content}`,
    },
    {
        order   : 5,
        title   : 'Conclusion',
        subtitle: 'Orders, Compensation, Sentencing, Costs',
        content : `<h2 class="text-2xl sm:text-3xl">Conclusion</h1> ${contractEconomicDuressHighCourtCaseLawStep_5_Content}`,
    },
    {
        order   : 6,
        title   : 'Reviews and Comments',
        subtitle: 'Reviews and comments from other members',
        content : `<h2 class="text-2xl sm:text-3xl">Reviews and Comments</h1> ${contractEconomicDuressHighCourtCaseLawStep_6_Content}`,
    },
    {
        order   : 7,
        title   : 'Final',
        subtitle: 'Single page document, downloadable case brief with additional information like definition, summary history, detailed notes on perjury, Perjury law by jurisdiction, sentences, Subornation of perjury, relevant Case Laws and more, convicted perjurs ',
        content : `<h2 class="text-2xl sm:text-3xl">Detailed Case Brief</h1> ${contractEconomicDuressHighCourtCaseLawStep_7_Content}`,
    },
];

export const constitutionalLawHumanRightsSupremeCourtCaseLawSteps = [
    {
        order   : 0,
        title   : 'Case Summary',
        subtitle: 'What the case is about, the court handling the case, the parties, what the parties want the court to do, case procedural history',
        content : `<h2 class="text-2xl sm:text-3xl">Case Summary</h1> ${constitutionalLawHumanRightsSupremeCourtCaseLawStep_0_Content}`,
    },
    {
        order   : 1,
        title   : 'Facts of the Case',
        subtitle: 'The dispute (before the act/omission, the act/omission, after the act/omission), relevant statements, question of fact (verifiable statements), question of law (legal issues), important (pivotal) facts',
        content : `<h2 class="text-2xl sm:text-3xl">Facts of the Case</h1> ${constitutionalLawHumanRightsSupremeCourtCaseLawStep_1_Content}`,
    },
    {
        order   : 2,
        title   : 'Issues',
        subtitle: 'The legal issues raised by the facts that the court is being asked to resolve and the process in which he court resolved it. Legal errors by trial courts',
        content : `<h2 class="text-2xl sm:text-3xl">Issues</h1> ${constitutionalLawHumanRightsSupremeCourtCaseLawStep_2_Content}`,
    },
    {
        order   : 3,
        title   : 'Rules',
        subtitle: 'Legal rules used by the court, precedents',
        content : `<h2 class="text-2xl sm:text-3xl">Rules</h1> ${constitutionalLawHumanRightsSupremeCourtCaseLawStep_3_Content}`,
    },
    {
        order   : 4,
        title   : 'Analysis',
        subtitle: 'Court\'s reasoning and analysis,dessenting voices, concurring voices, opinions, application of the rules to the case, application of legal precedent, application of rules on different facts',
        content : `<h2 class="text-2xl sm:text-3xl">Analysis</h1> ${constitutionalLawHumanRightsSupremeCourtCaseLawStep_4_Content}`,
    },
    {
        order   : 5,
        title   : 'Conclusion',
        subtitle: 'Orders, Compensation, Sentencing, Costs',
        content : `<h2 class="text-2xl sm:text-3xl">Conclusion</h1> ${constitutionalLawHumanRightsSupremeCourtCaseLawStep_5_Content}`,
    },
    {
        order   : 6,
        title   : 'Reviews and Comments',
        subtitle: 'Reviews and comments from other members',
        content : `<h2 class="text-2xl sm:text-3xl">Reviews and Comments</h1> ${constitutionalLawHumanRightsSupremeCourtCaseLawStep_6_Content}`,
    },
    {
        order   : 7,
        title   : 'Final',
        subtitle: 'Single page document, downloadable case brief with additional information like definition, summary history, detailed notes on perjury, Perjury law by jurisdiction, sentences, Subornation of perjury, relevant Case Laws and more, convicted perjurs ',
        content : `<h2 class="text-2xl sm:text-3xl">Detailed Case Brief</h1> ${constitutionalLawHumanRightsSupremeCourtCaseLawStep_7_Content}`,
    },
];
